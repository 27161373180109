import { throttle } from 'lodash';
import { v7 as uuidv7 } from 'uuid';

import { ActivityTracker } from '@inspiren-monorepo/activity-tracker';

export class BrowserActivityTracker extends ActivityTracker {
  private static instance: BrowserActivityTracker;

  public static getInstance(): BrowserActivityTracker {
    if (!BrowserActivityTracker.instance) {
      BrowserActivityTracker.instance = new BrowserActivityTracker();
    }

    return BrowserActivityTracker.instance;
  }

  override generateSessionId() {
    return uuidv7();
  }

  override registerEventListeners() {
    const keepActive = this.keepActive.bind(this);
    const throttledKeepActive = throttle(keepActive, 500);

    window.addEventListener('mousemove', throttledKeepActive);
    window.addEventListener('keydown', throttledKeepActive);
    window.addEventListener('scroll', throttledKeepActive);
    window.addEventListener('click', throttledKeepActive);

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        this.sendActivityEvent();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      window.removeEventListener('mousemove', throttledKeepActive);
      window.removeEventListener('keydown', throttledKeepActive);
      window.removeEventListener('scroll', throttledKeepActive);
      window.removeEventListener('click', throttledKeepActive);

      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }
}

export default BrowserActivityTracker.getInstance();
