import { skipToken, useQuery } from '@tanstack/react-query';

import { getOfflineEvents } from '../data-access/getOfflineEvents';
import { useEventReviewStoreShallow } from '../store/EventReviewStore';

export const useOfflineEvents = () => {
  const { selectedRoom, startDate, endDate } = useEventReviewStoreShallow([
    'selectedRoom',
    'startDate',
    'endDate',
  ]);

  const roomId = selectedRoom?.domainId;

  return useQuery({
    queryKey: ['eventReview', 'offlineEvents', roomId, startDate, endDate],
    queryFn:
      roomId && startDate && endDate
        ? () =>
            getOfflineEvents(
              roomId,
              startDate.toISOString(),
              endDate.toISOString(),
            )
        : skipToken,
  });
};
