import { useMutation, useQueryClient } from '@tanstack/react-query';

import { deleteExternalApiKey } from '../data-access/deleteExternalApiKey';

export const useDeleteExternalApiKey = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: string) => deleteExternalApiKey(id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['external-api-keys'],
      });
    },
  });
};
