import type { FieldTypes } from '../../../types/DataFields';

export const defaultRenderRows = (rows: FieldTypes[]) => (
  <ul>
    {rows.map((row) => (
      <li key={row.id as string}>
        {(row.displayName ?? row.name ?? row.id) as string}
      </li>
    ))}
  </ul>
);
