import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

import type { SelectOptionsTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { useCurrentUser } from '../HOC/CurrentUserContextProvider';
import { Api } from '../utility/client';

type Props = Omit<
  UseQueryOptions<
    SelectOptionsTypes.BuildingOption[],
    Error,
    SelectOptionsTypes.BuildingOption[],
    string[]
  >,
  'queryKey' | 'queryFn'
>;

export const useBuildingOptions = (props: Props = {}) => {
  const { user } = useCurrentUser();
  return useQuery({
    ...props,
    queryKey: ['building-options', user?.domainId || ''],

    queryFn: async () => {
      const url = '/selectOptions/v1/buildings';
      const res = await Api.get<SelectOptionsTypes.BuildingOption[]>(url);

      return res?.data;
    },
  });
};
