import { useQuery } from '@tanstack/react-query';
import { isNil } from 'lodash';

import type { OrgSettings } from '@inspiren-monorepo/virtual-care/api-contracts';

import { Api } from '../utility/client';

export const useOrgSettings = (orgId: string | undefined) => {
  const { data, isLoading, error } = useQuery({
    queryKey: ['org-settings', orgId],

    queryFn: async () => {
      if (isNil(orgId)) return null;

      // TODO: Move to telesitting namespace
      const url = `/v1/orgs/${orgId}/settings`;
      const res = await Api.get<OrgSettings>(url);

      return res?.data;
    },
  });

  return { data, loading: isLoading, error };
};
