import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { DataGridPro, type GridColDef } from '@mui/x-data-grid-pro';
import { format, parseISO } from 'date-fns';

import { isValidFallRiskLevel } from '@inspiren-monorepo/shared-types';

import { dateTimeFormat } from '../../../utility/helpers/time';
import FallRiskPill from '../../shared/FallRiskPill';
import InvalidValue from '../InvalidValue';
import { useRoomFallRiskChanges } from '../hooks/useRoomFallRiskChanges';
import TableError from '../tables/TableError';

interface Props {
  open: boolean;
  onClose: () => void;
  roomId: string;
}

const columns: GridColDef[] = [
  {
    field: 'time',
    headerName: 'Date',
    width: 190,
    type: 'dateTime',
    valueGetter: (value) => parseISO(value),
    valueFormatter: (value) => format(value, dateTimeFormat),
  },
  {
    field: 'fallRiskLevel',
    headerName: 'Changed To',
    width: 130,
    renderCell: ({ value }) =>
      isValidFallRiskLevel(value) ? (
        <FallRiskPill fallRisk={value} size='small' />
      ) : (
        <InvalidValue value={value} />
      ),
  },
  {
    field: 'type',
    headerName: 'Type',
    width: 130,
    type: 'singleSelect',
    valueOptions: ['Manual', 'Reset', 'API'],
    valueGetter: (value) =>
      value === 'FallRiskChanged'
        ? 'Manual'
        : value === 'FallRiskReset'
          ? 'Reset'
          : 'API',
  },
  {
    field: 'email',
    headerName: 'Changed By',
    width: 280,
  },
];

export const RoomFallRiskHistoryModal = ({ open, roomId, onClose }: Props) => {
  const { loading, events, error } = useRoomFallRiskChanges(roomId);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth='md'>
      <DialogTitle>
        Fall Risk Changes
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {error ? (
          <TableError />
        ) : (
          <DataGridPro
            columns={columns}
            rows={events}
            loading={loading}
            getRowId={(row) => `${row.room}-${row.time}`}
            pagination
            initialState={{ pagination: { paginationModel: { pageSize: 10 } } }}
            pageSizeOptions={[10, 25, 50]}
            rowSelection={false}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};
