import { useMutation, useQueryClient } from '@tanstack/react-query';

import { generateExternalApiKey } from '../data-access/generateExternalApiKey';

export const useGenerateExternalApiKey = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => generateExternalApiKey(),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['external-api-keys'],
      });
    },
  });
};
