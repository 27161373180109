import { ContactSupport } from '@mui/icons-material';

import { sendAmpEvent } from '../../utility/amplitude';
import { Can } from '../Can/Can';

export const ContactSupportButton = () => (
  <Can
    permission={{
      action: 'view',
      subject: 'global.contact-support',
    }}
  >
    <ContactSupport
      data-testid='contact-support-button'
      fontSize='large'
      onClick={() => {
        window.open(
          'https://inspiren.zendesk.com/hc/en-us/requests/new',
          '_blank',
        );

        sendAmpEvent('Contact Support Clicked');
      }}
      sx={{
        position: 'fixed',
        bottom: '1rem',
        right: '1rem',
        cursor: 'pointer',
        zIndex: 1000,
        fontSize: '50px',
      }}
    />
  </Can>
);
