import { useCallback, useMemo, useState } from 'react';
import { Stack } from '@mui/material';
import type { GridRowParams } from '@mui/x-data-grid-pro';
import { isNil, keyBy } from 'lodash';
import toast from 'react-hot-toast';

import { getAxiosErrorMessage } from '@inspiren-monorepo/util-axios';
import { DomainId } from '@inspiren-monorepo/util-rooms';
import type { AdminTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { RoomForm } from './components/RoomForm';
import getRoomsTableFields from './constants/getRoomsTableFields';
import { roomBulkUpdateActionsDefinition } from './constants/roomBulkUpdateActionsDefinition';
import { roomBulkUpdateMutationFn } from './data-access/roomBulkUpdateMutationFn';
import { useRenderBulkUpdateRows } from './hooks/useRenderBulkUpdateRows';
import { useRoomsSiteConfigs } from './hooks/useRoomsSiteConfigs';
import useRoomsUpsert from './hooks/useRoomsUpsert';
import { useSetRoomQueryDataAfterBulkUpdate } from './hooks/useSetRoomQueryDataAfterBulkUpdate';
import { useSiteConfigHistory } from './hooks/useSiteConfigHistory';

import { useIsAdmin } from '../../../../hooks/useIsAdmin';
import { useUnitOptions } from '../../../../hooks/useUnitOptions';
import { DropdownSingleSelect } from '../../../shared/DropdownSingleSelect';
import { BaseHistoryModal } from '../../components/BaseHistoryModal';
import BulkImportLink from '../../components/BulkImportLink';
import { OfflineReportSubButton } from '../../components/OfflineReportSubButton';
import { TableBase } from '../../components/TableBase';
import { AdminTableWrapper } from '../../components/TableBase/AdminTableWrapper';
import { useAdminOrg } from '../../hooks/useAdminOrg';
import { useRooms } from '../../hooks/useRooms';
import { useCareLevels } from '../CareLevelsTable/hooks/useCareLevels';

import type { RoomFieldTypes } from './types/RoomFieldTypes';
import type {
  OnSubmitFormModal,
  RenderFormModal,
} from '../../modals/FormModalBase';

export type TableView =
  | 'Default View'
  | 'Site Config View'
  | 'AUGi Status View';

const RoomsTable = () => {
  const orgId = import.meta.env.VITE_ORG_ID;
  const { isAdmin } = useIsAdmin();

  const [tableView, setTableView] = useState<TableView>('Default View');

  const tableViewOptions = useMemo(() => {
    const options: TableView[] = ['Default View', 'AUGi Status View'];

    if (isAdmin) {
      options.push('Site Config View');
    }

    return options.map((option) => ({
      label: option,
      value: option,
    }));
  }, [isAdmin]);

  const handleTableViewChange = useCallback((value: TableView | null) => {
    if (!value) return;
    setTableView(value);
  }, []);

  const { isLoading: orgLoading, data: org } = useAdminOrg({ orgId });

  const { isLoading: careLevelsLoading, data: careLevels } =
    useCareLevels(orgId);

  const careLevelsById = useMemo(
    () => keyBy(careLevels || [], 'id'),
    [careLevels],
  );

  const {
    data: units,
    isLoading: unitsLoading,
    isError: unitsError,
  } = useUnitOptions();

  const unitsMap = useMemo(() => keyBy(units || [], 'id'), [units]);

  const {
    isLoading: roomsLoading,
    isError: roomsError,
    rooms,
  } = useRooms({ includeDeleted: true });

  const { siteConfigs } = useRoomsSiteConfigs(org?.structureId || '');
  const { handleAddSubmit, handleEditSubmit } = useRoomsUpsert(unitsMap);

  const roomsTableFields = useMemo(
    () => getRoomsTableFields(unitsMap, tableView),
    [tableView, unitsMap],
  );

  const data = useMemo(() => {
    const filteredRooms =
      tableView === 'Site Config View'
        ? (rooms || []).filter((room) => room.baseId)
        : rooms || [];

    return filteredRooms.map(
      ({
        id,
        domainId,
        fallRiskLevel,
        careLevelId,
        displayName,
        hide,
        deleted,
        designation,
        unitId,
        householdId,
        buildingDisplayName,
        floorNumber,
        unitDisplayName,
        householdDisplayName,
        baseId,
        keepAlive,
        offlineStatus,
        tz,
        disable,
        pccRoomId,
        pccBedId,
        yardiRoomId,
        yardiBedId,
        alisRoomId,
        alisBedId,
      }) => {
        // TODO: can join this data when pulling from db https://linear.app/inspiren/issue/INS-695/join-this-data-when-pulling-from-db
        const siteConfig = siteConfigs?.find(
          (config) => config.domainId === domainId,
        );

        return {
          id,
          baseID: baseId || '',
          structureId: siteConfig?.id,
          ...siteConfig?.configOverrides,
          roomId: DomainId.parse(domainId).room || '',
          organization: DomainId.toOrgId(domainId) || '',
          unitId,
          fallRiskLevel,
          careLevel: careLevelId
            ? careLevelsById[careLevelId]?.displayName || ''
            : '',
          displayName,
          hide,
          deleted,
          designation,
          buildingDisplayName,
          floorNumber,
          unitDisplayName,
          keepAlive,
          offlineStatus,
          tz,
          disable,
          pccRoomId,
          pccBedId,
          yardiRoomId,
          yardiBedId,
          alisRoomId,
          alisBedId,
          householdId,
          householdDisplayName,
          bathroomSensor:
            siteConfig?.configOverrides?.bathroom_sensor_associations?.[0]
              ?.value || '',
        };
      },
    );
  }, [rooms, careLevelsById, siteConfigs, tableView]);

  const onEditSubmit: OnSubmitFormModal<RoomFieldTypes> = useCallback(
    async (item) => {
      try {
        await handleEditSubmit({
          ...item,
          organization: orgId,
        });

        toast.success(`Successfully updated room ${item.roomId}`);
      } catch (error) {
        const message =
          getAxiosErrorMessage(error) ??
          `Error updating room${error ? `: ${error}` : ''}`;

        toast.error(message);
      }
    },
    [handleEditSubmit, orgId],
  );

  const onAddSubmit: OnSubmitFormModal<RoomFieldTypes> = useCallback(
    async (item) => {
      try {
        await handleAddSubmit({
          ...item,
          roomId: item.displayName.replace(/[^\dA-Za-z]/g, '').toLowerCase(),
          organization: orgId,
        });

        toast.success(`Successfully added room ${item.roomId}`);
      } catch (error) {
        const message =
          getAxiosErrorMessage(error) ??
          `Error adding room${error ? `: ${error}` : ''}`;

        toast.error(message);
      }
    },
    [handleAddSubmit, orgId],
  );

  const renderModal: RenderFormModal<RoomFieldTypes> = useCallback(
    ({ defaultComponents, type }) => (
      <RoomForm
        type={type}
        unitsMap={unitsMap}
        isAdmin={isAdmin}
        defaultComponents={defaultComponents}
        orgId={orgId}
      />
    ),
    [orgId, isAdmin, units],
  );

  const {
    handleClose,
    handleOpen,
    columns,
    data: siteConfigHistoryData,
    selectedRow,
    isLoading: historyIsLoading,
  } = useSiteConfigHistory();

  const getSiteConfigHistoryAction = useCallback(
    ({ row }: GridRowParams) => [
      <BaseHistoryModal
        title='Site Config History'
        open={selectedRow === row.structureId}
        onOpen={() => handleOpen(row.structureId)}
        onClose={handleClose}
        rows={siteConfigHistoryData || []}
        loading={historyIsLoading}
        columns={columns}
      />,
    ],
    [
      columns,
      siteConfigHistoryData,
      selectedRow,
      handleOpen,
      handleClose,
      historyIsLoading,
    ],
  );

  const setRoomQueryDataAfterBulkUpdate =
    useSetRoomQueryDataAfterBulkUpdate(orgId);

  const renderBulkUpdateRows = useRenderBulkUpdateRows();

  return (
    <AdminTableWrapper>
      <TableBase<RoomFieldTypes, AdminTypes.RoomBulkUpdatePayload>
        itemName='Room'
        fields={roomsTableFields}
        data={data}
        loading={roomsLoading || careLevelsLoading || unitsLoading}
        renderModal={renderModal}
        modalLoading={orgLoading}
        error={roomsError || unitsError}
        onEditSubmit={onEditSubmit}
        onAddSubmit={onAddSubmit}
        customNoRowsText={isNil(orgId) ? 'No organization selected' : undefined}
        disableAddButton={isNil(orgId)}
        getExtraRowActions={
          tableView === 'Site Config View'
            ? getSiteConfigHistoryAction
            : undefined
        }
        extraActionButtons={isAdmin && <BulkImportLink itemName='rooms' />}
        extraTableOptions={
          <>
            {tableView === 'AUGi Status View' && <OfflineReportSubButton />}
            <Stack
              direction='row'
              alignItems='center'
              sx={{ flex: 1, justifyContent: 'flex-end' }}
            >
              <DropdownSingleSelect
                value={tableView}
                options={tableViewOptions}
                onChange={handleTableViewChange}
                id='table-view'
              />
            </Stack>
          </>
        }
        disableEditing={
          tableView === 'Site Config View' || tableView === 'AUGi Status View'
        }
        defaultPinnedColumns={['roomId', 'displayName']}
        enableBulkUpdate={isAdmin}
        bulkUpdateActionsDefinition={roomBulkUpdateActionsDefinition}
        bulkUpdateMutationFn={roomBulkUpdateMutationFn}
        onBulkUpdateSuccess={setRoomQueryDataAfterBulkUpdate}
        renderBulkUpdateRows={renderBulkUpdateRows}
      />
    </AdminTableWrapper>
  );
};

export default RoomsTable;
