import { type PropertyName, pick } from 'lodash';
import { create } from 'zustand';
import { useShallow } from 'zustand/react/shallow';

import type { WasabiImage } from '@inspiren-monorepo/shared-react/universal';
import type { EventReviewTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

type ErrorInfo = string | { message: string; code: number } | null;
export type ViewMode = 'admin' | 'staff';
export type TzMode = 'facility' | 'local' | 'utc';

interface State {
  selectedUnit: EventReviewTypes.Unit | null;
  selectedRoom: EventReviewTypes.Room | null;
  startDate: Date | null;
  endDate: Date | null;
  datePickerStartDate: Date | null;
  datePickerEndDate: Date | null;
  tzMode: TzMode;
  position: number;
  images: WasabiImage[] | null;
  lastImagesLoading: boolean;
  imagesLoading: boolean;
  imagesError: ErrorInfo;
  showStaffEventMarks: boolean;
  showNotifMarks: boolean;
  viewMode: ViewMode;
}

interface Actions {
  setSelectedUnit: (newValue: EventReviewTypes.Unit | null) => void;
  setSelectedRoom: (newValue: EventReviewTypes.Room | null) => void;
  setStartDate: (newValue: Date | null) => void;
  setEndDate: (newValue: Date | null) => void;
  setDatePickerStartAndEndDate: (
    startDate: Date | null,
    endDate: Date | null,
  ) => void;
  setTzMode: (newValue: TzMode) => void;
  incrementPosition: () => void;
  setPosition: (newValue: number) => void;
  setImages: (newValue: WasabiImage[] | null) => void;
  setImagesLoading: (newValue: boolean) => void;
  setImagesError: (newValue: ErrorInfo) => void;
  setShowStaffEventMarks: (newValue: boolean) => void;
  setShowNotifMarks: (newValue: boolean) => void;
  setLastImagesLoading: (newValue: boolean) => void;
  setViewMode: (newValue: ViewMode) => void;
  reset: () => void;
}

const initialState: State = {
  selectedUnit: null,
  selectedRoom: null,
  startDate: null,
  endDate: null,
  datePickerStartDate: null,
  datePickerEndDate: null,
  tzMode: 'facility',
  position: 0,
  images: null,
  imagesLoading: false,
  imagesError: null,
  showStaffEventMarks: true,
  showNotifMarks: true,
  lastImagesLoading: false,
  viewMode: 'staff',
};

type Store = State & Actions;

const useEventReviewStore = create<Store>((set) => ({
  ...initialState,
  setSelectedUnit: (newValue) => set({ selectedUnit: newValue }),
  setSelectedRoom: (newValue) => set({ selectedRoom: newValue }),
  setStartDate: (newValue) =>
    set({ startDate: newValue, datePickerStartDate: newValue }),
  setEndDate: (newValue) =>
    set({ endDate: newValue, datePickerEndDate: newValue }),
  setDatePickerStartAndEndDate: (startDate, endDate) =>
    set({ datePickerStartDate: startDate, datePickerEndDate: endDate }),
  setTzMode: (newValue) => set({ tzMode: newValue }),
  incrementPosition: () => set((state) => ({ position: state.position + 1 })),
  setPosition: (newValue) => set({ position: newValue }),
  setImages: (newValue) => set({ images: newValue }),
  setImagesLoading: (newValue) => set({ imagesLoading: newValue }),
  setImagesError: (newValue) => set({ imagesError: newValue }),
  setShowStaffEventMarks: (newValue) => set({ showStaffEventMarks: newValue }),
  setShowNotifMarks: (newValue) => set({ showNotifMarks: newValue }),
  setLastImagesLoading: (newValue) => set({ lastImagesLoading: newValue }),
  setViewMode: (newValue) => set({ viewMode: newValue }),
  reset: () => set(initialState),
}));

/**
 * This will cause a re-render to be triggered only when the selected
 * fields change, instead of when anything in the store changes.
 */
export const useEventReviewStoreShallow = <
  TFields extends keyof Store & PropertyName = keyof Store,
>(
  fields: TFields[] = [],
) =>
  useEventReviewStore(
    useShallow<Store, Pick<Store, TFields>>((state) => pick(state, fields)),
  );
