import axios from 'axios';

import type { TelesittingTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { getApiBaseUrl } from '../../../config/apiUrlConfig';

export async function fetchFallVideo(exportId: string) {
  const url = `${getApiBaseUrl()}/telesitting/v1/exports/${exportId}`;
  const res = await axios.get<TelesittingTypes.ExportProgress>(url);

  return res && res.data;
}
