import type { AdminTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { Api } from '../../../utility/client';

interface Props {
  includeDeleted?: boolean;
  lastKey?: string;
}

export interface RoomsTableQueryResponse {
  rooms: AdminTypes.Room[];
  lastKey: string | undefined;
}

export async function getRooms({ includeDeleted = false, lastKey }: Props) {
  return Api.get<RoomsTableQueryResponse>('admin/v1/rooms', {
    params: { includeDeleted, lastKey },
  }).then((res) => res.data);
}
