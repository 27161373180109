import { Api } from '../../../../../utility/client';

export async function updateUserActivationState(
  userId: string,
  deactivate: boolean,
) {
  const response = await Api.put(
    `/admin/v1/users/${userId}/${deactivate ? 'deactivate' : 'reactivate'}`,
  );

  return response.data;
}
