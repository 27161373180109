import { Typography } from '@mui/material';
import { getYear } from 'date-fns';

const Footer = () => (
  <Typography
    variant='body2'
    sx={{ marginX: 'auto', backgroundColor: 'white' }}
  >
    Copyright Inspiren, Inc. {getYear(new Date())}
  </Typography>
);

export default Footer;
