import { useCallback, useMemo } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';

import { getAxiosErrorMessage } from '@inspiren-monorepo/util-axios';

import { careLevelFields } from './constants/careLevelFields';
import { postCareLevel } from './data-access/postCareLevel';
import { putCareLevel } from './data-access/putCareLevel';
import {
  getCareLevelsQueryOptions,
  useCareLevels,
} from './hooks/useCareLevels';

import { TableBase } from '../../components/TableBase';
import { AdminTableWrapper } from '../../components/TableBase/AdminTableWrapper';
import { getBuildings } from '../../data-access/getBuildings';
import SelectBuilding from '../../modals/special/SelectBuilding';

import type { CareLevelFieldTypes } from './types/CareLevelFieldTypes';
import type {
  OnSubmitFormModal,
  RenderFormModal,
} from '../../modals/FormModalBase';

type SaveCareLevelFieldTypes = CareLevelFieldTypes & { buildingId?: string };

const CareLevelsTable = () => {
  const awsOrg = import.meta.env.VITE_ORG_ID;
  const queryClient = useQueryClient();

  // temporary fix for buildings till we migrate to RDS
  const { isLoading: buildingsLoading, data: buildings } = useQuery({
    queryKey: ['buildings'],
    queryFn: getBuildings,
  });

  const {
    isFetching: careLevelsFetching,
    isError: careLevelsError,
    data: careLevels,
  } = useCareLevels();

  const data = useMemo(
    () =>
      (careLevels || []).map(
        ({
          id,
          displayName,
          price,
          building,
          description,
          buildingId,
          buildingDisplayName,
        }) => ({
          id,
          displayName,
          price: price.toString(),
          building: building || '',
          description: description || '',
          buildingId: buildingId || '',
          buildingDisplayName: buildingDisplayName || '',
        }),
      ),
    [careLevels],
  );

  const renderModal: RenderFormModal<CareLevelFieldTypes> = useCallback(
    ({ defaultComponents, control, type, fields }) => (
      <>
        {defaultComponents.displayName}
        <SelectBuilding
          control={control}
          type={type}
          tooltip={fields.building.tooltip}
        />
        {defaultComponents.price}
        {defaultComponents.description}
      </>
    ),
    [],
  );

  const onEditSubmit: OnSubmitFormModal<SaveCareLevelFieldTypes> = useCallback(
    async (item) => {
      try {
        const building = buildings?.find((b) => b.id === item.buildingId);

        const careLevel = {
          displayName: item.displayName,
          price: parseInt(item.price),
          description: item.description,
          building: building?.domainId,
        };

        await putCareLevel(item.id, careLevel);

        await queryClient.invalidateQueries({
          queryKey: getCareLevelsQueryOptions().queryKey,
        });

        toast.success(`Successfully updated care level ${item.displayName}`);
      } catch (error) {
        const message =
          getAxiosErrorMessage(error) ??
          `Error updating care level ${item.displayName}`;

        toast.error(message);
      }
    },
    [awsOrg],
  );

  const onAddSubmit: OnSubmitFormModal<SaveCareLevelFieldTypes> = useCallback(
    async (item) => {
      try {
        const building = buildings?.find((b) => b.id === item.buildingId);

        const careLevel = {
          displayName: item.displayName,
          price: parseInt(item.price),
          description: item.description || undefined,
          building: building?.domainId,
        };

        await postCareLevel(careLevel);

        await queryClient.invalidateQueries({
          queryKey: getCareLevelsQueryOptions().queryKey,
        });

        toast.success(`Successfully added care level ${item.displayName}`);
      } catch (error) {
        const message =
          getAxiosErrorMessage(error) ??
          `Error adding care level ${item.displayName}`;

        toast.error(message);
      }
    },
    [],
  );

  return (
    <AdminTableWrapper>
      <TableBase<CareLevelFieldTypes>
        itemName='Care Level'
        fields={careLevelFields}
        data={data}
        loading={careLevelsFetching || buildingsLoading}
        renderModal={renderModal}
        error={careLevelsError}
        onEditSubmit={onEditSubmit}
        onAddSubmit={onAddSubmit}
        defaultPinnedColumns={['id', 'displayName']}
      />
    </AdminTableWrapper>
  );
};

export default CareLevelsTable;
