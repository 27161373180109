import type { EventReviewTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { Api } from '../../../utility/client';

import type { ViewMode } from '../store/EventReviewStore';

export const getStaffEvents = async (
  room: string,
  start: string,
  end: string,
  mode: ViewMode,
) => {
  try {
    const url = `/eventReview/v1/events/staff/${room}/${start}/${end}`;

    const res = await Api.get<EventReviewTypes.StaffEvent[]>(url, {
      params: { mode },
    });

    return res?.data;
  } catch (error) {
    console.error('error fetching staff events');
    throw error;
  }
};
