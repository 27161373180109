import type React from 'react';
import { Stack } from '@mui/material';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  type GridToolbarProps,
} from '@mui/x-data-grid-pro';

import type { BulkUpdatePayload } from '@inspiren-monorepo/shared-types';
import type { AdminTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import AddModal from '../../../modals/AddModal';
import { BulkUpdateMenu } from '../../BulkUpdate/components/BulkUpdateMenu';

import type {
  OnSubmitFormModal,
  RenderFormModal,
} from '../../../modals/FormModalBase';
import type { DataFields, FieldTypes } from '../../../types/DataFields';
import type { SectionedBulkUpdateActions } from '../../BulkUpdate/types/BulkUpdateFields';
import type { MutationFunction } from '@tanstack/react-query';

export interface ToolbarProps<
  F extends FieldTypes,
  TBulkUpdateAction extends BulkUpdatePayload = BulkUpdatePayload,
> extends GridToolbarProps {
  itemName: string;
  fields: DataFields<F>;
  setPanelAnchorEl: React.Dispatch<
    React.SetStateAction<HTMLButtonElement | null>
  >;
  onAddSubmit?: OnSubmitFormModal<F>;
  onAddOpen?: () => void;
  addLoading?: boolean;
  addError?: string | null;
  showExportMenu?: boolean;
  extraActionButtons?: React.ReactNode;
  extraTableOptions?: React.ReactNode;
  disableAddButton?: boolean;
  showAddButton?: boolean;
  renderModal?: RenderFormModal<F>;
  areRowsSelected?: boolean;
  enableBulkUpdate?: boolean;
  bulkUpdateActionsDefinition?: SectionedBulkUpdateActions<TBulkUpdateAction>;
  bulkUpdateMutationFn?: MutationFunction<
    AdminTypes.BulkUpdateResult,
    TBulkUpdateAction
  >;
  onBulkUpdateSuccess?: (variables: TBulkUpdateAction) => void;
  renderBulkUpdateRows?: (rows: F[]) => React.ReactNode;
}

export const Toolbar = <
  F extends FieldTypes,
  TBulkUpdateAction extends BulkUpdatePayload = BulkUpdatePayload,
>({
  itemName,
  fields,
  setPanelAnchorEl,
  onAddSubmit,
  onAddOpen,
  addLoading = false,
  addError = null,
  showExportMenu = true,
  extraActionButtons,
  extraTableOptions,
  disableAddButton,
  showAddButton = true,
  renderModal,
  areRowsSelected = false,
  enableBulkUpdate,
  bulkUpdateActionsDefinition,
  bulkUpdateMutationFn,
  onBulkUpdateSuccess,
  renderBulkUpdateRows,
}: ToolbarProps<F, TBulkUpdateAction>) => (
  <GridToolbarContainer>
    <Stack
      direction='row'
      flexWrap='wrap'
      width='100%'
      justifyContent='space-between'
      mb={1}
      gap={1}
    >
      <Stack direction='row' gap={1} alignItems='center'>
        {areRowsSelected &&
        enableBulkUpdate &&
        bulkUpdateActionsDefinition &&
        bulkUpdateMutationFn ? (
          <BulkUpdateMenu<F, TBulkUpdateAction>
            itemName={itemName}
            actionsDefinition={bulkUpdateActionsDefinition}
            mutationFn={bulkUpdateMutationFn}
            onSuccess={onBulkUpdateSuccess}
            renderRows={renderBulkUpdateRows}
          />
        ) : (
          <>
            {showAddButton && (
              <AddModal
                itemName={itemName}
                fields={fields}
                onSubmit={onAddSubmit}
                onOpen={onAddOpen}
                loading={addLoading}
                error={addError}
                disabled={disableAddButton}
                renderModal={renderModal}
              />
            )}
            {extraActionButtons}
          </>
        )}
      </Stack>
      <Stack direction='row' ml='auto' gap={1}>
        {extraTableOptions}
        <GridToolbarColumnsButton ref={setPanelAnchorEl} />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        {showExportMenu && (
          <GridToolbarExport
            csvOptions={{ fileName: `${itemName}Data` }}
            printOptions={{ disableToolbarButton: true }}
          />
        )}
      </Stack>
    </Stack>
  </GridToolbarContainer>
);
