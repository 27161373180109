import { useCallback, useState } from 'react';
import { Box, Button, Stack, Tooltip, Typography } from '@mui/material';
import { Delete, Publish, UploadFile } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import toast from 'react-hot-toast';
import { useCSVReader } from 'react-papaparse';

import { bulkCreateBeacons } from '../data-access/bulkCreateBeacons';

import type { BulkCreateResponse } from '../data-access/bulkCreateBeacons';

type NewBeacon = {
  id: string;
  emailOrId?: string;
};

function renderBulkCreateResponse(response: BulkCreateResponse) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Typography>{`Successfully added / assigned ${response.successful.length} beacons.`}</Typography>
      {!isEmpty(response.unsuccessful) && (
        <>
          <Typography sx={{ mt: 2, fontWeight: 'bold' }}>
            Unsuccessful:
          </Typography>
          {response.unsuccessful.map((item) => (
            <Typography>
              {item.id} - {item.reason}
            </Typography>
          ))}
        </>
      )}
    </Box>
  );
}

export const BulkUploadCSV = () => {
  const org = import.meta.env.VITE_ORG_ID;
  const { CSVReader } = useCSVReader();

  const [bulkBeacons, setBulkBeacons] = useState<NewBeacon[]>([]);

  const queryClient = useQueryClient();

  const postBeacons = useCallback(
    async (beacons: NewBeacon[]) => bulkCreateBeacons(org, beacons),
    [org],
  );

  const addBeaconsMutation = useMutation({
    mutationFn: postBeacons,
    onSuccess: (data) => {
      toast.success(renderBulkCreateResponse(data));
      queryClient.invalidateQueries({ queryKey: ['beacons'] });
      setBulkBeacons([]);
    },
    onError: () => {
      toast.error('Error: failed to add new users');
      setBulkBeacons([]);
    },
  });

  const publishBeacons = useCallback(async () => {
    await addBeaconsMutation.mutateAsync(bulkBeacons);
  }, [bulkBeacons]);

  return (
    <CSVReader
      config={{ skipEmptyLines: true }}
      onUploadAccepted={(results: { data: string[][] }) => {
        const beacons = results.data.map((res) => ({
          id: res[0],
          emailOrId: res[1],
        }));

        // remove the header row
        beacons.shift();
        setBulkBeacons(beacons);
      }}
    >
      {({
        acceptedFile,
        getRootProps,
        getRemoveFileProps,
      }: {
        acceptedFile: File;
        getRootProps: () => any;
        getRemoveFileProps: () => any;
      }) =>
        !isEmpty(bulkBeacons) && acceptedFile?.name ? (
          <Stack
            direction='row'
            spacing={1}
            alignItems='center'
            border={1}
            borderColor='grey.400'
            borderRadius={1}
            px={1}
          >
            <Typography fontWeight={500} variant='body2'>
              File: {acceptedFile.name}
            </Typography>
            <LoadingButton
              variant='text'
              component='label'
              startIcon={<Publish />}
              color='success'
              loading={addBeaconsMutation.isPending}
              size='small'
              onClick={publishBeacons}
            >
              Publish
            </LoadingButton>
            <Button
              variant='text'
              component='label'
              startIcon={<Delete />}
              color='error'
              size='small'
              {...getRemoveFileProps()}
            >
              Remove
            </Button>
          </Stack>
        ) : (
          <Tooltip title='Upload a CSV file with columns in this order: beaconId, email (optional). The first row is a header row and will be skipped.'>
            <Button
              variant='contained'
              size='small'
              component='label'
              startIcon={<UploadFile />}
              {...getRootProps()}
            >
              Bulk Create / Assign from CSV
            </Button>
          </Tooltip>
        )
      }
    </CSVReader>
  );
};
