import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

import type { SelectOptionsTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { useCurrentUser } from '../HOC/CurrentUserContextProvider';
import { Api } from '../utility/client';

type Props = Omit<
  UseQueryOptions<
    SelectOptionsTypes.FloorOption[],
    Error,
    SelectOptionsTypes.FloorOption[],
    string[]
  >,
  'queryKey' | 'queryFn'
> & {
  buildingId?: string;
};

export const useFloorOptions = (props: Props = {}) => {
  const { user } = useCurrentUser();
  const { buildingId, ...restProps } = props;
  return useQuery({
    ...restProps,
    queryKey: ['floor-options', user?.domainId || '', buildingId || ''],

    queryFn: async () => {
      const url = `/selectOptions/v1/floors${buildingId ? `?buildingId=${buildingId}` : ''}`;
      const res = await Api.get<SelectOptionsTypes.FloorOption[]>(url);

      return res?.data;
    },
  });
};
