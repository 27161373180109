import { getUnixTime } from 'date-fns';
import { isNil } from 'lodash';

import type { WasabiImage } from '@inspiren-monorepo/shared-react/universal';

export const getPositionFromDate = (
  images: WasabiImage[] | null,
  date: Date,
) => {
  if (isNil(images) || images.length === 0) return null;
  if (images.length === 1) return 0;

  const dateStr = date.toISOString();

  // Find first image after given date/time
  const after = images.findIndex((i) => i.time >= dateStr);

  // If none, return last position
  if (after === -1) return images.length - 1;

  // If first image, return first position
  if (after === 0) return 0;

  const before = after - 1;
  const unix = getUnixTime(date);

  const beforeRange = unix - getUnixTime(new Date(images[before].time));
  const afterRange = getUnixTime(new Date(images[after].time)) - unix;

  return beforeRange > afterRange ? after : before;
};
