import {
  API_PATH,
  type UserActivityEvent,
} from '@inspiren-monorepo/activity-tracker';

export const ingestBrowserUserActivity =
  (apiBaseUrl: string) => (events: UserActivityEvent[]) => {
    navigator.sendBeacon(
      `${apiBaseUrl}${API_PATH}`,
      JSON.stringify({ events }),
    );
  };
