import { useCallback, useMemo } from 'react';
import { groupBy, keyBy } from 'lodash';

import type { AdminTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { getBasestationsTableFields } from './constants/basestationsTableFields';
import useBasestationUpsert from './hooks/useBasestationUpsert';

import { useUnitOptions } from '../../../../hooks/useUnitOptions';
import { useRooms } from '../../hooks/useRooms';
import ImportTable from '../ImportTable';
import { mapCsvRowsToFields } from '../ImportTable/helpers/mapCsvRowsToFields';
import csvCellMappers from '../ImportTable/utils/csvCellMappers';

import type { DataFields } from '../../types/DataFields';
import type {
  FieldsConfiguration,
  Importable,
} from '../ImportTable/types/importable';
import type { UploadFn } from '../ImportTable/types/uploadFn';

const BasestationsImportTable = () => {
  const {
    basestations,
    basestationsLoading,
    handleAddSubmit,
    handleEditSubmit,
  } = useBasestationUpsert({
    queryOptions: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  });

  // but perhaps we will not need basestations import table
  const { isLoading: unitsLoading, data: units } = useUnitOptions();

  const { rooms } = useRooms({});

  const fieldsConfiguration: FieldsConfiguration<AdminTypes.BasestationDto> = {
    id: {
      csvHeader: 'id',
      mapper: csvCellMappers.StringLowercase,
    },
    unitId: {
      csvHeader: 'Building-Floor-Unit',
      csvHeaderRegExp: /building-floor-unit/i,
      mapper: csvCellMappers.Unit(units),
    },
    roomId: {
      csvHeader: 'roomId',
      csvHeaderRegExp: /room( id)?/i,
      mapper: csvCellMappers.Room(rooms),
    },
    virtual: {
      csvHeader: 'virtual',
      mapper: csvCellMappers.Boolean,
    },
  };

  const unitsMap = useMemo(() => keyBy(units || [], 'id'), [units]);

  const roomsMap = useMemo(() => keyBy(rooms || [], 'id'), [rooms]);

  const basestationsTableFields = useMemo(
    () => getBasestationsTableFields(unitsMap, roomsMap),
    [unitsMap, roomsMap],
  );

  const columns: DataFields<AdminTypes.BasestationDto> =
    basestationsTableFields.filter((e) => !!fieldsConfiguration[e.field]);

  const basestationsMap = useMemo(
    () => groupBy(basestations || [], 'id'),
    [basestations],
  );

  const mapCsvToFields = useCallback(
    async (
      csvRows: string[][],
    ): Promise<Importable<AdminTypes.BasestationDto>[]> =>
      mapCsvRowsToFields(
        csvRows,
        fieldsConfiguration,
        (e) => !!basestationsMap[e.id],
      ),
    [basestationsMap, fieldsConfiguration],
  );

  const createDefaultRow = useCallback(
    (): AdminTypes.BasestationDto =>
      ({
        id: '',
        roomId: '',
        virtual: false,
      }) as AdminTypes.BasestationDto,
    [],
  );

  const upload: UploadFn<AdminTypes.BasestationDto> = useCallback(
    async (item) => {
      const {
        $meta: { alreadyExists },
        ...rest
      } = item;

      const fn = alreadyExists ? handleEditSubmit : handleAddSubmit;

      await fn({
        ...rest,
      });
    },
    [handleAddSubmit, handleEditSubmit],
  );

  const onRowUpdate = useCallback(
    (
      row: Importable<AdminTypes.BasestationDto>,
    ): Importable<AdminTypes.BasestationDto> => ({
      ...row,
      $meta: {
        ...row.$meta,
        alreadyExists: !!basestationsMap[row.id] || false,
      },
    }),
    [basestationsMap],
  );

  return (
    <ImportTable
      fields={columns}
      createDefaultRow={createDefaultRow}
      mapCsvToFields={mapCsvToFields}
      loading={basestationsLoading || unitsLoading}
      upload={upload}
      itemName='basestations'
      onRowUpdate={onRowUpdate}
      bulkUpdateSupport
    />
  );
};

export default BasestationsImportTable;
