import { useCallback, useState } from 'react';

export enum BulkUpdateStep {
  FollowUp,
  Confirm,
  Pending,
  Result,
}

export const useBulkUpdateStep = (needsFollowUp: boolean) => {
  const [step, setStep] = useState<BulkUpdateStep>(
    needsFollowUp ? BulkUpdateStep.FollowUp : BulkUpdateStep.Confirm,
  );

  const goToNextStep = useCallback(() => {
    setStep((prevStep) => prevStep + 1);
  }, []);

  const goToFollowUp = useCallback(() => {
    setStep(0);
  }, []);

  return { step, goToNextStep, goToFollowUp };
};
