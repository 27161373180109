import { isEmpty } from 'lodash';

import { Api } from '../../../../../utility/client';

export async function updateBeacon(id: string, assignedTo?: string) {
  await Api.put(`/admin/v1/beacons/${id}`, {
    id,
    assignedTo: isEmpty(assignedTo) ? undefined : assignedTo,
  });
}
