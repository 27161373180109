import { Stack, Tab, Tabs } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { isNil } from 'lodash';
import { useParams } from 'react-router-dom';

import { isUsernameOrg } from '@inspiren-monorepo/util-users';

import { BeaconHistoryTable } from './components/BeaconHistoryTable';
import { getBeacon } from './data-access/getBeacon';

import ErrorScreen from '../../../../screens/ErrorScreen';
import Loading from '../../../../screens/Loading';
import { ShowPageField } from '../../components/ShowPageField';
import { ShowPageHeader } from '../../components/ShowPageHeader';
import { Battery } from '../BeaconsTable/components/Battery';

export const BeaconPage = () => {
  const awsOrg = import.meta.env.VITE_ORG_ID;
  const { id } = useParams<{ id: string }>();

  const {
    data: beacon,
    isLoading,
    isSuccess,
  } = useQuery({
    queryKey: ['beacon', id],
    queryFn: () => getBeacon(id as string),
  });

  if (isLoading) {
    return <Loading />;
  }

  if (!isSuccess) {
    return (
      <ErrorScreen defaultMessage='Something went wrong with getting beacon details' />
    );
  }

  return (
    <Stack spacing={1}>
      <ShowPageHeader
        backPath='/admin/beacons'
        prefix='Beacon'
        value={beacon.id}
      />
      <Stack gap={2} my={2}>
        <ShowPageField
          label='Assigned To'
          value={
            (isUsernameOrg(awsOrg)
              ? beacon.assignedToUsername
              : beacon.assignedToEmail) || '-'
          }
        />
        <ShowPageField label='Last Seen At' value={beacon.lastSeen || '-'} />
        <ShowPageField
          label='Last Seen In Room'
          value={beacon.lastSeenInRoom || '-'}
        />
        <ShowPageField
          label='Battery'
          value={
            isNil(beacon.battery) ? (
              '-'
            ) : (
              <Battery value={beacon.battery} size={45} />
            )
          }
        />
      </Stack>
      <Tabs value={0}>
        <Tab label='History' value={0} />
      </Tabs>
      <BeaconHistoryTable beacon={beacon} />
    </Stack>
  );
};
