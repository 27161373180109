import { useCallback } from 'react';
import { type InfiniteData, useQueryClient } from '@tanstack/react-query';

import { RoomBulkUpdateAction } from '@inspiren-monorepo/shared-types';
import type { AdminTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import type { RoomsTableQueryResponse } from '../../../data-access/getRooms';

export const useSetRoomQueryDataAfterBulkUpdate = (orgId: string) => {
  const queryClient = useQueryClient();

  const setRoomQueryDataAfterBulkUpdate = useCallback(
    ({ ids, action }: AdminTypes.RoomBulkUpdatePayload) => {
      queryClient.setQueryData(
        ['rooms', orgId, true],
        (oldData: InfiniteData<RoomsTableQueryResponse>) => {
          if (!oldData) {
            return {
              pages: [],
              pageParams: [],
            };
          }

          const newData: InfiniteData<RoomsTableQueryResponse> = {
            ...oldData,
            pages: oldData.pages.map((page) => ({
              ...page,
              rooms: page.rooms.map((room) => {
                if (
                  ids.includes(room.id) &&
                  [
                    RoomBulkUpdateAction.DisableAugi,
                    RoomBulkUpdateAction.EnableAugi,
                  ].includes(action)
                ) {
                  return {
                    ...room,
                    disable: action === RoomBulkUpdateAction.DisableAugi,
                  };
                }

                return room;
              }),
            })),
          };

          return newData;
        },
      );
    },
    [orgId, queryClient],
  );

  return setRoomQueryDataAfterBulkUpdate;
};
