import type { PropsWithChildren } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  type Theme,
  Typography,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import type { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx';

type Props = PropsWithChildren<{
  title: string;
  isAccordion?: boolean;
  defaultExpanded?: boolean;
  sx?: SystemStyleObject<Theme> | undefined;
}>;

const FormCategory = ({
  title,
  children,
  isAccordion,
  defaultExpanded,
  sx,
}: Props) => {
  if (isAccordion) {
    return (
      <Accordion defaultExpanded={defaultExpanded} sx={sx}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography variant='h6'>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    );
  }

  return (
    <Box
      sx={(theme) => ({
        bgcolor: 'grey.100',
        py: theme.spacing(1.5),
        px: theme.spacing(2.5),
        borderRadius: theme.spacing(1),
        my: theme.spacing(3),
        ...(sx || {}),
      })}
    >
      <Typography variant='h6'>{title}</Typography>
      {children}
    </Box>
  );
};

export default FormCategory;
