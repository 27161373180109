import { useCallback, useMemo } from 'react';
import {
  Typography,
  Stack,
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import MediationIcon from '@mui/icons-material/Mediation';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useQueryClient } from '@tanstack/react-query';
import { isNil } from 'lodash';
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import type { Permissions } from '@inspiren-monorepo/feature-permissions';
import { getAxiosErrorMessage } from '@inspiren-monorepo/util-axios';

import { EditOrganizationSettingsModal } from './EditOrganizationSettingsModal';
import { OrgSettingsPanel } from './OrgSettingsPanel';
import { RolesFormModal } from './RolesFormModal';

import { useIsAdmin } from '../../../../../hooks/useIsAdmin';
import Loading from '../../../../../screens/Loading';
import { Can } from '../../../../Can/Can';
import { ShowPageHeader } from '../../../components/ShowPageHeader';
import { TableBase } from '../../../components/TableBase';
import { postRole } from '../../../data-access/postRole';
import { putRole } from '../../../data-access/putRole';
import { useAdminOrg } from '../../../hooks/useAdminOrg';
import { useOrganizationRoles } from '../../../hooks/useOrganizationRoles';
import { useRoleTemplates } from '../../../hooks/useRoleTemplates';
import { useTrainingVideos } from '../../../hooks/useTrainingVideo';
import { useOrgProfileFields } from '../constants/orgProfileFields';
import { getColumnGroupsFromFields } from '../helpers/getColumnGroupsFromFields';
import toDotNotation from '../helpers/toDotNotation';
import { transformFieldsToPermissions } from '../helpers/transformFieldsToPermissions';
import { transformPermissionsToFields } from '../helpers/transformPermissionsToFields';

import type {
  OnSubmitFormModal,
  RenderFormModal,
} from '../../../modals/FormModalBase';
import type { RoleFieldTypes } from '../types/RoleFieldTypes';

export const OrgProfile = () => {
  const id = import.meta.env.VITE_ORG_ID;
  const queryClient = useQueryClient();

  const headerMenuState = usePopupState({
    variant: 'popover',
    popupId: 'demoMenu',
  });

  const navigate = useNavigate();

  const { data: org, isLoading: orgsLoading } = useAdminOrg({ orgId: id });

  const {
    data: rolesData,
    isLoading: rolesLoading,
    isError: rolesError,
  } = useOrganizationRoles(id);

  const { isLoading: trainingVideosLoading } = useTrainingVideos();
  const { data: roleTemplates } = useRoleTemplates();

  const loading = orgsLoading || rolesLoading || trainingVideosLoading;

  const updatedRoles = transformPermissionsToFields(
    rolesData ?? [],
    roleTemplates.map,
  );

  const { isAdmin } = useIsAdmin();

  const disableEditing = orgsLoading || !org || !isAdmin;

  const orgProfileFields = useOrgProfileFields();

  const columnGroupingModel = useMemo(
    () => getColumnGroupsFromFields(orgProfileFields),
    [orgProfileFields],
  );

  const renderModal: RenderFormModal<RoleFieldTypes> = useCallback(
    ({ control, type, setValue }) => {
      if (!org) {
        return null;
      }

      return (
        <RolesFormModal
          control={control}
          type={type}
          setValue={setValue}
          organization={org}
        />
      );
    },
    [updatedRoles, id],
  );

  const handleAddSubmit: OnSubmitFormModal<RoleFieldTypes> = useCallback(
    async (submitData) => {
      try {
        const params = toDotNotation(submitData) as RoleFieldTypes;

        const filteredParams = Object.keys(params).reduce((acc, key) => {
          if (params[key as keyof RoleFieldTypes]) {
            return {
              ...acc,
              [key]: params[key as keyof RoleFieldTypes],
            };
          }

          return acc;
        }, {});

        const data = {
          templateId: params.templateId,
          displayName: params.displayName,
          maxAssign: params.maxAssign ? Number(params.maxAssign) : undefined,
          requireTrainingVideoMobile:
            params.requireTrainingVideoMobile || false,
          trainingVideoUrlMobile: params.trainingVideoUrlMobile || undefined,
          showInStaffView: params.showInStaffView ?? true,
          isLicensed: params.isLicensed ?? false,
          fallVideoSMS: params.fallVideoSMS || false,
        };

        const permissions = transformFieldsToPermissions(
          filteredParams as RoleFieldTypes,
        );

        await postRole({
          ...data,
          permissions: permissions as Permissions[],
          orgId: id,
        });

        toast.success('Successfully added Role', { duration: 10000 });

        await queryClient.invalidateQueries({
          queryKey: ['org', id, 'roles'],
        });
      } catch (error) {
        const message =
          getAxiosErrorMessage(error) ??
          `Error adding Role${error ? `: ${error}` : ''}`;

        toast.error(message);
      }
    },
    [id],
  );

  const handleEditSubmit: OnSubmitFormModal<RoleFieldTypes> = useCallback(
    async (editdata) => {
      try {
        const params = toDotNotation(editdata) as RoleFieldTypes;

        const filteredParams = Object.keys(params).reduce((acc, key) => {
          if (params[key as keyof RoleFieldTypes]) {
            return {
              ...acc,
              [key]: params[key as keyof RoleFieldTypes],
            };
          }

          return acc;
        }, {});

        const data = {
          templateId: params.templateId,
          displayName: params.displayName,
          maxAssign: params.maxAssign ? Number(params.maxAssign) : undefined,
          requireTrainingVideoMobile:
            params.requireTrainingVideoMobile || false,
          trainingVideoUrlMobile: params.trainingVideoUrlMobile,
          showInStaffView: params.showInStaffView ?? true,
          fallVideoSMS: params.fallVideoSMS || false,
          id: params.id,
          orgId: params.orgId,
          isLicensed: params.isLicensed ?? false,
        };

        const permissions = transformFieldsToPermissions(
          filteredParams as RoleFieldTypes,
        );

        await putRole({
          ...data,
          permissions: permissions as Permissions[],
        });

        toast.success('Successfully updated role', { duration: 10000 });

        await Promise.all([
          queryClient.invalidateQueries({
            queryKey: ['org', org?.id, 'roles'],
          }),
          queryClient.invalidateQueries({
            queryKey: ['auth-user'],
          }),
        ]);
      } catch (error) {
        const message =
          getAxiosErrorMessage(error) ??
          `Error updating organization${error ? `: ${error}` : ''}`;

        toast.error(message);
      }
    },
    [id, org],
  );

  if (isNil(org)) {
    return <Loading />;
  }

  return (
    <Stack>
      <ShowPageHeader
        prefix='Organization'
        value={id}
        actions={
          <>
            <EditOrganizationSettingsModal
              organization={org}
              disabled={disableEditing}
            />
            {isAdmin && (
              <>
                <IconButton {...bindTrigger(headerMenuState)}>
                  <MoreVertIcon />
                </IconButton>
                <Menu {...bindMenu(headerMenuState)}>
                  <MenuItem onClick={() => navigate('/admin/alis')}>
                    <ListItemIcon>
                      <MediationIcon />
                    </ListItemIcon>
                    <ListItemText>Alis Structure Mapping</ListItemText>
                  </MenuItem>
                </Menu>
              </>
            )}
          </>
        }
        data-testid='org-profileHeader'
      />
      <OrgSettingsPanel
        org={org}
        loading={orgsLoading || rolesLoading}
        roles={rolesData}
      />
      <Can permission={{ action: 'view', subject: 'virtual-care.admin.roles' }}>
        <Typography variant='h5' my={2}>
          Roles
        </Typography>
        <TableBase<RoleFieldTypes>
          itemName='Role'
          fields={orgProfileFields}
          data={updatedRoles}
          loading={loading}
          error={rolesError}
          renderModal={renderModal}
          modalLoading={orgsLoading}
          showExportMenu={false}
          onAddSubmit={handleAddSubmit}
          onEditSubmit={handleEditSubmit}
          disableAddButton={!isAdmin}
          disableEditing={!isAdmin}
          columnGroupingModel={columnGroupingModel}
          defaultPinnedColumns={['displayName']}
        />
      </Can>
    </Stack>
  );
};
