import type { GridRowId } from '@mui/x-data-grid-pro';

import type { AdminTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import type { Importable } from '../../ImportTable/types/importable';

const importProperAssignmentValidator = (
  _uniqueId: GridRowId,
  params: AdminTypes.BasestationDto,
  field: keyof AdminTypes.BasestationDto,
  _rows: Importable<AdminTypes.BasestationDto>[],
) => {
  if (params[field]) {
    return null;
  }

  const assignmentFields: (keyof AdminTypes.BasestationDto)[] = ['roomId'];

  const set = new Set(assignmentFields.map((e) => Boolean(params[e])));

  if (set.size !== 1) {
    return 'You have to assign organization and room or un-assign';
  }

  return null;
};

export default importProperAssignmentValidator;
