import {
  Button,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import { CheckCircleOutlined as VerifiedIcon } from '@mui/icons-material';

import PhoneNumberInput from '../PhoneNumberInput';

interface Props {
  confirmedPhoneNumber?: string;
  smsWithVideoLink?: boolean;
  isFallVideoSmsRole?: boolean;
  onChangeTriggered: () => void;
}

const VerifiedStage = ({
  confirmedPhoneNumber,
  isFallVideoSmsRole,
  smsWithVideoLink,
  onChangeTriggered,
}: Props) => (
  <>
    <DialogContent>
      <Stack direction='column' gap={2}>
        <Stack direction='row' alignItems='flex-end'>
          <PhoneNumberInput value={confirmedPhoneNumber} disabled />
          <Stack direction='row' mb={0.5} ml={1}>
            <VerifiedIcon color='success' />
            <Typography
              color={(theme) => theme.palette.success.main}
              sx={{ pl: 0.5 }}
            >
              Verified
            </Typography>
          </Stack>
        </Stack>
        {isFallVideoSmsRole && (
          <FormControlLabel
            control={<Switch checked={!!smsWithVideoLink} />}
            label='Receive video link with text message'
            disabled
          />
        )}
      </Stack>
    </DialogContent>
    <DialogActions>
      <Button onClick={onChangeTriggered} variant='contained'>
        Edit
      </Button>
    </DialogActions>
  </>
);

export default VerifiedStage;
