import type { TelesittingTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { Api } from '../../../../utility/client';

export const fetchExportProgress = async (exportId: string) => {
  const url = `/eventReview/v1/export/videos/${exportId}`;
  const res = await Api.get<TelesittingTypes.ExportProgress>(url);

  return res && res.data;
};
