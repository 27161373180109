import type { PropsWithChildren } from 'react';
import { Box } from '@mui/material';

export const AdminTableWrapper = ({ children }: PropsWithChildren) => (
  <Box
    sx={{
      gap: 2,
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      minHeight: 0,
    }}
  >
    {children}
  </Box>
);
