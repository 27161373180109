import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { isUsernameOrg } from '@inspiren-monorepo/util-users';
import type { SelectOptionsTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import ChangePassword from './ChangePassword';
import ControlledSelectLevelAccessBuildings from './ControlledSelectLevelAccessBuildings';
import ControlledSelectUnit from './ControlledSelectUnit';
import { SelectLevelAccess } from './SelectLevelAccess';
import SelectRole from './SelectRole';

import type { DataField, FieldTypes } from '../../../types/DataFields';
import type { RoleOption } from '../helpers/getRoleOptions';
import type { UserFields } from '../helpers/getUsersTableFields';

export type UserFormOrg = {
  id: string;
  displayName: string;
};

type Props<F extends FieldTypes> = {
  isAdmin: boolean;
  type: 'add' | 'edit';
  defaultComponents: Record<keyof F, JSX.Element | null>;
  fields: Record<keyof F, DataField<F>>;
  unitOptions: SelectOptionsTypes.UnitOption[];
  buildingOptions: SelectOptionsTypes.BuildingOption[];
  showPassword: boolean;
};

export const UserForm = <F extends FieldTypes>({
  isAdmin,
  type,
  defaultComponents,
  fields,
  showPassword,
  unitOptions,
  buildingOptions,
}: Props<F>) => {
  const orgId = import.meta.env.VITE_ORG_ID;
  const { watch, setValue, formState } = useFormContext<UserFields>();
  const { disabled } = formState;

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      const unit = unitOptions?.find((unit) => unit.id === value.unitId);

      if (
        value.levelAccess !== 'building' &&
        ['levelAccess', 'unitId'].includes(name as string)
      ) {
        setValue('levelAccessBuildingIds', unit ? [unit.buildingId] : []);
      } else if (
        name === 'levelAccessBuildingIds' &&
        !(value.levelAccessBuildingIds || []).includes(unit?.buildingId)
      ) {
        setValue('unitId', '');
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, setValue, unitOptions]);

  return (
    <>
      {isAdmin && defaultComponents.id}
      {defaultComponents.firstName}
      {defaultComponents.lastName}
      {isUsernameOrg(orgId) && defaultComponents.username}
      {defaultComponents.email}
      {defaultComponents.mobilePhone}
      {type === 'add' && defaultComponents.password}
      {type === 'edit' ? (
        <SelectRole items={fields.role.options as RoleOption[]} />
      ) : (
        defaultComponents.role
      )}
      <SelectLevelAccess />
      <ControlledSelectLevelAccessBuildings options={buildingOptions} />
      <ControlledSelectUnit options={unitOptions} />
      {showPassword && !disabled && <ChangePassword />}
    </>
  );
};
