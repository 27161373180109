import { useMemo } from 'react';
import { compact, isEmpty } from 'lodash';

import type { AdminTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { useAdminUsers } from '../../../hooks/useAdminUsers';
import { ControlledAutocomplete } from '../../../modals/ControlledAutocomplete';

import type { Control } from 'react-hook-form';

interface Props {
  id: string;
  label: string;
  control: Control;
  beacons?: AdminTypes.Beacon[];
}

export const SelectUser = ({ id, label, control, beacons }: Props) => {
  // TODO: use different hook that returns only needed fields
  const { isFetching, usersById, userIds } = useAdminUsers();

  const userIdsWithBeacons = useMemo(
    () => compact((beacons || []).map((beacon) => beacon.assignedTo)),
    [beacons],
  );

  return (
    <ControlledAutocomplete
      id={id}
      label={label}
      items={userIds}
      control={control}
      getOptionLabel={(userId) => {
        if (isEmpty(userId)) return '';
        const user = usersById[userId];
        return user
          ? `${userIdsWithBeacons.includes(user.domainId) ? '\uD83D\uDD17 ' : ''
          } ${user.firstName} ${user.lastName} (${user.email})`
          : '';
      }}
      loading={isFetching}
    />
  );
};
