import { useCallback, useEffect, useMemo } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';

import {
  PhoneNumberFormat,
  formatPhoneNumberSafe,
} from '@inspiren-monorepo/util-formatters';

import { comparePhoneNumberSafe } from '../helpers/comparePhoneNumberSafe';

import type { QueryKey } from '@tanstack/react-query';

export interface ChangePhoneNumberForm {
  mobilePhoneNumber: string;
  smsWithVideoLink?: boolean;
}

type ChangePhoneNumberFormValues = {
  mobilePhone: string | null;
  smsWithVideoLink: boolean;
};

interface Params {
  confirmedMobilePhone?: string;
  currentSmsWithVideoLink?: boolean;
  onConfirmedMobilePhoneChange: (mobilePhone: string | null) => void;
  updatePhoneNumberMutationFn: (
    params: ChangePhoneNumberFormValues,
  ) => Promise<ChangePhoneNumberFormValues>;
  userQueryKey: QueryKey;
  confirmClear: (clearPhoneNumber: () => void) => void;
}

export const useChangeStage = ({
  confirmedMobilePhone,
  currentSmsWithVideoLink,
  onConfirmedMobilePhoneChange,
  updatePhoneNumberMutationFn: mutationFn,
  userQueryKey,
  confirmClear,
}: Params) => {
  const queryClient = useQueryClient();

  const {
    mutate: updateMobilePhone,
    isPending,
    isError,
    error: updateMutationError,
  } = useMutation({
    mutationFn,
    onSuccess: (data) => {
      onConfirmedMobilePhoneChange(data.mobilePhone);

      queryClient.invalidateQueries({ queryKey: userQueryKey });
    },
  });

  const { handleSubmit, control, watch, setValue } =
    useForm<ChangePhoneNumberForm>({
      defaultValues: {
        mobilePhoneNumber: confirmedMobilePhone,
        smsWithVideoLink: currentSmsWithVideoLink,
      },
    });

  const mobilePhoneNumber = watch('mobilePhoneNumber');
  const formSmsWithVideoLink = watch('smsWithVideoLink');

  useEffect(() => {
    if (!mobilePhoneNumber) {
      setValue('smsWithVideoLink', false);
    }
  }, [mobilePhoneNumber, setValue]);

  const isTypedNumberAlreadyConfirmed = useMemo(
    () =>
      confirmedMobilePhone &&
      comparePhoneNumberSafe(mobilePhoneNumber, confirmedMobilePhone),
    [confirmedMobilePhone, mobilePhoneNumber],
  );

  const isDirty = useMemo(
    () =>
      !isTypedNumberAlreadyConfirmed ||
      !!formSmsWithVideoLink !== !!currentSmsWithVideoLink,
    [
      isTypedNumberAlreadyConfirmed,
      currentSmsWithVideoLink,
      formSmsWithVideoLink,
    ],
  );

  const upsertButtonText = useMemo(() => {
    if (!confirmedMobilePhone) return 'Set phone number';
    if (!mobilePhoneNumber) return 'Clear phone number';
    return 'Submit';
  }, [confirmedMobilePhone, mobilePhoneNumber, isTypedNumberAlreadyConfirmed]);

  const changeMobilePhone = useCallback(
    (mobilePhone: string | null, smsWithVideoLink: boolean) => {
      if (
        mobilePhone &&
        confirmedMobilePhone &&
        comparePhoneNumberSafe(mobilePhone, confirmedMobilePhone) &&
        smsWithVideoLink === currentSmsWithVideoLink
      ) {
        onConfirmedMobilePhoneChange(confirmedMobilePhone);

        return;
      }

      updateMobilePhone({
        mobilePhone: mobilePhone
          ? formatPhoneNumberSafe(mobilePhone, PhoneNumberFormat.INTERNATIONAL)
          : null,
        smsWithVideoLink,
      });
    },
    [
      confirmedMobilePhone,
      onConfirmedMobilePhoneChange,
      updateMobilePhone,
      currentSmsWithVideoLink,
    ],
  );

  const onUpsertMobilePhone = useCallback(
    (formFields: ChangePhoneNumberForm) => {
      const mobilePhone = formFields.mobilePhoneNumber;
      const smsWithVideoLink = formFields.smsWithVideoLink ?? false;

      if (confirmedMobilePhone && !mobilePhone) {
        confirmClear(() => changeMobilePhone(null, false));
      } else {
        changeMobilePhone(mobilePhone, smsWithVideoLink);
      }
    },
    [confirmedMobilePhone, confirmClear, changeMobilePhone],
  );

  const clearPhoneNumberField = () => {
    setValue('mobilePhoneNumber', '');
    setValue('smsWithVideoLink', false);
  };

  return {
    clearPhoneNumberField,
    upsertButtonText,
    isPending,
    isError,
    updateMutationError,
    control,
    mobilePhoneNumber,
    handleSetPhoneNumber: handleSubmit(onUpsertMobilePhone),
    isDirty,
  };
};
