import { useCallback, useMemo } from 'react';
import { Stack, Typography, Chip, Button } from '@mui/material';
import CableIcon from '@mui/icons-material/Cable';
import { LoadingButton } from '@mui/lab';
import { grey } from '@mui/material/colors';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { some, sortBy } from 'lodash';
import { useConfirm } from 'material-ui-confirm';
import { usePopupState } from 'material-ui-popup-state/hooks';
import toast from 'react-hot-toast';

import { getAxiosErrorMessage } from '@inspiren-monorepo/util-axios';
import type { AdminTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { AlisBedRow } from './AlisBedRow';
import { AssignRoomModal } from './AssignRoomModal';

import { unassignAlisRoom } from '../data-access/unassignAlisRoom';

type Props = {
  buildingId?: string;
  room: AdminTypes.AlisStructureRoom;
};

export const AlisRoomRow = ({ room, buildingId }: Props) => {
  const queryClient = useQueryClient();

  const popupState = usePopupState({
    variant: 'popover',
    popupId: `${room.id}`,
  });

  const confirm = useConfirm();

  const { mutate, isPending } = useMutation<
    void,
    unknown,
    {
      roomId: number;
      householdId: string;
    }
  >({
    mutationFn: async ({ roomId, householdId }) => {
      await unassignAlisRoom(roomId, householdId);
    },
    onSuccess: async () => {
      toast.success('Alis room unassigned successfully');
      popupState.close();

      await queryClient.invalidateQueries({
        queryKey: ['alis-community-structure'],
      });
    },
    onError: (error: any) => {
      const message =
        getAxiosErrorMessage(error) ??
        `Error unassigning room from apartment${error ? `: ${error}` : ''}`;

      toast.error(message);
    },
  });

  const handleUnassign = useCallback(() => {
    confirm({
      title: 'Are you sure?',
      confirmationText: 'Confirm',
      confirmationButtonProps: {
        color: 'primary',
      },
      dialogProps: {
        maxWidth: 'xs',
      },
    }).then(() => {
      mutate({
        roomId: room.id,
        householdId: room.connectedHousehold?.id as string,
      });
    });
  }, [room, mutate]);

  const canUnassign = useMemo(
    () => !some(room.beds, (bed) => bed.connectedRoom?.id),
    [room],
  );

  return (
    <Stack>
      <Stack direction='row' gap={2}>
        <Stack
          sx={{
            p: 2,
            pb: 1,
            backgroundColor: grey[100],
            borderTopLeftRadius: '12px',
            borderTopRightRadius: '12px',
            flex: 1,
          }}
        >
          <Stack direction='row' spacing={2} sx={{ mb: 2 }}>
            <Stack>
              <Typography
                variant='overline'
                sx={{ lineHeight: 1.4, color: grey[500] }}
              >
                Room:
              </Typography>
              <Typography variant='h5'>{room.name}</Typography>
            </Stack>
            <Stack direction='row' gap={2} sx={{ pl: 8 }}>
              <Chip variant='outlined' label={`Type: ${room.type}`} />
              <Chip variant='outlined' label={`Category: ${room.category}`} />
              {room.isDisabled && <Chip label='Disabled' />}
            </Stack>
          </Stack>
        </Stack>
        <Stack
          sx={{
            flex: 1,
            p: 2,
            borderTopLeftRadius: '12px',
            borderTopRightRadius: '12px',
            border: `${grey[300]} dashed 2px`,
            borderBottomWidth: 0,
          }}
        >
          <Stack direction='row' spacing={2} sx={{ mb: 2 }}>
            {room.connectedHousehold?.id && (
              <Stack
                direction='row'
                justifyContent='space-between'
                sx={{ flex: 1, width: '100%' }}
              >
                <LoadingButton
                  onClick={handleUnassign}
                  loading={isPending}
                  disabled={!canUnassign}
                >
                  Unassign
                </LoadingButton>
                <Stack
                  alignItems='flex-end'
                  justifyContent='flex-start'
                  sx={{ flex: 1 }}
                >
                  <Typography
                    variant='overline'
                    sx={{ lineHeight: 1.4, color: grey[500] }}
                  >
                    APARTMENT:
                  </Typography>
                  <Typography variant='h5'>{room.name}</Typography>
                </Stack>
              </Stack>
            )}
            {!room.connectedHousehold?.id && (
              <Stack
                alignItems='flex-end'
                justifyContent='center'
                sx={{ flex: 1 }}
              >
                <AssignRoomModal room={room} buildingId={buildingId}>
                  <Button
                    startIcon={<CableIcon />}
                    variant='contained'
                    color='inherit'
                    disabled={!buildingId}
                  >
                    Connect to Apartment
                  </Button>
                </AssignRoomModal>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>
      {sortBy(room.beds, 'id').map((bed) => (
        <AlisBedRow key={`${room.id}-${bed.id}`} bed={bed} room={room} />
      ))}
    </Stack>
  );
};
