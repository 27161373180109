import type { AdminTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { Api } from '../../../utility/client';

export const getCareLevels = async (building?: string) => {
  const res = await Api.get<AdminTypes.CareLevel[]>('/admin/v1/care-levels', {
    params: { building },
  });

  return res.data;
};
