/* eslint-disable no-param-reassign */
import { useCallback, useMemo, useState } from 'react';
import { Stack } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { isEmpty, isString, mapValues, some, sortBy, values } from 'lodash';
import * as yup from 'yup';

import { positiveInt } from '@inspiren-monorepo/shared-react/browser';
import { SORT_OPTIONS } from '@inspiren-monorepo/shared-types';
import type { AdminTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { SelectRole } from './SelectRole';
import SelectTrainingVideo from './SelectTrainingVideo';
import { SelectUnit } from './SelectUnit';

import { ipValidationRegex } from '../../../../../utility/regex/ip-regex';
import { putOrg } from '../../../data-access/putOrg';
import { useTrainingVideos } from '../../../hooks/useTrainingVideo';
import EditModal from '../../../modals/EditModal';
import FormCategory from '../../../modals/FormCategory';

import type { RenderFormModal } from '../../../modals/FormModalBase';
import type { DataField } from '../../../types/DataFields';

const arrayFieldSchema = yup.array().transform((value, originalValue) =>
  typeof originalValue !== 'string'
    ? value
    : originalValue
        .split(',')
        .map((item: string) => item.trim())
        .filter((item) => !isEmpty(item)),
);

export type EditableSettings = Omit<AdminTypes.OrganizationDto, 'id'>;

const clientTypes = [
  {
    label: 'Patient',
    value: 'Patient',
  },
  {
    label: 'Resident',
    value: 'Resident',
  },
];

export const alertLevels = [
  {
    label: '0 - Send to HILQ',
    value: 0,
  },
  {
    label: '1 - Send Notif Immediately',
    value: 1,
  },
  {
    label: '2 - No Notification',
    value: 2,
  },
];

function nullifyEmptyStrings(data: AdminTypes.OrganizationDto) {
  return mapValues(data, (value) =>
    isString(value) && value.trim() === '' ? null : value,
  ) as AdminTypes.OrganizationDto;
}

export const fields: Record<
  string,
  DataField<EditableSettings & Pick<AdminTypes.OrganizationDto, 'id'>>
> = {
  id: {
    field: 'id',
    label: 'Organization ID',
    editable: false,
    width: 150,
    editType: 'text',
  },
  domains: {
    field: 'domains',
    label: 'Domains',
    width: 250,
    editType: 'text',
    initialValue: [],
    schema: arrayFieldSchema.when(
      [
        'auth0TenantUrl',
        'auth0WebClientId',
        'auth0MobileClientId',
        'auth0EnterpriseConnections',
        'auth0DbConnection',
      ],
      {
        is: (...formValues: string[]) =>
          some(formValues, (value) => !isEmpty(value)),
        then: (schema) =>
          schema
            .min(1, 'You must provide at least one domain.')
            .required('You must provide at least one domain.'),
      },
    ),
    schemaExclude: true,
    tooltip: 'Comma separated list of domains used by this organization',
  },
  displayName: {
    field: 'displayName',
    label: 'Display name',
    width: 250,
    editType: 'text',
    schema: yup.string().required('You must provide an organization name'),
  },
  ipAllowList: {
    field: 'ipAllowList',
    label: 'Allowed IP addresses',
    width: 220,
    editType: 'text',
    hideOnAdd: false,
    initialValue: [],
    schema: arrayFieldSchema.test({
      message: 'One of the values is neither IP address nor CIDR notation',
      test: (ips) =>
        !ips || ips.every((ip) => !!ip && ipValidationRegex.test(ip)),
    }),
  },
  patientType: {
    field: 'patientType',
    label: 'Client Type',
    width: 250,
    editType: 'select',
    options: sortBy(clientTypes, 'label'),
    tooltip:
      'The label by which clients of the facility will be referred to throughout the system',
  },
  staffBeaconsOnly: {
    field: 'staffBeaconsOnly',
    label: 'Beaconed Check-ins Only',
    width: 250,
    type: 'boolean',
    editType: 'boolean',
    schema: yup.boolean(),
    tooltip: 'Update Last Checked Only from Beaconed Events',
  },
  roomsDefaultSort: {
    field: 'roomsDefaultSort',
    label: 'Default sort',
    width: 250,
    editType: 'select',
    options: sortBy(SORT_OPTIONS, 'label'),
    tooltip: 'The default sort order of rooms in the mobile app.',
  },
  defaultTrainingVideoUrlMobile: {
    field: 'defaultTrainingVideoUrlMobile',
    label: 'Default Training Video (Mobile)',
    width: 300,
  },
  ipList: {
    field: 'ipList',
    label: 'Auto-Join IPs',
    width: 250,
    editType: 'text',
    initialValue: [],
    schema: arrayFieldSchema.test({
      message: 'One of the values is neither IP address nor CIDR notation',
      test: (ips) =>
        !ips || ips.every((ip) => !!ip && ipValidationRegex.test(ip)),
    }),
    tooltip:
      'Mobile Apps that join from this list of IP Addresses will automatically connect to this Org',
  },
  autoLogout: {
    field: 'autoLogout',
    label: 'Mobile Session Duration (hours)',
    width: 250,
    editType: 'text',
    schema: yup.number().nullable(),
    tooltip:
      'The duration in hours that a mobile session will last before the user is logged out.',
  },
  auth0TenantUrl: {
    editable: false,
    field: 'auth0TenantUrl',
    label: 'Tenant URL',
    width: 250,
    editType: 'text',
    schema: yup.string(),
    schemaExclude: true,
    tooltip: 'The URL of the Auth0 tenant used for this organization.',
  },
  auth0WebClientId: {
    editable: false,
    field: 'auth0WebClientId',
    label: 'Web Client ID',
    width: 250,
    editType: 'text',
    schema: yup.string(),
    schemaExclude: true,
    tooltip:
      'The client ID of the Auth0 used for this organization in the web application.',
  },
  auth0MobileClientId: {
    editable: false,
    field: 'auth0MobileClientId',
    label: 'Mobile Client ID',
    width: 250,
    editType: 'text',
    schema: yup.string(),
    schemaExclude: true,
    tooltip:
      'The client ID of the Auth0 used for this organization in the mobile application.',
  },
  auth0EnterpriseConnections: {
    editable: false,
    field: 'auth0EnterpriseConnections',
    label: 'Enterprise Connections',
    width: 250,
    editType: 'text',
    initialValue: [],
    schema: arrayFieldSchema,
    tooltip:
      'Comma separated list of enterprise connections used by this organization. ' +
      'For these connections we will create a user with default role if user does not exist.',
  },
  auth0DbConnection: {
    editable: false,
    field: 'auth0DbConnection',
    label: 'Database Connection',
    editType: 'text',
    width: 250,
    schema: yup.string().trim(),
    schemaExclude: true,
    tooltip:
      'The database connection used by this organization. ' +
      'User will be added to this connection when created from Admin page.',
  },
  defaultRoleId: {
    field: 'defaultRoleId',
    label: 'Default Role ID',
    width: 250,
    editType: 'text',
    schema: yup.string().trim().nullable(),
    tooltip:
      'The default role ID used by this organization. ' +
      'User will be assigned to this when created from an enterprise connection.',
  },
  beaconsFourDigitValidation: {
    field: 'beaconsFourDigitValidation',
    label: 'Four Digit Validation',
    width: 250,
    type: 'boolean',
    editType: 'boolean',
    schema: yup.boolean(),
    tooltip:
      'If enabled, the system will require a 4 digit ID suffix for each beacon. ' +
      'If disabled, the system will not require a 4 digit ID suffix for each beacon.',
  },
  defaultLevelAccess: {
    field: 'defaultLevelAccess',
    label: 'Default Level Access',
    width: 250,
    editType: 'select',
    options: [
      { value: '', label: 'None' },
      { value: 'org', label: 'Organization' },
      { value: 'building', label: 'Building' },
      { value: 'unit', label: 'Unit' },
    ],
    tooltip:
      'The default level access for this organization. ' +
      'This will be used when creating a new user.',
  },
  defaultUnitId: {
    field: 'defaultUnitId',
    label: 'Default Unit ID',
    width: 250,
    editType: 'text',
    schema: yup.string().trim().nullable(),
    tooltip:
      'The default unit ID used by this organization. ' +
      'This will be used when creating a new user.',
  },
  labelAllNotifs: {
    field: 'labelAllNotifs',
    label: 'Label All Activity',
    width: 250,
    type: 'boolean',
    editType: 'boolean',
    schema: yup.boolean(),
    tooltip:
      'If enabled, all notifications will be labeled regardless of fall risk. ' +
      'If disabled, labeled notifications will depend on fall risk level',
  },
  textMessageAlerts: {
    field: 'textMessageAlerts',
    label: 'Text Message Alerts',
    width: 250,
    type: 'boolean',
    editType: 'boolean',
    schema: yup.boolean(),
    tooltip:
      'If enabled, users are able to enter their phone numbers to receive SMS Notifications for Falls.',
  },
  fallLevel: {
    field: 'fallLevel',
    label: 'Fall Level',
    width: 250,
    editType: 'select',
    options: sortBy(alertLevels, 'label'),
  },
  fallExpireAfter: {
    field: 'fallExpireAfter',
    label: 'Fall Exp. Time (seconds)',
    width: 250,
    editType: 'text',
    schema: yup.number().nullable(),
  },
  outOfBedLevel: {
    field: 'outOfBedLevel',
    label: 'Out of Bed Level',
    width: 250,
    editType: 'select',
    options: sortBy(alertLevels, 'label'),
  },
  outOfBedExpireAfter: {
    field: 'outOfBedExpireAfter',
    label: 'Out Of Bed Exp. Time (seconds)',
    width: 250,
    editType: 'text',
    schema: yup.number().nullable(),
  },
  outOfChairLevel: {
    field: 'outOfChairLevel',
    label: 'Out of Chair Level',
    width: 250,
    editType: 'select',
    options: sortBy(alertLevels, 'label'),
  },
  outOfChairExpireAfter: {
    field: 'outOfChairExpireAfter',
    label: 'Out of Chair Exp. Time (seconds)',
    width: 250,
    editType: 'text',
    schema: yup.number().nullable(),
  },
  hiddenLevel: {
    field: 'hiddenLevel',
    label: 'Out of View Level',
    width: 250,
    editType: 'select',
    options: sortBy(alertLevels, 'label'),
  },
  hiddenExpireAfter: {
    field: 'hiddenExpireAfter',
    label: 'Out of View Exp. Time (seconds)',
    width: 250,
    editType: 'text',
    schema: yup.number().nullable(),
  },
  leavingBedLevel: {
    field: 'leavingBedLevel',
    label: 'Moving in Bed Level',
    width: 300,
    editType: 'select',
    options: sortBy(alertLevels, 'label'),
  },
  leavingBedExpireAfter: {
    field: 'leavingBedExpireAfter',
    label: 'Moving in Bed Exp. Time (seconds)',
    width: 400,
    editType: 'text',
    schema: yup.number().nullable(),
  },
  bathroomSensorEnabled: {
    field: 'bathroomSensorEnabled',
    label: 'Bathroom Sensor Enabled',
    width: 250,
    type: 'boolean',
    editType: 'boolean',
    schema: yup.boolean(),
    tooltip:
      'If enabled, bathroom status will be displayed on web and mobile. ' +
      'If disabled, bathroom status will not be displayed.',
  },
  notificationRepeats: {
    field: 'notificationRepeats',
    label: 'Notification Repeats',
    width: 250,
    editType: 'text',
    schema: yup.number().nullable(),
    tooltip: 'The number of times a notification will send for all alerts.',
  },
  notificationDelay: {
    field: 'notificationDelay',
    label: 'Notification Delay',
    width: 250,
    editType: 'text',
    schema: yup.number().nullable(),
    tooltip: 'The delay in seconds between notifications.',
  },
  alisOrgId: {
    field: 'alisOrgId',
    label: 'Alis ID',
    width: 100,
    editType: 'text',
    schema: positiveInt({ nullable: true }),
  },
  yardiOrgId: {
    field: 'yardiOrgId',
    label: 'Yardi ID',
    width: 100,
    editType: 'text',
    schema: yup.string().nullable(),
  },
  pccOrgUuid: {
    field: 'pccOrgUuid',
    label: 'PCC Org ID',
    width: 250,
    editType: 'text',
    schema: yup.string().nullable(),
  },
};

export const categoriesWithFields: {
  [category: string]: (keyof EditableSettings)[];
} = {
  General: [
    'displayName',
    'domains',
    'ipAllowList',
    'patientType',
    'staffBeaconsOnly',
    'beaconsFourDigitValidation',
    'bathroomSensorEnabled',
  ],
  Notifications: [
    'notificationRepeats',
    'notificationDelay',
    'fallExpireAfter',
    'fallLevel',
    'outOfBedExpireAfter',
    'outOfBedLevel',
    'outOfChairExpireAfter',
    'outOfChairLevel',
    'hiddenExpireAfter',
    'hiddenLevel',
    'leavingBedExpireAfter',
    'leavingBedLevel',
    'labelAllNotifs',
    'textMessageAlerts',
  ],
  'User Defaults': ['defaultRoleId', 'defaultLevelAccess', 'defaultUnitId'],
  Mobile: [
    'ipList',
    'defaultTrainingVideoUrlMobile',
    'autoLogout',
    'roomsDefaultSort',
  ],
  Auth0: [
    'auth0TenantUrl',
    'auth0WebClientId',
    'auth0MobileClientId',
    'auth0EnterpriseConnections',
    'auth0DbConnection',
  ],
  Integrations: ['pccOrgUuid', 'alisOrgId', 'yardiOrgId'],
};

const modalFields = values(fields);

interface Props {
  organization: AdminTypes.OrganizationDto;
  disabled: boolean;
}

export const EditOrganizationSettingsModal = ({
  organization,
  disabled,
}: Props) => {
  const [enabled, setEnabled] = useState(false);

  const [saveError, setSaveError] = useState<string | undefined>();

  const queryClient = useQueryClient();

  const initialValues: AdminTypes.OrganizationDto | undefined = useMemo(
    () =>
      Object.entries(organization).reduce(
        (
          curr: AdminTypes.OrganizationDto & { [key: string]: unknown },
          [key, value]: [string, unknown],
        ) => {
          curr[key] = value;

          if (fields[key]?.editType === 'text') {
            curr[key] = value ?? '';
          }

          if (fields[key]?.editType === 'boolean') {
            curr[key] = value ?? false;
          }

          return curr;
        },
        {} as AdminTypes.OrganizationDto & { [key: string]: unknown },
      ),
    [organization],
  );

  const { mutateAsync, isPending } = useMutation({
    mutationFn: (data: AdminTypes.OrganizationDto) =>
      putOrg({
        ...nullifyEmptyStrings(data),
        roomsDefaultSort: data.roomsDefaultSort || null,
        textMessageAlerts: data.textMessageAlerts || false,
      }),
    onSuccess: () => {
      setSaveError(undefined);
      queryClient.resetQueries({ queryKey: ['orgs'] });
      queryClient.resetQueries({ queryKey: ['org', organization.id] });
    },
    onError: (error) => {
      setSaveError(error?.message);
    },
  });

  const onSubmit = useCallback(
    async (data: AdminTypes.OrganizationDto) => mutateAsync(data),
    [mutateAsync],
  );

  const { isLoading: isTrainingVideosLoading, isError: isTrainingVideosError } =
    useTrainingVideos({ enabled });

  const renderModal: RenderFormModal<AdminTypes.OrganizationDto> = useCallback(
    ({ defaultComponents, control }) => (
      <>
        {Object.keys(categoriesWithFields).map((category) => (
          <FormCategory
            defaultExpanded={
              category === 'General' || category === 'Notifications'
            }
            isAccordion
            title={category}
            key={category}
          >
            <Stack
              flexDirection='row'
              flexWrap='wrap'
              justifyContent='space-between'
            >
              {categoriesWithFields[category].map((field) => {
                if (category === 'Notifications') {
                  return (
                    <div style={{ width: '40%' }} key={field}>
                      {defaultComponents[field]}
                    </div>
                  );
                }

                switch (field) {
                  case 'defaultTrainingVideoUrlMobile':
                    return (
                      <SelectTrainingVideo
                        control={control}
                        id='defaultTrainingVideoUrlMobile'
                        label='Default Training Video (Mobile)'
                        key='defaultTrainingVideoUrlMobile'
                      />
                    );
                  case 'defaultRoleId':
                    return (
                      <SelectRole
                        org={organization.id}
                        control={control}
                        id='defaultRoleId'
                        label='Default Role ID'
                        key='defaultRoleId'
                      />
                    );
                  case 'defaultUnitId':
                    return (
                      <SelectUnit
                        org={organization.id}
                        control={control}
                        id='defaultUnitId'
                        label='Default Unit ID'
                        key='defaultUnitId'
                      />
                    );
                  default:
                    return defaultComponents[field];
                }
              })}
            </Stack>
          </FormCategory>
        ))}
      </>
    ),
    [organization.displayName, organization.id],
  );

  const buttonLoading = useMemo(
    () => isPending || isTrainingVideosLoading,
    [isPending || isTrainingVideosLoading],
  );

  const loading = useMemo(
    () => !enabled || !initialValues || buttonLoading,
    [enabled, initialValues, buttonLoading],
  );

  const error = useMemo(
    () => isTrainingVideosError || saveError,
    [isTrainingVideosError, saveError],
  );

  return (
    <EditModal<AdminTypes.OrganizationDto>
      itemName={`Organization ${organization.displayName}`}
      initialValues={initialValues}
      loading={loading}
      error={error}
      isSaveError={Boolean(saveError)}
      fields={modalFields}
      onOpen={() => {
        setEnabled(true);
        setSaveError(undefined);
      }}
      onSubmit={onSubmit}
      openIcon={<Edit fontSize='small' />}
      renderModal={renderModal}
      disabled={disabled}
    >
      <LoadingButton
        loading={buttonLoading}
        startIcon={<Edit fontSize='inherit' />}
        variant='contained'
      >
        Edit
      </LoadingButton>
    </EditModal>
  );
};
