import type { AdminTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { Api } from '../../../utility/client';

export const postFloor = async (floor: AdminTypes.FloorCreateDto) => {
  try {
    const res = await Api.post<AdminTypes.FloorDto>('/admin/v1/floors', floor);
    return res && res.data;
  } catch (error) {
    console.error('error adding floor');
    throw error;
  }
};
