import { Button, Stack, Tooltip } from '@mui/material';
import {
  GridFooterContainer,
  type GridSlots,
  type PropsFromSlot,
} from '@mui/x-data-grid-pro';

export interface ImportTableFooterProps
  extends PropsFromSlot<GridSlots['footer']> {
  canImport?: boolean;
  onImport: () => void;
}

const ImportTableFooter = ({ canImport, onImport }: ImportTableFooterProps) => (
  <GridFooterContainer
    sx={{
      paddingLeft: '4px',
    }}
  >
    <Stack direction='row' flexWrap='wrap' width='100%'>
      <Tooltip
        title={
          canImport
            ? 'Click this button to send records into database'
            : 'Ensure that there is at least one row and that rows are saved.'
        }
      >
        <span>
          <Button
            variant='contained'
            size='small'
            disabled={!canImport}
            onClick={onImport}
          >
            Import data
          </Button>
        </span>
      </Tooltip>
    </Stack>
  </GridFooterContainer>
);

export default ImportTableFooter;
