import type React from 'react';
import { FormControl, FormHelperText } from '@mui/material';
import { isEmpty } from 'lodash';
import { Controller } from 'react-hook-form';

import LoadingAutocomplete from '../../shared/LoadingAutocomplete';

import type { Control } from 'react-hook-form';

type Props<T> = {
  id: string;
  label: string;
  items: T[];
  control: Control<any, any>;
  disabled?: boolean;
  onChange?: (event: any) => void;
  defaultValue?: T;
  loading?: boolean;
  getOptionLabel?: (item: T) => string;
  isOptionEqualToValue?: (option: T, value: T) => boolean;
  tooltip?: string;
};

export const ControlledAutocomplete = <T,>({
  id,
  label,
  items,
  control,
  disabled,
  onChange,
  defaultValue,
  loading,
  isOptionEqualToValue,
  getOptionLabel,
  tooltip,
}: Props<T>) => (
  <Controller
    name={id}
    control={control}
    defaultValue={defaultValue}
    render={({
      field: { name, value, onChange: onControllerChange },
      fieldState: { error },
    }) => {
      const handleChange = (
        _e: React.ChangeEvent<object>,
        newValue: T | null,
      ) => {
        onControllerChange(newValue);
        onChange?.(newValue);
      };

      return (
        <FormControl fullWidth>
          <LoadingAutocomplete<T>
            id={name}
            options={items}
            value={isEmpty(value) ? undefined : value}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={isOptionEqualToValue}
            onChange={handleChange}
            fullWidth
            margin='dense'
            label={label}
            disabled={disabled}
            loading={loading}
            error={Boolean(error)}
            tooltip={tooltip}
          />
          <FormHelperText error={Boolean(error)}>
            {error?.message}
          </FormHelperText>
        </FormControl>
      );
    }}
  />
);
