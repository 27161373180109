import type { PropsWithChildren } from 'react';
import {
  Alert,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';

import type { AdminTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { CopyTextButton } from '../../../../../shared/CopyTextButton';

type Props = {
  tokenDto: AdminTypes.CreateExternalApiKeyResponse;
  cancel: () => void;
};

const TokenText = ({ children }: PropsWithChildren) => (
  <Typography sx={{ paddingLeft: 0.5 }}>{children}</Typography>
);

export const TokenResultStage = ({ tokenDto, cancel }: Props) => (
  <>
    <DialogContent>
      <DialogContentText sx={{ mb: 2 }}>
        <Alert severity='info' sx={{ marginBottom: '0.5rem' }}>
          Please save this API Key in a secure place. Your will not be able to
          access the key once you close this popup.
        </Alert>
        <TokenText>
          Your API Key is{' '}
          <CopyTextButton
            sx={{
              paddingRight: 0,
            }}
            valueToCopy={tokenDto.apiKey}
          />
        </TokenText>
        <TokenText>
          It will expire on{' '}
          <i>{format(new Date(tokenDto.expiresAt), 'MM/dd/yyyy hh:mm aaa')}</i>
        </TokenText>
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={cancel}>Close</Button>
    </DialogActions>
  </>
);
