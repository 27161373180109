import { useCallback } from 'react';
import { type InfiniteData, useQueryClient } from '@tanstack/react-query';
import { omit } from 'lodash';

import type { AdminTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

interface UsersTableQueryResponse {
  users: AdminTypes.User[];
  lastKey?: string;
}

export const useSetDeactivateUserQueryData = (orgId?: string) => {
  const queryClient = useQueryClient();

  const setDeactivateUserQueryData = useCallback(
    (userId: string, deactivate: boolean) => {
      queryClient.setQueryData(
        ['users', orgId],
        (oldData: InfiniteData<UsersTableQueryResponse>) => {
          if (!oldData) {
            return {
              pages: [],
              pageParams: [],
            };
          }

          const newData: InfiniteData<UsersTableQueryResponse> = {
            ...oldData,
            pages: oldData.pages.map((page) => ({
              ...page,
              users: page.users.map((user) => {
                if (user.id === userId) {
                  return deactivate
                    ? { ...user, deletedAt: new Date().toISOString() }
                    : omit(user, 'deletedAt');
                }

                return user;
              }),
            })),
          };

          return newData;
        },
      );
    },
    [orgId, queryClient],
  );

  return setDeactivateUserQueryData;
};
