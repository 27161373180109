import { useCallback } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import * as yup from 'yup';

import { positiveInt, stringId } from '@inspiren-monorepo/shared-react/browser';
import { getAxiosErrorMessage } from '@inspiren-monorepo/util-axios';

import { useIsAdmin } from '../../../hooks/useIsAdmin';
import { TableBase } from '../components/TableBase';
import { getBuildings } from '../data-access/getBuildings';
import { getFloors } from '../data-access/getFloors';
import { postFloor } from '../data-access/postFloor';
import { putFloor } from '../data-access/putFloor';
import FormCategory from '../modals/FormCategory';
import SelectBuilding from '../modals/special/SelectBuilding';

import type {
  OnSubmitFormModal,
  RenderFormModal,
} from '../modals/FormModalBase';
import type { DataFields, FieldTypes } from '../types/DataFields';

export interface FloorFields extends FieldTypes {
  id?: string;
  buildingId: string;
  floorNumber: string;
  pccFloorId?: number;
  yardiFloorId?: string;
}

export const fields: DataFields<FloorFields> = [
  {
    field: 'id',
    label: 'ID',
    width: 'hidden',
  },
  {
    field: 'buildingId',
    label: 'Building',
    width: 200,
    editable: false,
    valueGetter: (_value, row) =>
      row.buildingDisplayName || row.buildingDomainId,
    schema: yup.string().required('Building is required'),
  },
  {
    field: 'floorNumber',
    label: 'Floor Number',
    width: 160,
    editType: 'text',
    editable: false,
    schema: positiveInt({ required: true }),
  },
  {
    field: 'pccFloorId',
    label: 'PCC Floor ID',
    width: 'hidden',
    editType: 'text',
    schema: positiveInt({ nullable: true }),
  },
  {
    field: 'yardiFloorId',
    label: 'Yardi Floor ID',
    width: 'hidden',
    editType: 'text',
    schema: stringId({ nullable: true }),
  },
];

const FloorsTable = () => {
  const queryClient = useQueryClient();
  const { isAdmin } = useIsAdmin();

  const {
    isLoading: buildingsLoading,
    isError: buildingsError,
    data: buildings,
  } = useQuery({
    queryKey: ['buildings'],
    queryFn: getBuildings,
  });

  const {
    isLoading: floorsLoading,
    isError: floorsError,
    data: floors,
  } = useQuery({
    queryKey: ['floors'],
    queryFn: getFloors,
  });

  const handleAddSubmit: OnSubmitFormModal<FloorFields> = useCallback(
    async ({ buildingId, floorNumber, pccFloorId, yardiFloorId }) => {
      try {
        await postFloor({
          buildingId,
          floorNumber: Number(floorNumber),
          pccFloorId: pccFloorId ? Number(pccFloorId) : null,
          yardiFloorId,
        });

        await queryClient.invalidateQueries({
          queryKey: ['floors'],
        });

        const building = buildings?.find((b) => b.id === buildingId);

        toast.success(
          `Successfully added floor ${floorNumber} to ${building?.displayName}`,
        );
      } catch (error) {
        const message =
          getAxiosErrorMessage(error) ??
          `Error adding floor${error ? `: ${error}` : ''}`;

        toast.error(message);
      }
    },
    [buildings],
  );

  const handleEditSubmit: OnSubmitFormModal<FloorFields> = useCallback(
    async ({ id, floorNumber, buildingId, pccFloorId, yardiFloorId }) => {
      try {
        await putFloor({
          id: id as string,
          pccFloorId,
          yardiFloorId,
        });

        await queryClient.invalidateQueries({
          queryKey: ['floors'],
        });

        const building = buildings?.find((b) => b.id === buildingId);

        toast.success(
          `Successfully updated floor ${floorNumber} in ${building?.displayName}`,
        );
      } catch (error) {
        const message =
          getAxiosErrorMessage(error) ??
          `Error updating floor${error ? `: ${error}` : ''}`;

        toast.error(message);
      }
    },
    [buildings],
  );

  const renderModal = useCallback<RenderFormModal<FloorFields>>(
    ({ defaultComponents, control, type }) => (
      <>
        <SelectBuilding type={type} control={control} />
        {defaultComponents.floorNumber}
        {isAdmin && (
          <FormCategory
            defaultExpanded={false}
            title='Integrations'
            isAccordion
            sx={{ mt: 1 }}
          >
            {defaultComponents.pccFloorId}
            {defaultComponents.yardiFloorId}
          </FormCategory>
        )}
      </>
    ),
    [isAdmin],
  );

  return (
    <TableBase<FloorFields>
      itemName='Floor'
      fields={fields}
      data={floors}
      disableEditing={!isAdmin}
      loading={floorsLoading}
      error={floorsError}
      modalLoading={buildingsLoading}
      modalError={buildingsError}
      onAddSubmit={handleAddSubmit}
      onEditSubmit={handleEditSubmit}
      renderModal={renderModal}
    />
  );
};

export default FloorsTable;
