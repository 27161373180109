import type React from 'react';
import { useCallback, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';

import { getAxiosErrorMessage } from '@inspiren-monorepo/util-axios';
import type { AdminTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { postBuilding } from '../../../../../utility';
import { usaStatesMap } from '../../../../../utility/constants';
import AddModal from '../../../modals/AddModal';
import FormCategory from '../../../modals/FormCategory';
import { type BuildingFields, fields } from '../../../tables/BuildingsTable';

import type {
  OnSubmitFormModal,
  RenderFormModal,
} from '../../../modals/FormModalBase';

type Props = {
  community: AdminTypes.AlisCommunity;
  children: React.ReactElement;
};

export const AddBuildingModal = ({ community, children }: Props) => {
  const queryClient = useQueryClient();

  const renderModal = useCallback<RenderFormModal<BuildingFields>>(
    ({ defaultComponents }) => (
      <>
        {defaultComponents.buildingId}
        {defaultComponents.displayName}
        {defaultComponents.address}
        {defaultComponents.city}
        {defaultComponents.state}
        {defaultComponents.zip}
        {defaultComponents.country}
        {defaultComponents.tz}
        <FormCategory defaultExpanded title='Integrations' isAccordion>
          {defaultComponents.pccFacilityId}
          {defaultComponents.yardiBuildingId}
          {defaultComponents.alisCommunityId}
        </FormCategory>
      </>
    ),
    [],
  );

  const handleAddSubmit: OnSubmitFormModal<BuildingFields> = useCallback(
    async (building) => {
      try {
        await postBuilding(building);

        await Promise.all([
          queryClient.invalidateQueries({
            queryKey: ['alis-community-structure'],
          }),
          queryClient.invalidateQueries({
            queryKey: ['building-options'],
          }),
        ]);

        toast.success(`Successfully added building ${building.buildingId}`);
      } catch (error) {
        const message =
          getAxiosErrorMessage(error) ??
          `Error adding building${error ? `: ${error}` : ''}`;

        toast.error(message);
      }
    },
    [],
  );

  const initialTimeZone = useMemo(() => {
    if (community.city.includes('Chicago')) {
      return 'America/Chicago';
    }

    if (community.city.includes('Los Angeles')) {
      return 'America/Los_Angeles';
    }

    if (community.city.includes('Denver')) {
      return 'America/Denver';
    }

    return 'America/New_York';
  }, [community]);

  return (
    <AddModal
      itemName='Building'
      fields={fields}
      onSubmit={handleAddSubmit}
      renderModal={renderModal}
      initialValues={{
        id: '',
        orgId: import.meta.env.VITE_ORG_ID,
        displayName: community.communityName,
        alisCommunityId: community.communityId,
        buildingId: '',
        address: '', // TODO: Get from community details endpoint
        city: community.city,
        state: usaStatesMap[community.state],
        zip: community.zip,
        country: 'USA',
        domainId: '',
        tz: initialTimeZone,
      }}
    >
      {children}
    </AddModal>
  );
};
