import {
  Dialog,
  DialogTitle,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from '@mui/material';
import { PhoneIphone as PhoneIcon } from '@mui/icons-material';
import {
  bindDialog,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';

import {
  PhoneNumberVerificationStage,
  usePhoneNumberVerification,
} from '@inspiren-monorepo/shared-react/universal';

import ChangeStage from './stages/ChangeStage';
import NotVerifiedStage from './stages/NotVerifiedStage';
import VerifiedStage from './stages/VerifiedStage';

import { useCurrentUser } from '../../../../HOC/CurrentUserContextProvider';

export const SmsSettings = () => {
  const { user } = useCurrentUser();

  const popupState = usePopupState({
    variant: 'dialog',
    popupId: 'phone-number-verification-modal',
  });

  const {
    stage,
    phoneNumber,
    setStage,
    setIsPhoneNumberVerified,
    onConfirmedPhoneNumberChange,
  } = usePhoneNumberVerification({
    initialIsPhoneNumberVerified: user?.mobilePhoneVerified,
    initialPhoneNumber: user?.mobilePhone,
  });

  return (
    <>
      <MenuItem {...bindTrigger(popupState)}>
        <ListItemIcon>
          <PhoneIcon fontSize='small' />
        </ListItemIcon>
        <ListItemText>SMS Settings</ListItemText>
      </MenuItem>

      <Dialog fullWidth {...bindDialog(popupState)}>
        <DialogTitle>SMS Settings</DialogTitle>

        {stage === PhoneNumberVerificationStage.CHANGE && (
          <ChangeStage
            confirmedMobilePhone={phoneNumber}
            goBack={() => setStage(PhoneNumberVerificationStage.VERIFIED)}
            onConfirmedMobilePhoneChange={onConfirmedPhoneNumberChange}
            smsWithVideoLink={user?.smsWithVideoLink}
            isFallVideoSmsRole={user?.fallVideoSMSRole}
          />
        )}

        {stage === PhoneNumberVerificationStage.NOT_VERIFIED && (
          <NotVerifiedStage
            confirmedPhoneNumber={phoneNumber}
            goBack={() => setStage(PhoneNumberVerificationStage.CHANGE)}
            onConfirmed={() => {
              setStage(PhoneNumberVerificationStage.VERIFIED);
              setIsPhoneNumberVerified(true);
            }}
          />
        )}

        {stage === PhoneNumberVerificationStage.VERIFIED && (
          <VerifiedStage
            confirmedPhoneNumber={phoneNumber}
            smsWithVideoLink={user?.smsWithVideoLink}
            isFallVideoSmsRole={user?.fallVideoSMSRole}
            onChangeTriggered={() =>
              setStage(PhoneNumberVerificationStage.CHANGE)
            }
          />
        )}
      </Dialog>
    </>
  );
};
