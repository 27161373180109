import { useEffect, useMemo } from 'react';
import { keyBy } from 'lodash';
import { useFormContext, useWatch } from 'react-hook-form';

import type { SelectOptionsTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { ControlledSelectUnitHousehold } from './ControlledSelectUnitHousehold';
import DisplayName from './DisplayName';
import SelectBuildingFloorUnit from './SelectBuildingFloorUnit';
import { SelectCareLevel } from './SelectCareLevel';
import SelectRoomDesignation from './SelectRoomDesignation';
import SelectRoomFallRisk from './SelectRoomFallRisk';

import { useHouseholdOptions } from '../../../../../hooks/useHouseholdOptions';
import FormCategory from '../../../modals/FormCategory';

import type { FieldTypes } from '../../../types/DataFields';
import type { RoomFieldTypes } from '../types/RoomFieldTypes';

type Props<F extends FieldTypes> = {
  isAdmin: boolean;
  orgId?: string;
  type: 'add' | 'edit';
  defaultComponents: Record<keyof F, JSX.Element | null>;
  unitsMap: Record<string, SelectOptionsTypes.UnitOption>;
  disableUnit?: boolean;
  disableHousehold?: boolean;
};

export const RoomForm = <F extends FieldTypes>({
  isAdmin,
  orgId,
  type,
  defaultComponents,
  unitsMap,
  disableUnit,
  disableHousehold,
}: Props<F>) => {
  const { control, setValue, getValues } = useFormContext<RoomFieldTypes>();
  const unitId = useWatch({ control, name: 'unitId' });
  const householdId = useWatch({ control, name: 'householdId' });

  const { data: households, isLoading } = useHouseholdOptions({
    unitId,
    enabled: !!unitId,
  });

  const householdsMap = useMemo(() => keyBy(households, 'id'), [households]);

  useEffect(() => {
    if (!unitId) {
      setValue('householdId', '');
    }
  }, [unitId]);

  useEffect(() => {
    const { displayName } = getValues();

    if (householdId && !displayName) {
      setValue('displayName', householdsMap[householdId]?.displayName);
    }
  }, [householdId, householdsMap]);

  return (
    <>
      <SelectBuildingFloorUnit org={orgId} type={type} disable={disableUnit} />
      <ControlledSelectUnitHousehold
        householdsMap={householdsMap}
        isLoading={isLoading}
        disabled={!unitId || disableHousehold}
        orgId={orgId}
      />
      <DisplayName field='displayName' label='Display Name' />
      {/* TODO: Use switch UI for fall risk similar to room modal */}
      <SelectRoomFallRisk orgId={orgId} />
      <SelectCareLevel unitsMap={unitsMap} />
      <SelectRoomDesignation />
      {defaultComponents.disable}
      {defaultComponents.hide}
      {defaultComponents.deleted}
      {isAdmin && (
        <FormCategory defaultExpanded={false} title='Integrations' isAccordion>
          {defaultComponents.pccRoomId}
          {defaultComponents.pccBedId}
          {defaultComponents.yardiRoomId}
          {defaultComponents.yardiBedId}
          {defaultComponents.alisRoomId}
          {defaultComponents.alisBedId}
        </FormCategory>
      )}
    </>
  );
};
