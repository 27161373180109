import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Toolbar,
} from '@mui/material';
import { Close, ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
import * as Sentry from '@sentry/react';

import { useBrowserActivityTracker } from '@inspiren-monorepo/activity-tracker-browser';
import {
  ActiveAlertPlaybackMenu,
  useActiveAlertPlayback,
} from '@inspiren-monorepo/shared-react/universal-components';
import { type AlertType, UserEventType } from '@inspiren-monorepo/shared-types';
import { formatAlertType } from '@inspiren-monorepo/util-formatters';
import { DomainId } from '@inspiren-monorepo/util-rooms';
import type { TelesittingTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import RoomBottomBar from './RoomCard/RoomBottomBar';
import RoomImage from './RoomImage';
import { useRoomImageSize } from './RoomImage/hooks/useRoomImageSize';
import { HEADER_HEIGHT } from './RoomModal/constants';
import RoomTopBar from './RoomTopBar';
import { ZoneUpdateWrapper } from './ZoneUpdate/ZoneUpdateWrapper';
import { ArrowIconButton } from './components/ArrowIconButton';
import { getRoomImages } from './data-access/getRoomLiveImages';
import { useUnitForRoom } from './hooks/useUnitForRoom';

import { useCurrentUser } from '../../HOC/CurrentUserContextProvider';
import { getAlertAnimationSxProps } from '../../styles/alertAnimation';
import { useTrackUserEvent } from '../../utility/analytics';
import { hasAccess } from '../Can/hasAccess';

const commonArrowWrapperSx = {
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
};

interface Props {
  imgSrc?: string;
  room: TelesittingTypes.PeriscopeRoom;
  onCloseClick: () => void;
  setTooltip: (state: boolean) => void;
  onPrivacyModeChange: () => void;
  resolve: () => void;
  resolveNotifLoading: boolean;
  disabled?: boolean;
  imageLoading?: boolean;
  offline: boolean;
  alert: AlertType | null;
  privacy: boolean;
  playingAudibleMessage: boolean;
  onNextClick?: () => void;
  onPrevClick?: () => void;
}

const RoomModal = ({
  imgSrc: liveImageSrc,
  room,
  onCloseClick,
  setTooltip,
  onPrivacyModeChange,
  resolve,
  disabled,
  imageLoading = false,
  offline = false,
  privacy = false,
  alert,
  playingAudibleMessage,
  resolveNotifLoading,
  onNextClick,
  onPrevClick,
}: Props) => {
  const { trackEvent } = useTrackUserEvent();
  const { user } = useCurrentUser();
  const unit = useUnitForRoom(room.domainId);
  const activityTracker = useBrowserActivityTracker();

  const [showZoneUpdate, setShowZoneUpdate] = useState(false);

  const bottomMenuRef = useRef<HTMLElement>(null);
  const bottomMenuHeight = bottomMenuRef.current?.clientHeight;
  const { width, height } = useRoomImageSize(bottomMenuHeight);

  const showMenu = useMemo(
    () =>
      hasAccess(user, { action: 'view', subject: 'global.disable-augi' }) ||
      hasAccess(user, { action: 'view', subject: 'global.fall-risk-off' }) ||
      hasAccess(user, { action: 'view', subject: 'global.fall-risk-high-low' }),
    [user],
  );

  const alertAnimation = useMemo(
    () => (alert && getAlertAnimationSxProps(alert, 'backgroundColor')) || {},
    [alert],
  );

  const onToggleZoneUpdate = useCallback(() => {
    setShowZoneUpdate((szu) => !szu);
  }, [setShowZoneUpdate]);

  const closeModal = useCallback(() => {
    onCloseClick();

    trackEvent({
      type: alert
        ? UserEventType.AlertLiveViewClosed
        : UserEventType.ExpandedLiveViewClosed,
      room: room.domainId,
    });
  }, [onCloseClick, trackEvent, room, showZoneUpdate, alert]);

  const onDialogClose = useCallback(
    (_evt: object, reason: string) => {
      if (reason === 'backdropClick' && showZoneUpdate) {
        return;
      }

      closeModal();
    },
    [onCloseClick, trackEvent, room, showZoneUpdate],
  );

  const {
    isActive: activeAlertPlaybackActive,
    imageUrl,
    imageTimestamp,
    imagesLoading,
    noImagesAvailable,
  } = useActiveAlertPlayback({
    roomImagesQueryKey: [
      'roomImages',
      'activeAlertPlayback',
      room.domainId,
      room.notificationId,
    ],
    roomImagesQueryFn: getRoomImages,
    roomId: room.domainId,
    notifTime:
      alert && room.notificationId
        ? new Date(room.notificationId * 1000)
        : undefined,
  });

  useEffect(() => {
    if (imageUrl || liveImageSrc) {
      activityTracker.keepActive();
    }
  }, [imageUrl, liveImageSrc]);

  return (
    <Dialog open onClose={onDialogClose} maxWidth='lg'>
      {!showZoneUpdate && (
        <>
          <Stack
            direction='row'
            width='100%'
            alignItems='center'
            sx={alertAnimation}
          >
            <DialogTitle sx={{ height: HEADER_HEIGHT }}>
              {alert
                ? `${formatAlertType(alert, unit?.bathroomAlertThreshold)} alert`
                : ''}
            </DialogTitle>

            <IconButton
              aria-label='close'
              onClick={closeModal}
              sx={{ ml: 'auto', mr: 1, height: 'fit-content' }}
            >
              <Close />
            </IconButton>
          </Stack>

          <DialogContent sx={{ overflow: 'hidden', p: 0, display: 'flex' }}>
            <Stack direction='column'>
              <Box position='relative'>
                <RoomTopBar
                  room={room}
                  playingAudibleMessage={playingAudibleMessage}
                  disabled={disabled}
                  offline={offline}
                  privacy={privacy}
                  onPrivacyModeChange={onPrivacyModeChange}
                  onToggleZoneUpdate={onToggleZoneUpdate}
                  size='large'
                  sx={{ pt: 1.5, pl: 2, pr: 0.5 }}
                  showMenu={showMenu}
                />
                <RoomBottomBar
                  room={room}
                  size='large'
                  resolve={resolve}
                  resolveNotifLoading={resolveNotifLoading}
                  setTooltip={setTooltip}
                  alert={alert}
                  sx={{ pb: 1.5, px: 2 }}
                  offline={offline}
                  disabled={disabled}
                  privacy={privacy}
                  showFallRisk
                  showActiveAlertPlayback
                  activeAlertPlaybackActive={activeAlertPlaybackActive}
                  fallRiskPillTestID='expandedroom-fallriskpill'
                  timestamp={imageTimestamp}
                  showTimestamp={
                    imageTimestamp &&
                    !imagesLoading &&
                    activeAlertPlaybackActive
                  }
                />
                <RoomImage
                  width={width}
                  height={height}
                  src={activeAlertPlaybackActive ? imageUrl : liveImageSrc}
                  enableZoom
                  disabled={disabled}
                  privacy={privacy}
                  privEndsAt={room.virtualCurtainAt}
                  loading={
                    activeAlertPlaybackActive ? imagesLoading : imageLoading
                  }
                  offline={offline}
                  testID='expandedroom-image'
                  keepAlive={room.keepAliveAt}
                  noImagesAvailable={
                    noImagesAvailable && activeAlertPlaybackActive
                  }
                />

                {onPrevClick && (
                  <Toolbar sx={{ ...commonArrowWrapperSx, left: 0 }}>
                    <ArrowIconButton
                      onClick={onPrevClick}
                      icon={<ArrowBackIosNew fontSize='large' />}
                    />
                  </Toolbar>
                )}
                {onNextClick && (
                  <Toolbar sx={{ ...commonArrowWrapperSx, right: 0 }}>
                    <ArrowIconButton
                      onClick={onNextClick}
                      icon={<ArrowForwardIos fontSize='large' />}
                    />
                  </Toolbar>
                )}
              </Box>

              <Box ref={bottomMenuRef}>
                <ActiveAlertPlaybackMenu />
              </Box>
            </Stack>
          </DialogContent>
        </>
      )}

      {showZoneUpdate && (
        <ZoneUpdateWrapper
          imagesIsLoading={imageLoading}
          lastSuccessfulImage={liveImageSrc}
          roomId={room.domainId}
          roomName={
            room.displayName || DomainId.parse(room.domainId).room || ''
          }
          toggleZoneUpdate={onToggleZoneUpdate}
          width={width}
          height={height}
        />
      )}
    </Dialog>
  );
};

export default Sentry.withProfiler(RoomModal);
