import {
  CheckCircle as EnableIcon,
  Unpublished as DisableIcon,
  // ThumbUp as ConsentIcon,
  // ThumbDown as RevokeConsentIcon,
  // RequestQuote as ChangeBillingCodeIcon,
} from '@mui/icons-material';

import { RoomBulkUpdateAction } from '@inspiren-monorepo/shared-types';
import type { AdminTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import type { SectionedBulkUpdateActions } from '../../../components/BulkUpdate/types/BulkUpdateFields';

export const roomBulkUpdateActionsDefinition: SectionedBulkUpdateActions<AdminTypes.RoomBulkUpdatePayload> =
  [
    [
      {
        action: RoomBulkUpdateAction.EnableAugi,
        displayName: 'Enable AUGi',
        description: 'enable AUGi',
        icon: <EnableIcon />,
      },
      {
        action: RoomBulkUpdateAction.DisableAugi,
        displayName: 'Disable AUGi',
        description: 'disable AUGi',
        icon: <DisableIcon />,
      },
    ],
    // [
    //   {
    //     action: RoomBulkUpdateAction.Consent,
    //     displayName: 'Consent',
    //     description: 'grant consent',
    //     icon: <ConsentIcon />,
    //   },
    //   {
    //     action: RoomBulkUpdateAction.RevokeConsent,
    //     displayName: 'Revoke Consent',
    //     description: 'revoke consent',
    //     icon: <RevokeConsentIcon />,
    //   },
    // ],
    // [
    //   {
    //     action: RoomBulkUpdateAction.UpdateBillingCode,
    //     displayName: 'Update Billing Code',
    //     description: 'update the billing code to TODO',
    //     icon: <ChangeBillingCodeIcon />,
    //     followUpFields: {
    //       billingCode: 'string',
    //     },
    //     renderFollowUpModal: ({ control }) => (
    //       <ControlledAutocomplete
    //         id='billingCode'
    //         label='Billing code'
    //         control={control}
    //         items={[]}
    //       />
    //     ),
    //   },
    // ],
  ];
