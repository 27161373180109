import { useMemo } from 'react';
import type { SxProps, Theme } from '@mui/material';
import { Toolbar } from '@mui/material';
import { isNil, noop } from 'lodash';

import { DomainId } from '@inspiren-monorepo/util-rooms';
import type { TelesittingTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { RoomAudibleMessagesModalButton } from './RoomAudibleMessagesModalButton';
import RoomMenu from './RoomCard/RoomMenu';
import { AudibleMessageIndicator } from './components/AudibleMessageIndicator';
import { useUnitForRoom } from './hooks/useUnitForRoom';

import { useOrgSettings } from '../../hooks/useOrgSettings';
import FallRiskPill from '../shared/FallRiskPill';

type Props = {
  room: TelesittingTypes.PeriscopeRoom;
  playingAudibleMessage: boolean;
  onPrivacyModeChange: () => void;
  onToggleZoneUpdate?: () => void;
  disabled?: boolean;
  privacy: boolean;
  offline?: boolean;
  size?: 'small' | 'large';
  sx?: SxProps<Theme>;
  showMenu?: boolean;
  hideAudibleMessageButton?: boolean;
};

const RoomTopBar = ({
  room,
  onPrivacyModeChange,
  onToggleZoneUpdate,
  disabled,
  privacy,
  offline,
  size = 'small',
  sx = {},
  playingAudibleMessage,
  showMenu = false,
  hideAudibleMessageButton = false,
}: Props) => {
  const { displayName, fallRiskLevel = 'off' } = room;

  const org = room.orgId;
  const { data: orgSettingsData } = useOrgSettings(org);

  const unit = useUnitForRoom(room.domainId);

  const isAudibleMessageButtonHidden = useMemo(
    () =>
      !unit?.audibleMessages ||
      !room?.audible ||
      disabled ||
      privacy ||
      offline ||
      hideAudibleMessageButton,
    [unit, room, disabled, privacy, offline, hideAudibleMessageButton],
  );

  return (
    <Toolbar
      disableGutters
      sx={{
        position: 'absolute',
        zIndex: 100,
        opacity: 0.8,
        '&.MuiToolbar-root': {
          minHeight: 0,
        },
        gap: 1,
        ...sx,
      }}
    >
      <FallRiskPill
        label={`Room ${displayName}`}
        fallRisk={fallRiskLevel}
        size={size === 'large' ? 'large' : 'medium'}
        bold
      />

      {showMenu && (
        <RoomMenu
          roomId={room.id}
          roomDomainId={room.domainId}
          baseId={room.baseId!}
          roomName={displayName}
          fallRiskLevel={fallRiskLevel}
          onPrivacyModeChange={onPrivacyModeChange}
          onToggleZoneUpdate={onToggleZoneUpdate || noop}
          offline={Boolean(room.offline)}
          privacy={privacy}
          augiDisabled={room.disable || false}
          unitId={DomainId.toUnitId(room.domainId, true)}
          isDemoSession={room.isDemoSession}
          isDemoAction={room.isDemoAction}
          bathroomSensorEnabled={
            (orgSettingsData?.settings?.bathroomSensorEnabled &&
              !isNil(room.bathroomOccupied)) ||
            false
          }
          bathroomAlertsEnabled={!!room.bathroomAlertsEnabled}
          keepAlive={room.keepAliveAt!}
        />
      )}

      {!isAudibleMessageButtonHidden && (
        <RoomAudibleMessagesModalButton
          roomId={room.domainId}
          roomName={room.displayName}
          baseId={room.baseId}
        />
      )}
      {playingAudibleMessage && <AudibleMessageIndicator size={size} />}
    </Toolbar>
  );
};

export default RoomTopBar;
