import { isNil } from 'lodash';
import * as yup from 'yup';

type Params = {
  nullable?: boolean;
};

export const stringId = ({ nullable }: Params = {}) => {
  const schema = yup
    .string()
    .matches(
      /^[\w-]+$/,
      'Field can only include letters, numbers, underscores, and hyphens without spaces',
    );

  if (nullable) {
    return schema
      .nullable()
      .transform((_, value) => (value === '' || isNil(value) ? null : value));
  }

  return schema.required('Field is required.');
};
