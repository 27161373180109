import { NoopLogger } from './NoopLogger';

import type { ErrorFn } from '../types/ILogger';

export class ConsoleLogger extends NoopLogger {
  override error: ErrorFn = (...args) => {
    console.error(...args);
  };

  override warn(...args: any[]) {
    console.warn(...args);
  }

  override info(...args: any[]) {
    console.info(...args);
  }

  override trace(...args: any[]) {
    console.trace(...args);
  }

  override debug(...args: any[]) {
    console.debug(...args);
  }

  override time(label?: string) {
    console.time(label);
  }

  override timeLog(label?: string, ...args: any[]) {
    console.timeLog(label, ...args);
  }

  override timeEnd(label?: string) {
    console.timeEnd(label);
  }
}
