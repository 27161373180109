import { sortBy } from 'lodash';
import { useFormContext } from 'react-hook-form';

import { useFloorOptions } from '../../../../hooks/useFloorOptions';
import ControlledDropdown from '../ControlledDropdown';


type Props = {
  type: 'add' | 'edit';
};

const SelectOrgFloor = ({ type }: Props) => {
  const orgId = import.meta.env.VITE_ORG_ID;
  const { control } = useFormContext();
  const { isLoading, data: floors } = useFloorOptions();

  return (
    <ControlledDropdown
        id='floorId'
        label='Building-Floor'
        items={sortBy(floors, (floor) =>
          floor.domainId.replace(`${orgId}-`, '').toLowerCase(),
        ).map((floor) => ({
          value: floor.id,
          label: floor.domainId.replace(`${orgId}-`, ''),
        }))}
        control={control}
        disabled={isLoading || type === 'edit'}
      />
  );
};

export default SelectOrgFloor;
