import { FormControlLabel, Switch } from '@mui/material';
import { type Control, Controller, useWatch } from 'react-hook-form';

import type { ChangePhoneNumberForm } from '@inspiren-monorepo/shared-react/universal';

interface Props {
  control: Control<ChangePhoneNumberForm>;
}

export const ControlledSmsWithVideoLinkSwitch = ({ control }: Props) => {
  const mobilePhoneNumber = useWatch({ control, name: 'mobilePhoneNumber' });

  return (
    <Controller
      control={control}
      name='smsWithVideoLink'
      render={({ field: { onChange, value }, formState: { isSubmitting } }) => (
        <FormControlLabel
          control={
            <Switch
              disabled={!mobilePhoneNumber || isSubmitting}
              checked={!!value}
              onChange={onChange}
            />
          }
          label='Receive video link with text message'
        />
      )}
    />
  );
};
