import { addMinutes, addSeconds, subMinutes } from 'date-fns';

import { DomainId } from '@inspiren-monorepo/util-rooms';

import { generateEventReviewUrl } from './generateEventReviewUrl';

interface Params {
  roomId: string;
  roomName: string;
  offline: boolean;
  lastKeepAliveTime?: Date;
  lastOfflineEventTime?: Date;
}

export const generateLastImagesEventReviewUrl = ({
  roomId,
  roomName,
  offline,
  lastKeepAliveTime,
  lastOfflineEventTime,
}: Params) => {
  let start: Date;
  let end: Date;

  if (offline && lastKeepAliveTime && lastOfflineEventTime) {
    end = addSeconds(lastOfflineEventTime, 15);
    start = subMinutes(lastKeepAliveTime, 1);
  } else if (offline && (lastKeepAliveTime || lastOfflineEventTime)) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- we've already checked that one of them is defined
    const time = (lastKeepAliveTime || lastOfflineEventTime)!;
    end = addMinutes(time, 1);
    start = subMinutes(time, 1);
  } else {
    end = new Date();
    start = subMinutes(end, 1);
  }

  const org = DomainId.toOrgId(roomId);
  const unit = DomainId.toUnitId(roomId);

  return generateEventReviewUrl({
    org,
    unit,
    room: roomName,
    start,
    end,
  });
};
