import { useCallback } from 'react';
import { Link } from '@mui/material';
import { isEmpty, isNil } from 'lodash';

import { getPositionFromDate } from '@inspiren-monorepo/shared-react/browser';

import { useEventReviewStoreShallow } from '../../store/EventReviewStore';
import DisabledTimeLink from '../DisabledTimeLink';

interface Props {
  disabled: boolean;
  value: Date;
  formattedValue: string;
}

export const TimeLink = ({ disabled, value, formattedValue }: Props) => {
  const { images, setPosition } = useEventReviewStoreShallow([
    'images',
    'setPosition',
  ]);

  const setPositionFromDate = useCallback(
    (date: Date) => {
      if (isNil(images)) return;
      const position = getPositionFromDate(images, date) || 0;
      setPosition(position);
    },
    [images, setPosition],
  );

  return disabled ? (
    <DisabledTimeLink
      tooltipText={isEmpty(images) ? '' : 'Outside of selected range'}
    >
      {formattedValue}
    </DisabledTimeLink>
  ) : (
    <Link component='button' onClick={() => setPositionFromDate(value)}>
      {formattedValue}
    </Link>
  );
};
