import { CopyButton } from './CopyButton';

type Props = Parameters<typeof CopyButton>[0];

export const CopyTextButton = ({ valueToCopy, ...rest }: Props) => (
  <>
    <CopyButton valueToCopy={valueToCopy} {...rest} />
    <span>
      <i>{valueToCopy}</i>
    </span>
  </>
);
