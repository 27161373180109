import { useCallback, useMemo, useState } from 'react';
import { FormControlLabel, Switch } from '@mui/material';
import { isAxiosError } from 'axios';
import { isEmpty, keyBy } from 'lodash';
import toast from 'react-hot-toast';

import { getAxiosErrorMessage } from '@inspiren-monorepo/util-axios';
import {
  getUsernameFromEmail,
  isUsernameOrg,
} from '@inspiren-monorepo/util-users';

import { ChangeUserActivationState } from './components/ChangeUserActivationState';
import { UserForm } from './components/UserForm';
import { getUsersTableFields } from './helpers/getUsersTableFields';
import useUsersUpsert from './hooks/useUsersUpsert';

import { useBuildingOptions } from '../../../../hooks/useBuildingOptions';
import { useIsAdmin } from '../../../../hooks/useIsAdmin';
import { useOrganizationRolesMap } from '../../../../hooks/useOrganizationRolesMap';
import { useUnitOptions } from '../../../../hooks/useUnitOptions';
import Loading from '../../../../screens/Loading';
import BulkImportLink from '../../components/BulkImportLink';
import { TableBase } from '../../components/TableBase';
import { AdminTableWrapper } from '../../components/TableBase/AdminTableWrapper';
import { useAdminOrg } from '../../hooks/useAdminOrg';
import { useAdminUsers } from '../../hooks/useAdminUsers';

import type { UserFields } from './helpers/getUsersTableFields';
import type {
  OnSubmitFormModal,
  RenderFormModal,
} from '../../modals/FormModalBase';

const UsersTable = () => {
  const orgId = import.meta.env.VITE_ORG_ID;
  const { isAdmin, isAdminLoading } = useIsAdmin();
  const [staffView, setStaffView] = useState(!isAdmin);

  const { data: currentOrg } = useAdminOrg({ orgId });

  const { users, isFetching } = useAdminUsers();

  const { data: roleMap = {} } = useOrganizationRolesMap(orgId);

  const {
    data: unitOptions,
    isLoading: unitsLoading,
    isError: unitsError,
  } = useUnitOptions();

  const unitsMap = useMemo(() => keyBy(unitOptions || [], 'id'), [unitOptions]);

  const {
    isLoading: buildingsLoading,
    isError: buildingsError,
    data: buildingOptions = [],
  } = useBuildingOptions();

  const buildingsMap = useMemo(
    () => keyBy(buildingOptions || [], 'id'),
    [buildingOptions],
  );

  const { handleAddSubmit, handleEditSubmit } = useUsersUpsert({
    users,
    roleMap,
    unitsMap,
  });

  const { auth0EnterpriseConnections, textMessageAlerts } = useMemo(
    () => ({
      auth0EnterpriseConnections:
        currentOrg?.auth0EnterpriseConnections?.filter((r) => !isEmpty(r)) ||
        [],
      auth0DbConnection: currentOrg?.auth0DbConnection,
      textMessageAlerts: currentOrg?.textMessageAlerts,
    }),
    [currentOrg, orgId],
  );

  const modalLoading = useMemo(
    () => unitsLoading || buildingsLoading,
    [unitsLoading, buildingsLoading],
  );

  const modalError = useMemo(
    () => unitsError || buildingsError,
    [unitsError, buildingsError],
  );

  const renderModal: RenderFormModal<UserFields> = useCallback(
    ({ defaultComponents, fields, type, initialValues }) => (
      <UserForm
        isAdmin={isAdmin}
        defaultComponents={defaultComponents}
        fields={fields}
        type={type}
        showPassword={
          type === 'edit' &&
          !auth0EnterpriseConnections.includes(
            initialValues?.auth0Connection || '',
          )
        }
        unitOptions={unitOptions || []}
        buildingOptions={buildingOptions || []}
      />
    ),
    [orgId, isAdmin, auth0EnterpriseConnections, unitOptions, buildingOptions],
  );

  const fields = useMemo(
    () =>
      getUsersTableFields(
        isAdmin,
        roleMap,
        buildingsMap,
        unitsMap,
        orgId,
        textMessageAlerts,
        staffView,
      ),
    [
      isAdmin,
      orgId,
      roleMap,
      textMessageAlerts,
      staffView,
      unitsMap,
      buildingsMap,
    ],
  );

  const usersData = useMemo(
    () =>
      users
        // TODO: when moving to server side staff view should be filtered on the server
        .filter((user) => !staffView || user.showInStaffView)
        .map((user) => {
          const usernameFromEmail = isUsernameOrg(orgId)
            ? getUsernameFromEmail(user.email, orgId)
            : null;

          return {
            id: user.domainId,
            userId: user.id,
            firstName: user.firstName || '',
            lastName: user.lastName || '',
            role: user.roleId || '',
            unitId: user.unitId || null,
            email: (!usernameFromEmail && user.email) || '',
            username: user.username || usernameFromEmail || '',
            password: '',
            levelAccess: user.levelAccess || null,
            levelAccessBuildingIds: user.levelAccessBuildingIds || [],
            beacon: user.beacon || '',
            mobilePhone: user.mobilePhone,
            mobilePhoneVerified: user.mobilePhoneVerified,
            auth0Connection: user.auth0Connection,
            lastActivityAt: user.lastActivityAt,
            deactivated: Boolean(user.deletedAt),
          };
        }),
    [users, staffView],
  );

  const onEditSubmit: OnSubmitFormModal<UserFields> = async (params) => {
    try {
      await handleEditSubmit(params);
      toast.success('Successfully edited the user');
    } catch (error) {
      const errMsg = getAxiosErrorMessage(error) || 'failed to edit user';
      toast.error(`Error: ${errMsg}`);
    }
  };

  const onAddSubmit: OnSubmitFormModal<UserFields> = async (params) => {
    try {
      await handleAddSubmit(params);
      toast.success('Successfully added the user');
    } catch (error) {
      if (isAxiosError(error) && error.response?.status === 409) {
        toast.error('Error: user already exists');
      } else {
        const errMsg = getAxiosErrorMessage(error) || 'failed to add new user';
        toast.error(`Error: ${errMsg}`);
      }
    }
  };

  // The table defaults won't work if the fields change after the table is mounted
  // So we delay mounting until we know if the user is an admin
  if (isAdminLoading) return <Loading />;

  return (
    <AdminTableWrapper>
      <TableBase<UserFields>
        key={`users-table-${staffView ? 'staff' : 'all'}`}
        itemName='User'
        fields={fields}
        data={usersData || []}
        loading={isFetching}
        modalLoading={modalLoading}
        modalError={modalError}
        error={false}
        onEditSubmit={onEditSubmit}
        onAddSubmit={onAddSubmit}
        defaultSort={staffView ? 'status' : undefined}
        defaultSortDirection={staffView ? 'desc' : undefined}
        extraActionButtons={isAdmin && <BulkImportLink itemName='users' />}
        extraTableOptions={
          // TODO: Use DropdownSingleSelect component instead, similiar to the RoomsTable
          // This change probably requires customer comms
          <FormControlLabel
            id='staff-view-switch'
            label='Enable Staff Status View'
            checked={staffView}
            onChange={(_e, checked) => setStaffView(checked)}
            slotProps={{ typography: { variant: 'body2' } }}
            control={<Switch />}
          />
        }
        disableAddButton={!orgId}
        renderModal={renderModal}
        defaultPinnedColumns={['firstName', 'lastName']}
        defaultFilterModel={{
          items: isAdmin
            ? [
                {
                  field: 'deactivated',
                  operator: 'is',
                  value: 'Active',
                },
              ]
            : [],
        }}
        renderBottomLeftEditModalActions={(closeModal) =>
          isAdmin && (
            <ChangeUserActivationState orgId={orgId} onSuccess={closeModal} />
          )
        }
        shouldDisableEditForm={(userData) => Boolean(userData.deactivated)}
      />
    </AdminTableWrapper>
  );
};

export default UsersTable;
