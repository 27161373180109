import type React from 'react';
import { cloneElement, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { sortBy } from 'lodash';
import {
  bindDialog,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import toast from 'react-hot-toast';

import { getAxiosErrorMessage } from '@inspiren-monorepo/util-axios';
import { DomainId } from '@inspiren-monorepo/util-rooms';
import type {
  AdminTypes,
  SelectOptionsTypes,
} from '@inspiren-monorepo/virtual-care/api-contracts';

import { useBuildingOptions } from '../../../../../hooks/useBuildingOptions';
import LoadingAutocomplete from '../../../../shared/LoadingAutocomplete';
import { assignAlisCommunity } from '../data-access/assignAlisCommunity';

type Props = {
  community: AdminTypes.AlisCommunity;
  children: React.ReactElement;
};

function getBuildingName(building: SelectOptionsTypes.BuildingOption) {
  return building.displayName || DomainId.parse(building.domainId).building;
}

export const AssignCommunityModal = ({ community, children }: Props) => {
  const queryClient = useQueryClient();

  const [selectedBuilding, setSelectedBuilding] =
    useState<SelectOptionsTypes.BuildingOption | null>(null);

  const popupState = usePopupState({
    variant: 'dialog',
    popupId: 'connect-to-building-modal',
  });

  const { data: buildings, isLoading } = useBuildingOptions();

  const { mutate, isPending } = useMutation<
    void,
    unknown,
    {
      communityId: number;
      buildingId: string;
    }
  >({
    mutationFn: ({ buildingId, communityId }) =>
      assignAlisCommunity(communityId, buildingId),
    onSuccess: async () => {
      toast.success('Alis Community assigned successfully');
      popupState.close();

      await queryClient.invalidateQueries({
        queryKey: ['alis-community-structure'],
      });
    },
    onError: (error: any) => {
      const message =
        getAxiosErrorMessage(error) ??
        `Error assigning community to building${error ? `: ${error}` : ''}`;

      toast.error(message);
    },
  });

  return (
    <>
      {cloneElement(children, { ...bindTrigger(popupState) })}
      {popupState.isOpen && (
        <Dialog fullWidth maxWidth='xs' {...bindDialog(popupState)}>
          <DialogTitle>
            Connect to existing building
            <IconButton
              aria-label='close'
              onClick={popupState.close}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box sx={{ py: 2 }}>
              <LoadingAutocomplete
                label='Building'
                loading={isLoading}
                size='small'
                id='building'
                data-testid='building'
                options={sortBy(buildings, (building) =>
                  getBuildingName(building),
                )}
                value={selectedBuilding}
                getOptionLabel={(building) =>
                  getBuildingName(building) || 'error'
                }
                isOptionEqualToValue={(o, v) => o.id === v.id}
                onChange={(_e, newValue) => setSelectedBuilding(newValue)}
                sx={{ width: '100%' }}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              variant='outlined'
              sx={{ fontSize: 14, width: '140px' }}
              onClick={popupState.close}
            >
              Cancel
            </Button>
            <LoadingButton
              variant='contained'
              sx={{ fontSize: 14, width: '140px' }}
              onClick={() =>
                mutate({
                  communityId: community.communityId,
                  buildingId: selectedBuilding?.id as string,
                })
              }
              disabled={!selectedBuilding}
              loading={isPending}
            >
              Confirm
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
