import type { AdminTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { Api } from '../../../../../utility/client';

export const postCareLevel = async (careLevel: AdminTypes.NewCareLevel) => {
  const res = await Api.post<AdminTypes.CareLevel>(
    '/admin/v1/care-levels',
    careLevel,
  );

  return res.data;
};
