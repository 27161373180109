import { isEmpty } from 'lodash';

import { useEventReviewStoreShallow } from '../store/EventReviewStore';

export const useTimestamp = () => {
  const { images, position } = useEventReviewStoreShallow([
    'images',
    'position',
  ]);

  if (!images || !images[position] || isEmpty(images[position]?.time)) {
    return null;
  }

  return new Date(images[position].time);
};
