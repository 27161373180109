import type { EventReviewTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { Api } from '../../../utility/client';

export const getOrg = async (orgId: string) => {
  const res = await Api.get<EventReviewTypes.Organization>(
    `/eventReview/v1/orgs/${orgId}`,
  );

  return res.data;
};
