import type { RoomActivityType } from '../DetailsPanel/RoomActivity/types/RoomActivityType';

export const roomActivityTypeMap: Record<RoomActivityType, string> = {
  PrivacyModeOn: 'Virtual Curtain Enabled',
  AugiDisabled: 'AUGi Disabled',
  AugiEnabled: 'AUGi Enabled',
  LiveViewOpened: 'Live View',
  ExpandedLiveViewOpened: 'Expanded Live View',
  AlertLiveViewOpened: 'Alert Live View',
  StaffEvent: 'Staff Visit',
  StateChange: 'State Change',
};

export const formatRoomActivityType = (type: RoomActivityType): string =>
  roomActivityTypeMap[type] || 'Unknown Event';
