import type { DOMAttributes } from 'react';
import { forwardRef, useMemo } from 'react';
import type { TextFieldProps } from '@mui/material';
import { Autocomplete, TextField } from '@mui/material';
import { keyBy, orderBy } from 'lodash';

import { DomainId } from '@inspiren-monorepo/util-rooms';
import type { SelectOptionsTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

interface Props
  extends Pick<
    DOMAttributes<HTMLDivElement>,
    | 'onBlur'
    | 'onFocus'
    | 'onMouseLeave'
    | 'onMouseOver'
    | 'onTouchEnd'
    | 'onTouchStart'
  > {
  id?: string;
  value?: string[];
  onChange: (value: string[]) => void;
  error?: unknown;
  disabled?: boolean;
  label?: string;
  options?: SelectOptionsTypes.BuildingOption[];

  inputSx?: TextFieldProps['sx'];
}

const SelectLevelAccessBuildings = forwardRef((props: Props, ref) => {
  const {
    value,
    onChange,
    id,
    error,
    disabled,
    label,
    options,
    inputSx,
    onBlur,
    onFocus,
    onMouseLeave,
    onMouseOver,
    onTouchEnd,
    onTouchStart,
  } = props;

  const buildingOptions = useMemo(() => options || [], [options]);

  const buildings = useMemo(
    () =>
      orderBy(
        buildingOptions,

        (item) => item?.displayName?.toLowerCase(),
        ['asc'],
      ),
    [buildingOptions],
  );

  const mappedBuildings = useMemo(() => keyBy(buildings, 'id'), [buildings]);

  return (
    <Autocomplete
      ref={ref}
      id={id}
      disabled={disabled}
      data-testid='building'
      options={Object.keys(mappedBuildings)}
      getOptionLabel={(option) => {
        const building = mappedBuildings[option];
        return (
          building?.displayName ||
          DomainId.parse(building?.domainId).building ||
          'error'
        );
      }}
      multiple
      value={value}
      isOptionEqualToValue={(o, v) => o === v}
      onChange={(a, b) => onChange(b)}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          margin='dense'
          error={Boolean(error)}
          sx={inputSx}
        />
      )}
      fullWidth
      onBlur={onBlur}
      onFocus={onFocus}
      onMouseLeave={onMouseLeave}
      onMouseOver={onMouseOver}
      onTouchEnd={onTouchEnd}
      onTouchStart={onTouchStart}
    />
  );
});

export default SelectLevelAccessBuildings;
