import { useCallback } from 'react';
import { groupBy, mapValues } from 'lodash';

import type { RoomFieldTypes } from '../types/RoomFieldTypes';

export const useRenderBulkUpdateRows = () =>
  useCallback((rows: RoomFieldTypes[]) => {
    const groupedRooms = mapValues(
      groupBy(rows, 'buildingDisplayName'),
      (buildingRooms) => groupBy(buildingRooms, 'unitDisplayName'),
    );

    return (
      <ul>
        {Object.entries(groupedRooms).map(([building, units]) => (
          <li key={building}>
            {building}
            <ul>
              {Object.entries(units).map(([unit, rooms]) => (
                <li key={unit}>
                  {unit}
                  <ul>
                    {rooms.map((room) => (
                      <li key={room.id}>{room.displayName}</li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    );
  }, []);
