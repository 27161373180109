import type { PropsWithChildren } from 'react';

import { useInitBrowserActivityTracker } from '@inspiren-monorepo/activity-tracker-browser';

import { useCurrentUser } from '../../HOC/CurrentUserContextProvider';
import { getApiBaseUrl } from '../../config/apiUrlConfig';

const API_URL = getApiBaseUrl();
const IS_LOCAL = import.meta.env.VITE_IS_LOCAL;

export const BrowserActivityTrackerProvider = (props: PropsWithChildren) => {
  const { user } = useCurrentUser();

  useInitBrowserActivityTracker({
    app: 'virtual-care',
    userId: user?.id,
    activityBaseUrl: API_URL,
    isLocal: IS_LOCAL === 'true',
  });

  return props.children;
};
