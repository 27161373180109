import { Api } from '../../../../../utility/client';

export async function unassignAlisBed(
  unitId: number,
  bedId: string,
  roomId: string,
) {
  await Api.patch(`/admin/v1/alis/beds/${`${unitId}-${bedId}`}/unassign`, {
    roomId,
  });
}
