import { useMemo } from 'react';
import { keyBy } from 'lodash';
import { useWatch } from 'react-hook-form';

import { sortByAlpha } from '@inspiren-monorepo/util-formatters';
import type { AdminTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { useUnitOptions } from '../../../../../hooks/useUnitOptions';
import { useRooms } from '../../../hooks/useRooms';
import { ControlledAutocomplete } from '../../../modals/ControlledAutocomplete';
import ControlledSelectUnit from '../../UsersTable/components/ControlledSelectUnit';

import type { Control, UseFormSetValue } from 'react-hook-form';

type Props = {
  control: Control;
  setValue: UseFormSetValue<
    Pick<Partial<AdminTypes.BasestationDto>, 'unitName' | 'roomId'>
  >;
};

const SelectUnitRoom = ({ control, setValue }: Props) => {
  const selectedUnit = useWatch({
    name: 'unitId',
    control,
  });

  const { data: units } = useUnitOptions();

  const { isLoading: roomsLoading, rooms } = useRooms({});

  const { roomsMap, sortedRooms } = useMemo(() => {
    const mappedRooms = rooms
      ?.filter((room) => room.unitId === selectedUnit && !room.deleted)
      .map((room) => ({
        label: room.displayName || '',
        value: room.id,
      }));

    const sortedRooms = sortByAlpha(mappedRooms ?? [], 'label');
    return {
      roomsMap: keyBy(sortedRooms, 'value'),
      sortedRooms,
    };
  }, [rooms, selectedUnit]);

  const handleUnitEdit = () => {
    setValue('roomId', '');
  };

  return (
    <>
      <ControlledSelectUnit
        handleUnitEdit={handleUnitEdit}
        options={units || []}
      />
      <ControlledAutocomplete
        id='roomId'
        label='Room'
        loading={roomsLoading}
        items={sortedRooms.map((room) => room.value)}
        getOptionLabel={(option) => roomsMap[option]?.label || ''}
        isOptionEqualToValue={(option, value) => option === value}
        control={control}
        disabled={!selectedUnit || roomsLoading}
      />
    </>
  );
};

export default SelectUnitRoom;
