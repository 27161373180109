import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { ROOM_DESIGNATION_OPTIONS } from '@inspiren-monorepo/shared-types';

import ControlledDropdown from '../../../modals/ControlledDropdown';

import type { RoomFieldTypes } from '../types/RoomFieldTypes';

const SelectRoomDesignation = () => {
  const { control } = useFormContext<RoomFieldTypes>();
  const options = useMemo(() => Object.values(ROOM_DESIGNATION_OPTIONS), []);

  return (
    <ControlledDropdown
      id='designation'
      label='Designation'
      items={options}
      control={control}
    />
  );
};

export default SelectRoomDesignation;
