import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from '@mui/material';

interface Props {
  open: boolean;
  onClose: () => void;
  subscribed: boolean;
  onConfirm: () => void;
  isLoading?: boolean;
}

export const OfflineReportSubModal = ({
  open,
  onClose,
  subscribed,
  onConfirm,
  isLoading,
}: Props) => (
  <Dialog open={open} onClose={onClose} fullWidth maxWidth='sm'>
    <DialogTitle>
      {subscribed ? 'Unsubscribe' : 'Subscribe'} to this AUGi Status Report
    </DialogTitle>
    <DialogContent>
      <DialogContentText>
        You will {subscribed ? 'stop receiving ' : 'receive '} daily email
        updates if any devices are offline and not disabled.
      </DialogContentText>
      <Divider sx={{ margin: '10px 0px' }} />
      <DialogActions>
        <Button
          onClick={onClose}
          variant='contained'
          size='small'
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button
          onClick={onConfirm}
          variant='contained'
          size='small'
          disabled={isLoading}
          endIcon={isLoading ? <CircularProgress size={20} /> : undefined}
        >
          {subscribed ? 'Unsubscribe' : 'Subscribe'}
        </Button>
      </DialogActions>
    </DialogContent>
  </Dialog>
);
