import type { Theme } from '@mui/material';
import {
  Box,
  Drawer,
  List,
  ListItemButton,
  ListItemText,
  Toolbar,
  useMediaQuery,
} from '@mui/material';
import { startCase } from 'lodash';
import { Link, useLocation } from 'react-router-dom';

import { useCurrentUser } from '../../HOC/CurrentUserContextProvider';
import {
  type AdminTab,
  useAccessibleAdminTabs,
} from '../../utility/permissions/useAccessibleAdminTabs';

type Props = {
  mobileOpen: boolean;
  onMobileClose: () => void;
};

const customNameMap: Partial<Record<AdminTab, string>> = {
  'api-keys': 'API Keys',
  organizations: 'General Settings',
};

const customPathMap: Partial<Record<AdminTab, string>> = {
  organizations: 'general-settings',
};

const AdminDrawer = ({ mobileOpen, onMobileClose }: Props) => {
  const location = useLocation();
  const { user } = useCurrentUser();

  // Match with only second section of path
  const currentTable = location.pathname.split('/')[2];

  // Drawer will be permanent above sm breakpoint
  const isDrawerPermanent = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.up('sm'),
  );

  // Edit drawer width here
  const drawerWidth = isDrawerPermanent ? '250px' : '60%';

  const accessibleTabs = useAccessibleAdminTabs(user);

  const menu = accessibleTabs.map((tab) => ({
    name: customNameMap[tab] || startCase(tab),
    path: customPathMap[tab] || tab,
  }));

  return (
    <Drawer
      variant={isDrawerPermanent ? 'permanent' : 'temporary'}
      open={mobileOpen}
      onClose={onMobileClose}
      sx={{
        width: drawerWidth,

        '& .MuiDrawer-paper': {
          width: drawerWidth,
          backgroundColor: 'grey.50',
        },
      }}
    >
      <Toolbar />
      <Box sx={{ overflow: 'auto' }}>
        <List dense>
          {menu.map((menuItem) => {
            const { path, name } = menuItem;
            const selected = path === currentTable;

            return (
              <ListItemButton
                component={Link}
                to={path}
                selected={selected}
                key={path}
                onClick={onMobileClose}
              >
                <ListItemText
                  primary={name}
                  primaryTypographyProps={{
                    color: selected ? 'primary' : undefined,
                    sx: { fontWeight: selected ? 700 : undefined },
                  }}
                />
              </ListItemButton>
            );
          })}
        </List>
      </Box>
    </Drawer>
  );
};

export default AdminDrawer;
