import '@fontsource/roboto/latin-italic.css';
import '@fontsource/roboto/latin.css';

import { StrictMode } from 'react';
import { CssBaseline, GlobalStyles, ThemeProvider } from '@mui/material';
import { LicenseInfo } from '@mui/x-license';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { isAxiosError } from 'axios';
import { ConfirmProvider } from 'material-ui-confirm';
import * as ReactDOM from 'react-dom/client';
import { Helmet } from 'react-helmet';
import { BrowserRouter } from 'react-router-dom';
import { TamaguiProvider } from 'tamagui';

import { tamaguiConfig } from '@inspiren-monorepo/util-tamagui';

import App from './app/App';
import Auth from './app/HOC/Auth';
import { CurrentUserContextProvider } from './app/HOC/CurrentUserContextProvider';
import ErrorScreen from './app/screens/ErrorScreen';
import globalStyles from './app/styles/global';
import theme from './app/styles/theme';
import { BrowserActivityTrackerProvider } from './app/utility/analytics';
import { initSentry } from './app/utility/sentry';

LicenseInfo.setLicenseKey(import.meta.env.VITE_APP_MUI_KEY);

initSentry();

const NO_RETRY_STATUSES = [400, 401, 403, 404];

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      refetchOnReconnect: true,
      retry: (failureCount, error) => {
        if (
          isAxiosError(error) &&
          NO_RETRY_STATUSES.some(
            (statusToSkip) => statusToSkip === error.response?.status,
          )
        )
          return false;

        if (failureCount >= 4) return false;
        return true;
      },
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <Sentry.ErrorBoundary
    fallback={
      <ErrorScreen defaultMessage='There was an error loading the page.' />
    }
  >
    <Helmet>
      <meta name='commit-hash' content={import.meta.env.VITE_COMMIT_HASH} />
      <meta name='commit-date' content={import.meta.env.VITE_COMMIT_DATE} />
    </Helmet>
    <StrictMode>
      <ThemeProvider theme={theme}>
        <TamaguiProvider config={tamaguiConfig}>
          <CssBaseline />
          <GlobalStyles styles={globalStyles} />
          <BrowserRouter>
            <QueryClientProvider client={queryClient}>
              <CurrentUserContextProvider>
                <Auth>
                  <ConfirmProvider
                    defaultOptions={{
                      confirmationText: 'OK',
                      confirmationButtonProps: {
                        variant: 'contained',
                        id: 'mui-confirm',
                      },
                    }}
                  >
                    <BrowserActivityTrackerProvider>
                      <App />
                    </BrowserActivityTrackerProvider>
                  </ConfirmProvider>
                </Auth>
              </CurrentUserContextProvider>
              <ReactQueryDevtools
                initialIsOpen={false}
                buttonPosition='bottom-left'
              />
            </QueryClientProvider>
          </BrowserRouter>
        </TamaguiProvider>
      </ThemeProvider>
    </StrictMode>
  </Sentry.ErrorBoundary>,
);
