import { isEmpty } from 'lodash';

import { Api } from '../../../../../utility/client';

export async function createBeacon(id: string, assignedTo?: string) {
  await Api.post('/admin/v1/beacons', {
    id,
    assignedTo: isEmpty(assignedTo) ? undefined : assignedTo,
  });
}
