export const SecureToken = ({ value }: { value: string }) => {
  const cutPoint = value.lastIndexOf('*') + 1;

  return (
    <>
      <span style={{ color: 'grey' }}>{value.slice(0, cutPoint)}</span>
      <span style={{ color: 'black' }}>{value.slice(cutPoint)}</span>
    </>
  );
};
