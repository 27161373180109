// custom component
// takes a component, and permission { action: string, subject: string }
// gets user from context
// checks if user has permission to view component
// if no, return component

import type { PropsWithChildren } from 'react';

import type { Permissions } from '@inspiren-monorepo/feature-permissions';


import { hasAccess } from './hasAccess';

import { useCurrentUser } from '../../HOC/CurrentUserContextProvider';

interface Props {
  permission: Permissions;
}

export const Cannot = ({ children, permission }: PropsWithChildren<Props>) => {
  const { user } = useCurrentUser();

  return !hasAccess(user, permission) ? children : null;
};
