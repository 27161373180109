import { useCallback, useEffect, useState } from 'react';
import {
  Alert,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from '@mui/material';
import { Close } from '@mui/icons-material';

import { fetchExportProgress } from './data-access/fetchExportProgress';
import { startVideoExport } from './data-access/startVideoExport';

import ExportState from '../../Exports/ExportState';
import { useEventReviewStoreShallow } from '../store/EventReviewStore';

type Props = {
  open: boolean;
  onClose: () => void;
};

const DownloadModal = ({ open, onClose }: Props) => {
  const [exportId, setExportId] = useState<string | null>(null);
  const [startError, setStartError] = useState<boolean>(false);

  const { selectedRoom, startDate, endDate } = useEventReviewStoreShallow([
    'selectedRoom',
    'startDate',
    'endDate',
  ]);

  const startExport = useCallback(async () => {
    try {
      if (!selectedRoom || !startDate || !endDate) return;

      const { exportId } = await startVideoExport(
        selectedRoom.domainId,
        startDate,
        endDate,
      );

      setExportId(exportId);
    } catch {
      setStartError(true);
    }
  }, [selectedRoom, startDate, endDate]);

  useEffect(() => {
    if (open) {
      startExport();
    } else {
      setExportId(null);
      setStartError(false);
    }
  }, [open]);

  return (
    <Dialog open={open}>
      <Stack direction='row'>
        <DialogTitle>Download as MP4</DialogTitle>
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            ml: 'auto',
            mr: 1,
            ':hover': {
              backgroundColor: 'transparent',
            },
          }}
        >
          <Close />
        </IconButton>
      </Stack>
      <DialogContent sx={{ textAlign: 'center', minWidth: 300 }}>
        {!startError && (
          <ExportState
            exportId={exportId}
            fetchExportFunc={fetchExportProgress}
          />
        )}
        {startError && (
          <Alert severity='error'>
            Something went wrong while trying to download the video.
          </Alert>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default DownloadModal;
