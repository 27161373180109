import { useCallback, useEffect, useMemo, useState } from 'react';
import { Card, Tooltip } from '@mui/material';
import * as Sentry from '@sentry/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { differenceInMilliseconds } from 'date-fns';
import { isEmpty } from 'lodash';
import toast from 'react-hot-toast';

import { prefetchImage } from '@inspiren-monorepo/shared-react/browser';
import {
  type WasabiImage,
  useLiveImage,
} from '@inspiren-monorepo/shared-react/universal';
import { type AlertType, UserEventType } from '@inspiren-monorepo/shared-types';
import { formatAlertType } from '@inspiren-monorepo/util-formatters';
import { checkIfPrivacyMode, DomainId } from '@inspiren-monorepo/util-rooms';
import type { TelesittingTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import RoomBottomBar from './RoomCard/RoomBottomBar';
import RoomImage from './RoomImage';
import RoomModal from './RoomModal';
import RoomTopBar from './RoomTopBar';
import { getRoomImages } from './data-access/getRoomLiveImages';
import { resolveNotification } from './data-access/resolveNotification';
import { turnOffPrivacyModeForRoom } from './data-access/turnOffPrivacyModeForRoom';
import { useNotifSound } from './hooks/useNotifSound';
import { useUnitForRoom } from './hooks/useUnitForRoom';

import { getAlertAnimationSxProps } from '../../styles/alertAnimation';
import { sendAmpEvent } from '../../utility/amplitude';
import { useTrackUserEvent } from '../../utility/analytics';
import { requestClearImagesFromBasestation } from '../../utility/mqtt';

import type { ResolveNotifProps } from './data-access/resolveNotification';
import type { ViewMode } from '../../../types';

interface Props {
  room: TelesittingTypes.PeriscopeRoom;
  roomSize: number;
  playingAudibleMessage: boolean;
  viewMode: ViewMode;
  showModal: boolean;
  onRoomCardClick: () => void;
  onModalClose: () => void;
  onNextClick?: () => void;
  onPrevClick?: () => void;
}

const RoomCard = ({
  room,
  roomSize,
  playingAudibleMessage,
  showModal,
  onRoomCardClick,
  onModalClose,
  onNextClick,
  onPrevClick,
  viewMode,
}: Props) => {
  const { trackEvent } = useTrackUserEvent();

  useNotifSound(room);

  const [hover, setHover] = useState(false);
  const [showTooltip, setShowTooltip] = useState(true);

  const queryClient = useQueryClient();

  const [isPrivacyModeOn, setPrivacyModeOn] = useState(
    checkIfPrivacyMode(room.virtualCurtainAt),
  );

  const { data: liveImagesData, isSuccess: liveImagesSuccess } = useQuery({
    queryKey: ['liveImages', room.domainId, viewMode],
    queryFn: () => getRoomImages({ roomId: room.domainId, viewMode }),
    enabled: !room.offline,
    refetchInterval: 45000,
    refetchIntervalInBackground: true,
  });

  const unit = useUnitForRoom(room.domainId);

  useEffect(() => {
    if (liveImagesSuccess && viewMode === 'telesitter') {
      requestClearImagesFromBasestation(
        DomainId.toOrgId(room.domainId),
        room.baseId!,
      );
    }
  }, [liveImagesData]);

  const resolveNotifMutation = useMutation({
    mutationFn: (data: ResolveNotifProps) => resolveNotification(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['customUnits'],
      });

      toast.success('Alert resolved');
    },
    onError: () => {
      toast.error('Failed to resolve alert');
    },
  });

  const handleResolve = useCallback(() => {
    if (!room.notificationId) return;

    resolveNotifMutation.mutate({
      roomId: room.domainId,
      notificationId: room.notificationId,
    });
  }, [room, resolveNotifMutation]);

  useEffect(() => {
    const privacyModeOn = checkIfPrivacyMode(room.virtualCurtainAt);
    setPrivacyModeOn(privacyModeOn);

    if (privacyModeOn && room.virtualCurtainAt) {
      const timeout = setTimeout(
        () => {
          setPrivacyModeOn(false);
        },
        differenceInMilliseconds(new Date(room.virtualCurtainAt), new Date()),
      );

      return () => clearTimeout(timeout);
    }

    return () => true;
  }, [room.virtualCurtainAt]);

  // room width above which to display large toolbars
  const largeUI = roomSize >= 450;

  const aspectRatio = 0.75;
  const roomHeight = roomSize * aspectRatio;

  const offline = useMemo(() => room?.offline ?? false, [room?.offline]);
  const disabled = useMemo(() => room?.disable ?? false, [room?.disable]);

  const logImageLoadFailure = useCallback(
    (image: WasabiImage | undefined) => {
      Sentry.captureMessage('Failed to load image after 3 retries', {
        extra: {
          ...image,
          roomId: room.domainId,
          baseId: room.baseId,
        },
      });
    },
    [room],
  );

  const { lastSuccessfulImage } = useLiveImage({
    src: liveImagesData?.images || [],
    prefetchImage,
    onImageLoadFailure: logImageLoadFailure,
  });

  const loading = useMemo(
    () => isEmpty(lastSuccessfulImage) && !offline,
    [lastSuccessfulImage, offline],
  );

  const handlePrivacyModeChange = useCallback(async () => {
    if (isPrivacyModeOn) {
      setPrivacyModeOn(false);

      sendAmpEvent('Privacy Mode Off', {
        room: room.domainId,
      });

      await turnOffPrivacyModeForRoom(room.domainId);
    }
  }, [isPrivacyModeOn, room.domainId]);

  const alert: AlertType | null = useMemo(
    () => (!offline && !loading && room.alert) || null,
    [offline, loading, room.alert],
  );

  const alertAnimation = useMemo(
    () => (alert && getAlertAnimationSxProps(alert, 'border')) || {},
    [alert],
  );

  return (
    <>
      <Tooltip
        title={
          (showTooltip &&
            alert &&
            `${formatAlertType(alert, unit?.bathroomAlertThreshold)} alert`) ||
          ''
        }
        followCursor
      >
        <Card
          sx={{
            width: roomSize,
            height: roomHeight,
            m: 0.5,
            position: 'relative',
            transform: hover ? 'scale(1.025)' : undefined,
            transition: 'transform 0.2s',
            ...alertAnimation,
          }}
          elevation={0}
          onMouseEnter={() => setHover(true)}
          onMouseMove={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          data-test-component='RoomCard-Card'
          data-test-roomid={room?.domainId}
        >
          <RoomTopBar
            room={room}
            playingAudibleMessage={playingAudibleMessage}
            privacy={isPrivacyModeOn}
            onPrivacyModeChange={handlePrivacyModeChange}
            size={largeUI ? 'large' : 'small'}
            sx={{
              pt: largeUI ? 2 : 1.5,
              pl: largeUI ? 2.5 : 2,
              pr: largeUI ? 1.5 : 0.5,
            }}
            hideAudibleMessageButton
          />

          {hover && (
            <RoomBottomBar
              room={room}
              onMenuClose={() => setHover(false)}
              size={largeUI ? 'large' : 'small'}
              setTooltip={setShowTooltip}
              resolve={handleResolve}
              resolveNotifLoading={resolveNotifMutation.isPending}
              alert={alert}
              sx={{ pb: largeUI ? 2 : 1.5, px: largeUI ? 2 : 1.5 }}
              offline={offline}
              disabled={disabled}
              showFallRisk={false}
            />
          )}

          <RoomImage
            onClick={() => {
              onRoomCardClick();

              trackEvent({
                type: alert
                  ? UserEventType.AlertLiveViewOpened
                  : UserEventType.ExpandedLiveViewOpened,
                room: room.domainId,
              });

              sendAmpEvent('Expand Room Card', { room: room.domainId });
            }}
            src={lastSuccessfulImage}
            width={roomSize}
            height={roomHeight}
            sx={{ cursor: 'pointer' }}
            privacy={isPrivacyModeOn}
            privEndsAt={room.virtualCurtainAt}
            disabled={!!room.disable}
            loading={loading}
            offline={offline}
            keepAlive={room.keepAliveAt}
          />
        </Card>
      </Tooltip>

      {showModal && (
        <RoomModal
          imgSrc={lastSuccessfulImage}
          room={room}
          onCloseClick={onModalClose}
          setTooltip={setShowTooltip}
          resolve={handleResolve}
          resolveNotifLoading={resolveNotifMutation.isPending}
          onPrivacyModeChange={handlePrivacyModeChange}
          disabled={disabled}
          imageLoading={loading}
          offline={offline}
          alert={alert}
          privacy={isPrivacyModeOn}
          playingAudibleMessage={playingAudibleMessage}
          onNextClick={onNextClick}
          onPrevClick={onPrevClick}
        />
      )}
    </>
  );
};

export default Sentry.withProfiler(RoomCard);
