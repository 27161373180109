import type React from 'react';
import { useCallback, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { keyBy } from 'lodash';
import toast from 'react-hot-toast';

import { getAxiosErrorMessage } from '@inspiren-monorepo/util-axios';
import type { AdminTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { useUnitOptions } from '../../../../../hooks/useUnitOptions';
import AddModal from '../../../modals/AddModal';
import { RoomForm } from '../../../tables/RoomsTable/components/RoomForm';
import getRoomsTableFields from '../../../tables/RoomsTable/constants/getRoomsTableFields';
import useRoomsUpsert from '../../../tables/RoomsTable/hooks/useRoomsUpsert';

import type {
  OnSubmitFormModal,
  RenderFormModal,
} from '../../../modals/FormModalBase';
import type { RoomFieldTypes } from '../../../tables/RoomsTable/types/RoomFieldTypes';

type Props = {
  room: AdminTypes.AlisStructureRoom;
  bed: AdminTypes.AlisStructureBed;
  children: React.ReactElement;
};

export const AddRoomModal = ({ room, bed, children }: Props) => {
  const queryClient = useQueryClient();
  const orgId = import.meta.env.VITE_ORG_ID;

  const { data: units } = useUnitOptions();
  const unitsById = useMemo(() => keyBy(units || [], 'id'), [units]);

  const roomFields = useMemo(() => getRoomsTableFields(unitsById), [unitsById]);

  const renderModal = useCallback<RenderFormModal<RoomFieldTypes>>(
    ({ defaultComponents }) => (
      <RoomForm
        type='add'
        unitsMap={unitsById}
        isAdmin
        defaultComponents={defaultComponents}
        orgId={orgId}
        disableUnit
        disableHousehold
      />
    ),
    [roomFields, unitsById, orgId],
  );

  const { handleAddSubmit: handleAdd } = useRoomsUpsert(unitsById);

  const handleAddSubmit: OnSubmitFormModal<RoomFieldTypes> = useCallback(
    async (room) => {
      try {
        await handleAdd(room);

        await Promise.all([
          queryClient.invalidateQueries({
            queryKey: ['alis-community-structure'],
          }),
          queryClient.invalidateQueries({
            queryKey: ['room-options'],
          }),
        ]);

        toast.success(`Successfully added room ${room.displayName}`);
      } catch (error) {
        console.error(error);

        const message =
          getAxiosErrorMessage(error) ??
          `Error adding room${error ? `: ${error}` : ''}`;

        toast.error(message);
      }
    },
    [],
  );

  if (!roomFields) return null;

  return (
    <AddModal
      itemName='Room'
      fields={roomFields}
      onSubmit={handleAddSubmit}
      renderModal={renderModal}
      initialValues={{
        id: '',
        displayName: `${room.name}`,
        orgId: import.meta.env.VITE_ORG_ID,
        unitId: room.connectedHousehold?.unitId as string,
        householdId: room.connectedHousehold?.id as string,
        alisRoomId: room.id,
        alisBedId: bed.id,
      }}
    >
      {children}
    </AddModal>
  );
};
