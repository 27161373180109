import { AppBar, Box, Button, Stack, Tab, Tabs, Toolbar } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

import ProfileMenu from './ProfileMenu';

import { useCurrentUser } from '../../HOC/CurrentUserContextProvider';
import { screens } from '../../screens/screens';
import { sendAmpEvent } from '../../utility/amplitude';
import { Can } from '../Can/Can';
import { hasAccess } from '../Can/hasAccess';
import { Logo } from '../SVG';

const NavBar = () => {
  const location = useLocation();
  const { user } = useCurrentUser();

  // Match with only first section of path
  const currentScreen = `/${location.pathname.split('/')[1]}`;

  // filter screens
  const accessibleScreens = screens.filter(({ subject }) =>
    hasAccess(user, { action: 'view', subject }),
  );

  const zuiDomain = import.meta.env.VITE_ZUI_DOMAIN;

  return (
    <AppBar
      position='fixed'
      elevation={0}
      sx={{
        backgroundColor: '#fff',
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Toolbar>
        <Box sx={{ flexGrow: 1 }}>
          <Link to='/rooms'>
            <Logo width={134} height={37} />
          </Link>
        </Box>
        <Stack direction='row' alignItems='center' spacing={2}>
          <Tabs value={currentScreen}>
            {accessibleScreens.map(({ label, path }) => (
              <Tab
                component={Link}
                onClick={() => sendAmpEvent(path)}
                value={path}
                to={path}
                label={label}
                key={path}
                sx={{
                  fontWeight: 500,
                  '&.Mui-selected': {
                    fontWeight: 800,
                  },
                }}
              />
            ))}
          </Tabs>
          <Can permission={{ action: 'view', subject: 'virtual-care.tableau' }}>
            <Button
              variant='contained'
              href='https://prod-useast-b.online.tableau.com/#/site/augi/explore'
              target='_blank'
              onClick={() => sendAmpEvent('Launch Tableau')}
            >
              Launch Tableau
            </Button>
          </Can>
          {zuiDomain && (
            <Can permission={{ action: 'access', subject: 'zui' }}>
              <Button
                variant='contained'
                href={`//${zuiDomain}`}
                target='_blank'
                onClick={() => sendAmpEvent('Launch ZUI')}
              >
                Launch ZUI
              </Button>
            </Can>
          )}
          <ProfileMenu />
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
