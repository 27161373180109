import { Stack, Card, Box } from '@mui/material';

import { fetchFallVideo } from './data-access/fetchFallVideo';

import ExportState from '../Exports/ExportState';
import { Logo } from '../SVG';

type Props = {
  exportId: string;
};

export const FallVideo = ({ exportId }: Props) => (
  <Stack direction='column' sx={{ height: '100%' }}>
    <Box sx={{ padding: 2, width: '100%' }}>
      <Logo width={134} height={37} />
    </Box>
    <Stack flex={1} alignItems='center' justifyContent='center' sx={{ px: 2 }}>
      <Card
        variant='outlined'
        sx={{
          padding: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          minWidth: '350px',
          minHeight: '400px',
        }}
      >
        <Stack
          justifyContent='center'
          alignItems='center'
          direction='column'
          sx={{ height: '100%' }}
        >
          <ExportState exportId={exportId} fetchExportFunc={fetchFallVideo} />
        </Stack>
      </Card>
    </Stack>
  </Stack>
);
