import { useMemo } from 'react';
import { skipToken, useQuery } from '@tanstack/react-query';
import { intervalToDuration } from 'date-fns';
import { capitalize, isNil } from 'lodash';

import { formatPatientStatus } from '@inspiren-monorepo/util-formatters';

import { getRoomState } from '../data-access/getRoomState';
import { useEventReviewStoreShallow } from '../store/EventReviewStore';

import type { RoomActivityRow } from '../DetailsPanel/RoomActivity/types/RoomActivityRow';

export const useRoomState = () => {
  const { selectedRoom, startDate, endDate, viewMode } =
    useEventReviewStoreShallow([
      'selectedRoom',
      'startDate',
      'endDate',
      'viewMode',
    ]);

  const roomId = selectedRoom?.domainId;

  const { data, isLoading, isError } = useQuery({
    queryKey: ['eventReview', 'roomState', roomId, startDate, endDate],
    queryFn:
      roomId && startDate && endDate && viewMode === 'admin'
        ? () =>
            getRoomState(roomId, startDate.toISOString(), endDate.toISOString())
        : skipToken,
  });

  const roomStateData: RoomActivityRow[] = useMemo(() => {
    if (!data) return [];

    return data
      .map((event) => {
        const oldStatus = formatPatientStatus(
          event.oldLoc || 'none',
          event.oldPose || 'none',
        );

        const newStatus = formatPatientStatus(
          event.newLoc || 'none',
          event.newPose || 'none',
        );

        const state =
          oldStatus === newStatus
            ? undefined
            : capitalize(`${oldStatus} to ${newStatus}`);

        return {
          event: 'StateChange' as const,
          state,
          time: new Date(event.time),
          timeOut: null,
          duration: event.dur
            ? intervalToDuration({ start: 0, end: event.dur * 1000 })
            : null,
          id: `${event.type}-${event.time}`,
        };
      })
      .filter((event) => !isNil(event.state));
  }, [data]);

  return { data: roomStateData, isLoading, isError };
};
