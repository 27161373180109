import { useQuery } from '@tanstack/react-query';

import type { AdminTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { TableBase } from '../../../components/TableBase';
import { formatUnassigned } from '../../helpers/formatUnassigned';
import { noValueClassName } from '../../helpers/noValueClassName';
import { getBeaconHistory } from '../data-access/getBeaconHistory';

import type { DataFields, FieldTypes } from '../../../types/DataFields';

type Props = {
  beacon: AdminTypes.Beacon;
};

interface BeaconHistoryFields extends FieldTypes {
  version: number;
  updatedAt: string;
  id: string;
  assignedTo?: string;
  assignedToEmail?: string;
  assignedToUserFullName?: string;
  latest: number;
  org: string;
}

const fields: DataFields<BeaconHistoryFields> = [
  {
    field: 'version',
    label: 'Version',
    editType: 'text',
    width: 80,
    editable: false,
  },
  {
    field: 'assignedToEmail',
    label: 'Assigned To',
    width: 300,
    editType: 'text',
    valueFormatter: formatUnassigned,
    cellClassName: noValueClassName,
  },
  {
    field: 'assignedToUserFullName',
    label: 'Name',
    width: 150,
    editType: 'text',
    valueFormatter: formatUnassigned,
    cellClassName: noValueClassName,
  },
];

export const BeaconHistoryTable = ({ beacon }: Props) => {
  const { id } = beacon;

  const {
    isLoading: loading,
    data: history,
    isError,
  } = useQuery({
    queryKey: ['beaconHistory', id],
    queryFn: () => getBeaconHistory(id),
  });

  return (
    <TableBase<BeaconHistoryFields>
      itemName='Beacon'
      loading={loading}
      error={isError}
      fields={fields}
      data={history}
      getRowId={(row) => row.version}
      disableEditing
      showAddButton={false}
    />
  );
};
