import { useFormContext, useWatch } from 'react-hook-form';

import { DomainId } from '@inspiren-monorepo/util-rooms';
import type { SelectOptionsTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { ControlledAutocomplete } from '../../../modals/ControlledAutocomplete';
import { useCareLevels } from '../../CareLevelsTable/hooks/useCareLevels';

import type { RoomFieldTypes } from '../types/RoomFieldTypes';

interface Props {
  unitsMap: Record<string, SelectOptionsTypes.UnitOption>;
}

export const SelectCareLevel = ({ unitsMap }: Props) => {
  const { control } = useFormContext<RoomFieldTypes>();
  const unitId = useWatch({ name: 'unitId', control });
  const unit = unitId ? unitsMap[unitId] : undefined;
  const building = unit ? DomainId.toBuildingId(unit.domainId) : undefined;

  const { data: careLevels = [], isLoading } = useCareLevels(building);

  return (
    <ControlledAutocomplete
      id='careLevel'
      label='Care Level'
      items={careLevels.map((careLevel) => careLevel.id)}
      loading={isLoading}
      control={control}
      getOptionLabel={(id) =>
        careLevels.find((careLevel) => careLevel.id === id)?.displayName || id
      }
    />
  );
};
