import { useMemo } from 'react';
import { Box, Divider, Grow, Toolbar, useTheme } from '@mui/material';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import {
  createHtmlPortalNode,
  InPortal,
  OutPortal,
} from 'react-reverse-portal';

import DetailsPanel from './DetailsPanel';
import EventToolbar from './EventToolbar';
import ImagePlayer from './ImagePlayer';
import { useSplitPane } from './hooks/useSplitPane';

export const EventReview = () => {
  const theme = useTheme();
  const splitPane = useSplitPane();

  const imagePlayerPortalNode = useMemo(() => createHtmlPortalNode(), []);
  const detailsPanelPortalNode = useMemo(() => createHtmlPortalNode(), []);

  return (
    <>
      <InPortal node={imagePlayerPortalNode}>
        <ImagePlayer />
      </InPortal>

      <InPortal node={detailsPanelPortalNode}>
        <DetailsPanel />
      </InPortal>

      <Box
        sx={{
          backgroundColor: 'grey.50',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Toolbar />
        <EventToolbar />
        <Grow in style={{ transformOrigin: 'top center' }}>
          <Box
            sx={{
              mx: 2,
              mb: 2,
              borderRadius: `${theme.shape.borderRadius}px`,
              border: `1px solid ${theme.palette.divider}`,
              backgroundColor: '#fff',
            }}
          >
            {splitPane ? (
              <PanelGroup direction='horizontal'>
                <Panel defaultSize={50} minSize={30}>
                  <OutPortal node={imagePlayerPortalNode} />
                </Panel>
                <PanelResizeHandle
                  style={{
                    width: '1px',
                    backgroundColor: theme.palette.divider,
                  }}
                />
                <Panel defaultSize={50} minSize={30}>
                  <OutPortal node={detailsPanelPortalNode} />
                </Panel>
              </PanelGroup>
            ) : (
              <>
                <OutPortal node={imagePlayerPortalNode} />
                <Divider />
                <OutPortal node={detailsPanelPortalNode} />
              </>
            )}
          </Box>
        </Grow>
      </Box>
    </>
  );
};
