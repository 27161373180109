import { queryOptions, useQuery } from '@tanstack/react-query';

import { getCareLevels } from '../../../data-access/getCareLevels';

export const getCareLevelsQueryOptions = (building?: string) =>
  queryOptions({
    queryKey: ['careLevels', building],
    queryFn: async () => getCareLevels(building),
  });

export const useCareLevels = (building?: string) =>
  useQuery(getCareLevelsQueryOptions(building));
