import type { DOMAttributes } from 'react';
import { forwardRef, useMemo } from 'react';
import type { TextFieldProps } from '@mui/material';
import { Autocomplete, TextField } from '@mui/material';
import { keyBy, orderBy } from 'lodash';

import type { SelectOptionsTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

interface Props
  extends Pick<
    DOMAttributes<HTMLDivElement>,
    | 'onBlur'
    | 'onFocus'
    | 'onMouseLeave'
    | 'onMouseOver'
    | 'onTouchEnd'
    | 'onTouchStart'
  > {
  id?: string;
  value?: string;
  onChange: (value: string | null) => void;
  error?: unknown;
  disabled?: boolean;
  label?: string;
  loading?: boolean;
  options?: SelectOptionsTypes.UnitOption[];

  inputSx?: TextFieldProps['sx'];
}

const SelectUnit = forwardRef((props: Props, ref) => {
  const {
    value,
    onChange,
    id,
    error,
    disabled,
    label,
    inputSx,
    onBlur,
    onFocus,
    onMouseLeave,
    onMouseOver,
    onTouchEnd,
    onTouchStart,
    options,
    loading,
  } = props;

  const units = useMemo(
    () =>
      orderBy(
        options,
        [
          (item) => item?.buildingDisplayName?.toLowerCase(),
          (item) => item?.displayName?.toLowerCase(),
          (item) => item?.name?.toLowerCase(),
        ],
        ['asc', 'asc', 'asc'],
      ),
    [options],
  );

  const mappedUnits = useMemo(() => keyBy(units, 'id'), [units]);

  return (
    <Autocomplete<string>
      ref={ref}
      id={id}
      disabled={disabled}
      data-testid='unit'
      options={Object.keys(mappedUnits)}
      loading={loading}
      getOptionLabel={(option) => {
        if (option in mappedUnits)
          return `${
            mappedUnits[option].buildingDisplayName
          } ${mappedUnits[option].displayName || mappedUnits[option].name}`;
        return 'error';
      }}
      renderOption={(renderProps, option) => (
        <li {...renderProps}>
          {mappedUnits[option].displayName || mappedUnits[option].name}
        </li>
      )}
      groupBy={(option) =>
        mappedUnits[option].buildingDisplayName ||
        mappedUnits[option].domainId.split('-')[1]
      }
      value={value && value in mappedUnits ? value : null}
      isOptionEqualToValue={(o, v) => o === v}
      onChange={(_a, b) => onChange(b)}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          margin='dense'
          error={Boolean(error)}
          sx={inputSx}
        />
      )}
      fullWidth
      onBlur={onBlur}
      onFocus={onFocus}
      onMouseLeave={onMouseLeave}
      onMouseOver={onMouseOver}
      onTouchEnd={onTouchEnd}
      onTouchStart={onTouchStart}
    />
  );
});

export default SelectUnit;
