import type { FC } from 'react';
import { Box, Typography } from '@mui/material';

import theme from '../../../../styles/theme';

interface ImageEventContainerProps {
  title: 'AUGi Disabled' | 'AUGi Offline' | 'Virtual Curtain Enabled';
}

export const ImageEventContainer: FC<ImageEventContainerProps> = ({
  title,
}) => (
  <Box
    sx={{
      background:
        title === 'AUGi Offline'
          ? theme.palette.grey[400]
          : title === 'AUGi Disabled'
            ? theme.palette.disableAugi.main
            : 'linear-gradient(180deg, rgba(71,109,195,1) 0%, rgba(172,193,253,1) 100%)',
      color:
        title === 'AUGi Offline'
          ? 'grey.600'
          : theme.palette.disableAugi.contrastText,
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 1,
    }}
  >
    <Typography variant='body2' mt={1} sx={{ userSelect: 'none' }}>
      {title}
    </Typography>
  </Box>
);
