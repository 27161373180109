import * as yup from 'yup';

import schema from '../../../../../utility/schema';

const emailSchema = yup.string().email('Must be a valid email').max(255);

const usernameSchema = yup
  .string()
  .strict(true)
  .min(3)
  .max(15)
  .required('You must provide a username.')
  .lowercase('Username must be lowercase.')
  .matches(
    /^[\da-z]+$/,
    'Username can only contain lowercase letters and numbers',
  );

const getEmailSchema = (required?: boolean) =>
  schema.applyRequirementConstraint(
    emailSchema,
    !!required,
    'You must provide an email.',
  );

export default {
  getEmailSchema,
  usernameSchema,
};
