import { useCallback, useMemo } from 'react';
import { Link } from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { formatInTimeZone } from 'date-fns-tz';
import { isEmpty, isNaN, isNil } from 'lodash';
import toast from 'react-hot-toast';
import * as yup from 'yup';

import { getAxiosErrorMessage } from '@inspiren-monorepo/util-axios';
import { DEFAULT_TIMEZONE } from '@inspiren-monorepo/util-date';
import { isUsernameOrg } from '@inspiren-monorepo/util-users';
import type { AdminTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { Battery } from './components/Battery';
import { BeaconModalForm } from './components/BeaconModalForm';
import { BulkUploadCSV } from './components/BulkUploadCSV';
import { createBeacon } from './data-access/createBeacon';
import { updateBeacon } from './data-access/updateBeacon';

import { useIsAdmin } from '../../../../hooks/useIsAdmin';
import { useOrgSettings } from '../../../../hooks/useOrgSettings';
import { TableBase } from '../../components/TableBase';
import { AdminTableWrapper } from '../../components/TableBase/AdminTableWrapper';
import { getBeacons } from '../../data-access/getBeacons';
import { formatNoValue } from '../helpers/formatNoValue';
import { formatUnassigned } from '../helpers/formatUnassigned';
import { noValueClassName } from '../helpers/noValueClassName';

import type {
  OnSubmitFormModal,
  RenderFormModal,
} from '../../modals/FormModalBase';
import type { DataFields, FieldTypes } from '../../types/DataFields';

/**
 * When we import beacons we only receive few last digits so we need to add prefix
 */
const BEACON_PREFIX = 'a0224eb0';

interface BeaconFields extends FieldTypes {
  id: string;
  assignedTo?: string;
  assignedToEmail?: string;
  assignedToUsername?: string;
  email?: string;
  firstName?: string;
  lastSeen?: string;
  latest: number;
  buildingName: string;
  lastSeenInRoom?: string;
  org: string;
  battery?: string;
}

export const BeaconsTable = () => {
  const awsOrg = import.meta.env.VITE_ORG_ID;
  const queryClient = useQueryClient();

  const { loading: settingLoading, data: orgSettings } = useOrgSettings(awsOrg);

  const {
    isLoading: loading,
    data: beacons,
    isError,
  } = useQuery({
    queryKey: ['beacons'],
    queryFn: () => getBeacons(),
  });

  const fields = useMemo(() => {
    const innerFields: DataFields<BeaconFields> = [
      {
        field: 'id',
        label: 'ID',
        width: 250,
        editType: 'text',
        editable: false,
        initialValue: BEACON_PREFIX,
        schema: yup
          .string()
          .required('You must provide an ID')
          .test(
            'id',
            `ID should have ${BEACON_PREFIX.length + 4} characters.`,
            (value) => {
              if (orgSettings?.settings?.fourDigitValidation) {
                return value?.length === BEACON_PREFIX.length + 4;
              }

              return true;
            },
          )
          .matches(
            new RegExp(`^${BEACON_PREFIX}\\S+$`, 'i'),
            `ID should start with ${BEACON_PREFIX} prefix.`,
          )
          .matches(/^\S+$/i, "ID can't contain spaces.")
          .matches(
            /^[\da-z]+$/,
            'ID can only include lower-case letter and number characters',
          ),
        renderCell: ({ value }) => (
          <Link href={`/admin/beacons/${value}`}>{value}</Link>
        ),
      },
      {
        field: 'assignedTo',
        label: 'Assigned To',
        width: 300,
        editType: 'text',
        renderCell: ({ value, row }) => {
          if (!isEmpty(value)) {
            return isUsernameOrg(awsOrg)
              ? row.assignedToUsername
              : row.assignedToEmail;
          }

          return <span className='no-value'>unassigned</span>;
        },
      },
      {
        field: 'assignedToUserFullName',
        label: 'Name',
        width: 150,
        editType: 'text',
        valueFormatter: formatUnassigned,
        cellClassName: noValueClassName,
      },
      {
        field: 'battery',
        label: 'Battery',
        width: 90,
        editType: 'text',
        editable: false,
        renderCell: ({ value }) =>
          isNil(value) ? '-' : <Battery value={value} />,
      },
      {
        field: 'lastSeen',
        label: 'Last Seen At',
        width: 200,
        editType: 'text',
        sortComparator: (v1, v2) => {
          if (
            (!v1 || isNaN(new Date(v1).getTime())) &&
            (!v2 || isNaN(new Date(v2).getTime()))
          ) {
            return 0;
          }

          if (!v1 || isNaN(new Date(v1).getTime())) return -1;
          if (!v2 || isNaN(new Date(v2).getTime())) return 1;

          const date1 = new Date(v1);
          const date2 = new Date(v2);
          return date1.getTime() - date2.getTime();
        },
        valueFormatter: (
          value: string | undefined,
          beacon: AdminTypes.Beacon,
        ) => {
          if (isNil(value)) {
            return '-';
          }

          return formatInTimeZone(
            value,
            beacon.buildingTz || DEFAULT_TIMEZONE,
            'MMM dd, yyyy hh:mm a zzz',
          );
        },
      },
      {
        field: 'buildingName',
        label: 'Building',
        width: 200,
        editable: false,
        valueFormatter: formatNoValue,
      },
      {
        field: 'lastSeenInRoom',
        label: 'Last Seen In Room',
        width: 300,
        editType: 'text',
        valueFormatter: formatNoValue,
      },
    ];

    return innerFields;
  }, [orgSettings]);

  const { isAdmin } = useIsAdmin();

  const handleEditSubmit: OnSubmitFormModal<BeaconFields> = useCallback(
    async (beacon) => {
      try {
        await updateBeacon(beacon.id, beacon.assignedTo);

        await queryClient.invalidateQueries({
          queryKey: ['beacons'],
        });

        toast.success('Beacon updated successfully.');
      } catch (error) {
        const message =
          getAxiosErrorMessage(error) ??
          `Error updating beacon ${error ? `: ${error}` : ''}`;

        toast.error(message);
      }
    },
    [awsOrg],
  );

  const handleAddSubmit: OnSubmitFormModal<BeaconFields> = useCallback(
    async (beacon) => {
      try {
        await createBeacon(beacon.id, beacon.assignedTo);

        await queryClient.invalidateQueries({
          queryKey: ['beacons'],
        });

        toast.success('Beacon created successfully.');
      } catch (error) {
        const message = getAxiosErrorMessage(error) ?? 'Could not add beacon';

        toast.error(message);
      }
    },
    [],
  );

  const renderModal: RenderFormModal<BeaconFields> = useCallback(
    ({ control, type }) => (
      <BeaconModalForm control={control} type={type} beacons={beacons} />
    ),
    [beacons],
  );

  return (
    <AdminTableWrapper>
      <TableBase<BeaconFields>
        key={`beacons-${awsOrg}`}
        itemName='Beacon'
        fields={fields}
        data={beacons}
        loading={loading}
        modalLoading={settingLoading}
        error={isError}
        onEditSubmit={handleEditSubmit}
        onAddSubmit={handleAddSubmit}
        extraActionButtons={isAdmin && <BulkUploadCSV />}
        renderModal={renderModal}
        disableAddButton={!isAdmin}
        defaultPinnedColumns={['id']}
      />
    </AdminTableWrapper>
  );
};
