import { useCallback, useMemo } from 'react';
import { formatInTimeZone } from 'date-fns-tz';

import {
  DATETIME_NUMERIC_MONTH,
  DATETIME_NUMERIC_MONTH_NO_YEAR,
  DEFAULT_TIMEZONE,
  TIME_NUMERIC_MONTH,
} from '@inspiren-monorepo/util-date';

import { useEventReviewStoreShallow } from '../store/EventReviewStore';

export const useTimezone = ({ showDate = false, showYear = false } = {}) => {
  const { tzMode, selectedUnit } = useEventReviewStoreShallow([
    'tzMode',
    'selectedUnit',
  ]);

  const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const facilityTimezone = selectedUnit?.timezone || DEFAULT_TIMEZONE;
  const utcTimezone = 'UTC';

  const ianaTimezone = useMemo(
    () =>
      tzMode === 'facility'
        ? facilityTimezone
        : tzMode === 'local'
          ? localTimezone
          : utcTimezone,
    [tzMode, facilityTimezone, localTimezone],
  );

  const formattedTimezone = useMemo(
    () => formatInTimeZone(new Date(), ianaTimezone, 'zzz'),
    [ianaTimezone],
  );

  const formatString = useMemo(
    () =>
      showYear
        ? DATETIME_NUMERIC_MONTH
        : showDate
          ? DATETIME_NUMERIC_MONTH_NO_YEAR
          : TIME_NUMERIC_MONTH,
    [showDate, showYear],
  );

  const formatter = useCallback(
    (date: Date) => formatInTimeZone(date, ianaTimezone, formatString),
    [ianaTimezone, formatString],
  );

  return { formattedTimezone, formatInTimezone: formatter, ianaTimezone };
};
