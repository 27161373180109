import { Stack } from '@mui/material';
import type { GridCellClassNamePropType } from '@mui/x-data-grid-pro';
import * as yup from 'yup';

import type {
  AdminTypes,
  SelectOptionsTypes,
} from '@inspiren-monorepo/virtual-care/api-contracts';

import { formatUnassigned } from '../../../beacons/helpers/formatUnassigned';
import { ImportUnitSelect } from '../../ImportTable/components';
import importUniqueValidator from '../../ImportTable/validators/importUniqueValidator';
import importUniqueRoomValidator from '../../RoomsTable/validators/importUniqueRoomValidator';
import { ImportRoomSelect } from '../components';
import { AssignmentHistory } from '../components/AssignmentHistory';
import importProperAssignmentValidator from '../validators/importProperAssignmentValidator';

import type { ImportableDataFields } from '../../ImportTable/types/importable';

const virtualDropdownSchema = yup
  .string()
  .nullable()
  .when('virtual', {
    is: true,
    then: (schema) => schema.required("Pick one or un-check 'Virtual'"),
  });

const unassignedClassName: GridCellClassNamePropType = (params) => {
  if (!params.value || params.value === '') return 'basestations unassigned';
  return '';
};

export const getBasestationsTableFields = (
  unitsByKey?: Record<string, SelectOptionsTypes.UnitOption>,
  roomsByKey?: Record<string, AdminTypes.Room>,
): ImportableDataFields<AdminTypes.BasestationDto> => [
  {
    field: 'id',
    label: 'ID',
    width: 150,
    editType: 'text',
    editable: false,
    schema: yup
      .string()
      .required('You must provide an ID')
      .matches(
        /^[\da-z]+$/,
        'ID can only include lower-case letter and number characters',
      ),
    onImportCellEditValidators: [importUniqueValidator],
  },
  {
    field: 'buildingName',
    label: 'Building',
    width: 300,
  },
  {
    field: 'unitName',
    label: 'Unit',
    width: 150,
  },
  {
    field: 'unitId',
    label: 'Building-Floor-Unit',
    initialValue: '',
    schema: yup.string().nullable(),
    importType: 'select',
    renderImportCell: (params) => <ImportUnitSelect {...params} />,
    valueFormatter: (value: string) => {
      const unit = unitsByKey?.[value];
      return unit?.domainId || value;
    },
    width: 'hidden',
  },
  {
    field: 'roomName',
    label: 'Room',
    width: 160,
    valueFormatter: formatUnassigned,
    cellClassName: unassignedClassName,
    renderCell: ({ id, formattedValue }) => (
      <Stack
        sx={{ width: '100%' }}
        direction='row'
        justifyContent='space-between'
        alignItems='center'
      >
        {formattedValue}
        <AssignmentHistory basestationId={id as string} />
      </Stack>
    ),
  },
  {
    field: 'roomId',
    label: 'roomId',
    width: 'hidden',
    schema: yup.string().nullable(),
    importType: 'select',
    importLabel: 'Room ID',
    renderImportCell: (params) => <ImportRoomSelect {...params} />,
    onImportCellEditValidators: [
      importUniqueRoomValidator(
        'A Basestation has already been assigned to this room',
      ),
      importProperAssignmentValidator,
    ],
    valueFormatter: (value: string) => {
      const room = roomsByKey?.[value];
      return room?.domainId || value;
    },
  },
  { field: 'version', label: 'Version', width: 120, editType: 'text' },
  {
    field: 'virtual',
    label: 'Virtual',
    initialValue: false,
    schema: yup.boolean().nullable(),
    importType: 'boolean',
    type: 'boolean',
    editType: 'boolean',
    width: 80,
    flex: 0,
  },
  {
    field: 'scenario',
    label: 'Scenario',
    schema: virtualDropdownSchema,
    width: 200,
  },
];
