import type { GlobalStylesProps } from '@mui/material';

const globalStyles: GlobalStylesProps['styles'] = (theme) => ({
  'body, html': {
    backgroundColor: '#fff',
    height: '100%',
  },

  '#root, main': {
    height: '100%',
  },

  '.no-value': {
    color: theme.palette.grey[400],
    fontStyle: 'italic',
  },
});

export default globalStyles;
