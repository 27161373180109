import type { AdminTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { Api } from '../../../../../utility/client';

export const getExternalApiKeys = async () => {
  const res = await Api.get<AdminTypes.GetExternalApiKeysResponse>(
    '/admin/v1/external-api',
  );

  return res.data;
};
