import type { AdminTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { SelectUser } from './SelectUser';

import ControlledTextField from '../../../modals/ControlledTextField';

import type { Control } from 'react-hook-form';

interface Props {
  control: Control;
  type: 'add' | 'edit';
  beacons?: AdminTypes.Beacon[];
}

export const BeaconModalForm = ({ control, type, beacons }: Props) => (
    <>
      <ControlledTextField
        id='id'
        label='ID'
        control={control}
        disabled={type === 'edit'}
      />
      <SelectUser
        id='assignedTo'
        label='Assigned To'
        control={control}
        beacons={beacons}
      />
    </>
  );
