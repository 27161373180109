import type { AdminTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { Api } from '../../../../../utility/client';

export async function postHousehold(household: AdminTypes.NewHousehold) {
  const res = await Api.post<AdminTypes.Household>(
    '/admin/v1/households',
    household,
  );

  return res.data;
}
