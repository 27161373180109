import { Tooltip } from '@mui/material';
import {
  DateRangePickerDay,
  type DateRangePickerDayProps,
} from '@mui/x-date-pickers-pro';
import { isBefore, subDays } from 'date-fns';
import { isNil } from 'lodash';

import type { Dayjs } from 'dayjs';

interface Props extends DateRangePickerDayProps<Dayjs> {
  timeWindowInDays: number | null;
}

const DatePickerDayCell = ({
  timeWindowInDays,
  ...dateRangePickerDayProps
}: Props) => {
  const message = `You can only see the last ${timeWindowInDays} days of history`;

  const timeWindowStart = !isNil(timeWindowInDays)
    ? subDays(new Date(), timeWindowInDays + 1)
    : null;

  const cellDate = dateRangePickerDayProps.day.toDate();

  const showTooltip =
    timeWindowStart && cellDate && isBefore(cellDate, timeWindowStart);

  return (
    <Tooltip title={showTooltip ? message : ''}>
      {/* The empty span prevents the tooltip from being disabled if the DateRangePickerDay is disabled */}
      <span>
        <DateRangePickerDay {...dateRangePickerDayProps} />
      </span>
    </Tooltip>
  );
};

export default DatePickerDayCell;
