import { FormControlLabel, type FormControlLabelProps } from '@mui/material';

import ColorSwitch, { type ColorSwitchProps } from './ColorSwitch';

type Props = Pick<ColorSwitchProps, 'customColor'> &
  Pick<FormControlLabelProps, 'id' | 'checked' | 'onChange' | 'disabled'>;

export const MarkSwitch = ({
  customColor,
  ...formControlLabelProps
}: Props) => (
  <FormControlLabel
    {...formControlLabelProps}
    label='Show on video progress bar'
    slotProps={{ typography: { variant: 'body2' } }}
    control={
      <ColorSwitch customColor={customColor} size='small' sx={{ mr: 0.5 }} />
    }
  />
);
