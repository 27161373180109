import { useCallback, useState } from 'react';
import type { GridColDef } from '@mui/x-data-grid-pro';
import { useQuery, skipToken } from '@tanstack/react-query';
import { sortBy } from 'lodash';

import { getRoomSiteConfigHistory } from '../../../data-access/getRoomSiteConfigHistory';

export const useSiteConfigHistory = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<string | null>(null);

  const { data, isLoading } = useQuery({
    queryKey: ['siteConfigHistory', selectedRow],
    queryFn: selectedRow
      ? () => getRoomSiteConfigHistory(selectedRow)
      : skipToken,
  });

  const columns: GridColDef[] = [
    {
      field: 'updatedAt',
      headerName: 'Date Time',
      width: 200,
      renderCell: (params) => {
        const date = new Date(params.value as string);
        return date.toLocaleString();
      },
    },
    {
      field: 'fieldName',
      headerName: 'Field',
      width: 200,
    },
    {
      field: 'previousValue',
      headerName: 'Changed From',
      minWidth: 500,
      renderCell: (params) =>
        params.value !== null && params.value !== undefined
          ? JSON.stringify(params.value)
          : '',
    },
    {
      field: 'newValue',
      headerName: 'Changed To',
      minWidth: 500,
      renderCell: (params) =>
        params.value !== null && params.value !== undefined
          ? JSON.stringify(params.value)
          : '',
    },
    {
      field: 'updatedBy',
      headerName: 'Changed By',
      width: 150,
    },
    {
      field: 'notes',
      headerName: 'Notes',
      width: 300,
    },
  ];

  const handleOpen = useCallback((structureId: string) => {
    setSelectedRow(structureId);
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
    setSelectedRow(null);
  }, []);

  return {
    open,
    handleOpen,
    handleClose,
    columns,
    data: sortBy(data, 'updatedAt').reverse(),
    selectedRow,
    isLoading,
  };
};
