import React, { useCallback } from 'react';
import { Button, Stack } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { OfflineReportSubModal } from './OfflineReportSubModal';

import { useCurrentUser } from '../../../HOC/CurrentUserContextProvider';
import { updateUser } from '../../shared/data-access/updateUser';

export const OfflineReportSubButton = () => {
  const { user } = useCurrentUser();
  const queryClient = useQueryClient();
  const [open, setOpen] = React.useState(false);

  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);

  const updateAuthUserMutation = useMutation({
    mutationFn: updateUser,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['auth-user'],
      });
    },
  });

  const handleConfirm = useCallback(async () => {
    if (!user) return;

    await updateAuthUserMutation.mutateAsync({
      userId: user.domainId,
      updatedFields: {
        offlineReport: !user.offlineReport,
      },
    });

    handleClose();
  }, [user, updateAuthUserMutation, handleClose]);

  return (
    <>
      <Stack direction='row' alignItems='center'>
        <Button
          variant='contained'
          onClick={handleOpen}
          size='small'
          disabled={!user}
        >
          {user?.offlineReport ? 'Unsubscribe' : 'Subscribe'}
        </Button>
      </Stack>
      {open && (
        <OfflineReportSubModal
          open={open}
          onClose={handleClose}
          subscribed={user?.offlineReport || false}
          onConfirm={handleConfirm}
          isLoading={updateAuthUserMutation.isPending}
        />
      )}
    </>
  );
};
