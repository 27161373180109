import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

import type { SelectOptionsTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { useCurrentUser } from '../HOC/CurrentUserContextProvider';
import { Api } from '../utility/client';

type Props = Omit<
  UseQueryOptions<
    SelectOptionsTypes.RoomOption[],
    Error,
    SelectOptionsTypes.RoomOption[],
    string[]
  >,
  'queryKey' | 'queryFn'
> & {
  unitId?: string;
  householdId?: string;
};

export const useRoomOptions = (props: Props = {}) => {
  const { user } = useCurrentUser();
  const { unitId, householdId, ...restProps } = props;
  return useQuery({
    ...restProps,
    queryKey: [
      'room-options',
      user?.domainId || '',
      unitId || '',
      householdId || '',
    ],

    queryFn: async () => {
      const url = '/selectOptions/v1/rooms';

      const res = await Api.get<SelectOptionsTypes.RoomOption[]>(url, {
        params: {
          unitId,
          householdId,
        },
      });

      return res?.data;
    },
  });
};
