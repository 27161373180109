import { Api } from '../../../../../utility/client';

export async function assignAlisCommunity(
  communityId: number,
  buildingId: string,
) {
  await Api.patch(`/admin/v1/alis/communities/${communityId}/assign`, {
    buildingId,
  });
}
