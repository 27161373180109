import { Api } from '../../../utility/client';

import type { NotifRes } from '../../../../types';

export const getEventNotifications = async (
  room: string,
  start: string,
  end: string,
  includeUnpromoted: boolean,
) => {
  try {
    const url = `/eventReview/v1/notifications/${room}/${start}/${end}`;

    const res = await Api.get<NotifRes[]>(url, {
      params: { includeUnpromoted },
    });

    return res?.data;
  } catch (error) {
    console.error('error fetching event notifications');
    throw error;
  }
};
