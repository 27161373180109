import { useCallback } from 'react';
import { addSeconds, isWithinInterval, subSeconds } from 'date-fns';

import { useTimestamp } from '../../hooks/useTimestamp';

export const useIsRowActive = () => {
  const currentTimestamp = useTimestamp();

  return useCallback(
    (eventStartTime: Date | null, eventEndTime: Date | null) => {
      if (!currentTimestamp) {
        return false;
      }

      if (
        eventStartTime &&
        eventEndTime &&
        isWithinInterval(currentTimestamp, {
          // Give a little extra time because the nearest available image to the event start/end might be a few seconds off
          start: subSeconds(eventStartTime, 5),
          end: addSeconds(eventEndTime, 5),
        })
      ) {
        return true;
      }

      if (
        eventStartTime &&
        !eventEndTime &&
        isWithinInterval(currentTimestamp, {
          // Row will be active basically at the start time, with a little buffer
          start: subSeconds(eventStartTime, 5),
          end: addSeconds(eventStartTime, 5),
        })
      ) {
        return true;
      }

      return false;
    },
    [currentTimestamp],
  );
};
