import { Api } from '../../../../../utility/client';

export async function unassignAlisCommunity(
  communityId: number,
  buildingId: string,
) {
  await Api.patch(`/admin/v1/alis/communities/${communityId}/unassign`, {
    buildingId,
  });
}
