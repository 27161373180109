import { PromisePool } from '@supercharge/promise-pool';
import { isAxiosError } from 'axios';

import { createBeacon } from './createBeacon';

export type BulkCreateResponse = {
  successful: string[];
  unsuccessful: Array<{ id: string; reason: string }>;
};

export async function bulkCreateBeacons(
  org: string | undefined,
  beacons: Array<{ id: string; emailOrId?: string }>,
) {
  const response: BulkCreateResponse = {
    successful: [],
    unsuccessful: [],
  };

  await PromisePool.withConcurrency(20)
    .for(beacons)
    .process(async ({ id, emailOrId }) => {
      try {
        await createBeacon(id, emailOrId);
        response.successful.push(id);
      } catch (error) {
        if (isAxiosError(error)) {
          response.unsuccessful.push({
            id,
            reason: error.response?.data?.error?.message,
          });
        } else {
          console.error(error, id);
          response.unsuccessful.push({ id, reason: 'Something went wrong.' });
        }
      }
    });

  return response;
}
