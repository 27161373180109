import { isNil } from 'lodash';
import * as yup from 'yup';

type Params = {
  nullable?: boolean;
  required?: boolean;
};

export const positiveInt = (params: Params = {}) => {
  if (params.nullable && params.required)
    throw new Error('Cannot be both nullable and required');

  const schema = yup
    .number()
    .typeError('Must be a number.')
    .positive('Must be a positive number.')
    .integer('Must be a whole number.');

  if (params.required) {
    return schema.required('Field is required.');
  }

  if (params.nullable) {
    return schema
      .nullable()
      .transform((_, value) =>
        value === '' || isNil(value) ? null : Number(value),
      );
  }

  return schema;
};
