import { useCallback, useMemo } from 'react';
import { Button, Menu, MenuItem, Stack, Typography } from '@mui/material';
import CableIcon from '@mui/icons-material/Cable';
import { LoadingButton } from '@mui/lab';
import { grey } from '@mui/material/colors';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useConfirm } from 'material-ui-confirm';
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import toast from 'react-hot-toast';

import { getAxiosErrorMessage } from '@inspiren-monorepo/util-axios';
import { DomainId } from '@inspiren-monorepo/util-rooms';
import type { AdminTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { AddRoomModal } from './AddRoomModal';
import { AssignBedModal } from './AssignBedModal';

import { unassignAlisBed } from '../data-access/unassignAlisBed';

type Props = {
  room: AdminTypes.AlisStructureRoom;
  bed: AdminTypes.AlisStructureBed;
};

export const AlisBedRow = ({ room, bed }: Props) => {
  const queryClient = useQueryClient();

  const popupState = usePopupState({
    variant: 'popover',
    popupId: `${room.id}-${bed.id}`,
  });

  const confirm = useConfirm();

  const isLastBed = useMemo(() => room.beds.at(-1)?.id === bed.id, [room, bed]);

  const { mutate, isPending } = useMutation<
    void,
    unknown,
    {
      alisUnitId: number;
      alisBedId: string;
      roomId: string;
    }
  >({
    mutationFn: async ({ alisUnitId, alisBedId, roomId }) => {
      await unassignAlisBed(alisUnitId, alisBedId, roomId);
    },
    onSuccess: async () => {
      toast.success('Alis bed unassigned successfully');
      popupState.close();

      await queryClient.invalidateQueries({
        queryKey: ['alis-community-structure'],
      });
    },
    onError: (error: any) => {
      const message =
        getAxiosErrorMessage(error) ??
        `Error unassigning bed from room${error ? `: ${error}` : ''}`;

      toast.error(message);
    },
  });

  const handleUnassign = useCallback(() => {
    confirm({
      title: 'Are you sure?',
      confirmationText: 'Confirm',
      confirmationButtonProps: {
        color: 'primary',
      },
      dialogProps: {
        maxWidth: 'xs',
      },
    }).then(() => {
      mutate({
        alisUnitId: room.id,
        alisBedId: bed.id,
        roomId: bed.connectedRoom?.id as string,
      });
    });
  }, [room, bed, mutate]);

  return (
    <Stack direction='row' gap={2}>
      <Stack
        sx={{
          backgroundColor: grey[100],
          flex: 1,
          px: 2,
          pb: isLastBed ? 2 : 1,
          borderBottomLeftRadius: isLastBed ? '12px' : 0,
          borderBottomRightRadius: isLastBed ? '12px' : 0,
        }}
      >
        <Stack
          sx={{
            backgroundColor: 'white',
            borderRadius: 2,
            flex: 1,
            p: 2,
          }}
        >
          <Typography
            variant='overline'
            sx={{ lineHeight: 1.4, color: grey[500] }}
          >
            Bed:
          </Typography>
          <Typography variant='h5'>{bed.name}</Typography>
        </Stack>
      </Stack>
      <Stack
        sx={{
          px: 2,
          pb: isLastBed ? 2 : 1,
          flex: 1,
          border: `${grey[300]} dashed 2px`,
          borderTopWidth: 0,
          borderBottomWidth: isLastBed ? '2px' : 0,
          borderBottomLeftRadius: isLastBed ? '12px' : 0,
          borderBottomRightRadius: isLastBed ? '12px' : 0,
        }}
      >
        <Stack
          justifyContent='center'
          alignItems='flex-end'
          sx={{
            backgroundColor: grey[100],
            borderRadius: 2,
            flex: 1,
            p: 2,
          }}
        >
          {!bed.connectedRoom?.id && (
            <>
              <Button
                startIcon={<CableIcon />}
                variant='contained'
                color='inherit'
                {...bindTrigger(popupState)}
                disabled={!room.connectedHousehold?.id}
              >
                Connect to Room
              </Button>
              <Menu {...bindMenu(popupState)}>
                <AddRoomModal room={room} bed={bed}>
                  <MenuItem onClick={popupState.close}>Create Room</MenuItem>
                </AddRoomModal>
                <AssignBedModal room={room} bed={bed}>
                  <MenuItem onClick={popupState.close}>
                    Connect to Room
                  </MenuItem>
                </AssignBedModal>
              </Menu>
            </>
          )}
          {bed.connectedRoom?.id && (
            <Stack
              direction='row'
              justifyContent='space-between'
              sx={{ flex: 1, width: '100%' }}
            >
              <LoadingButton onClick={handleUnassign} loading={isPending}>
                Unassign
              </LoadingButton>
              <Stack
                alignItems='flex-end'
                justifyContent='flex-start'
                sx={{ flex: 1 }}
              >
                <Typography
                  variant='overline'
                  sx={{ lineHeight: 1.4, color: grey[500] }}
                >
                  Room:
                </Typography>
                <Stack direction='row' spacing={2}>
                  <Typography variant='h4'>
                    {bed.connectedRoom.displayName ||
                      DomainId.parse(bed.connectedRoom.domainId).room}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};
