import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

type Props = {
  cancelDisabled?: boolean;
  cancel: () => void;

  confirmDisabled?: boolean;
  confirmLoading?: boolean;
  confirm: () => void;
};

export const ConfirmationStage = ({
  cancel,
  cancelDisabled,
  confirm,
  confirmDisabled,
  confirmLoading,
}: Props) => (
  <>
    <DialogContent>
      <DialogContentText sx={{ mb: 2 }}>
        Click <i>Next</i> if you want to generate the API key. It will be valid
        for six months.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button disabled={cancelDisabled} onClick={cancel}>
        Cancel
      </Button>
      <LoadingButton
        disabled={confirmDisabled}
        onClick={confirm}
        loading={confirmLoading}
        variant='contained'
      >
        Next
      </LoadingButton>
    </DialogActions>
  </>
);
