import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useFormContext, useWatch } from 'react-hook-form';

import { getUnits } from '../../../data-access/getUnits';
import ControlledDropdown from '../../../modals/ControlledDropdown';

import type { RoomFieldTypes } from '../types/RoomFieldTypes';

type Props = {
  orgId?: string;
};

const SelectRoomFallRisk = ({ orgId = '' }: Props) => {
  const { control } = useFormContext<RoomFieldTypes>();
  const selectedUnitId = useWatch({ name: 'unitId', control });

  const { isLoading: unitsLoading, data: units } = useQuery({
    queryKey: ['units'],
    queryFn: getUnits,
  });

  const unit = useMemo(
    () => units?.find((u) => u.id === selectedUnitId),
    [units, orgId, selectedUnitId],
  );

  const fallRiskOptions = useMemo(() => {
    const options = ['high', 'low', 'off'];
    if (!unit) return options;

    if (unit.nightLowFallRisk) {
      options.splice(2, 0, 'nightLow');
    }

    return options;
  }, [unit]);

  return (
    <ControlledDropdown
      id='fallRiskLevel'
      label='Fall Risk Level'
      items={fallRiskOptions}
      control={control}
      disabled={unitsLoading}
    />
  );
};

export default SelectRoomFallRisk;
