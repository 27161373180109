type Level = 'org' | 'building' | 'floor' | 'unit' | 'room';

type DomainIdParts = {
  org?: string;
  building?: string;
  floor?: string;
  unit?: string;
  room?: string;
};

export class DomainId {
  static toOrgId(domainId: string | null): string {
    const { org } = DomainId.parse(domainId);

    if (!org) {
      throw new Error('Invalid domain id');
    }

    return org;
  }

  static toBuildingId(domainId: string): string {
    const { org, building } = DomainId.parse(domainId);

    return `${org}-${building}`;
  }

  static toFloorId(domainId: string): string {
    const { org, building, floor } = DomainId.parse(domainId);

    return `${org}-${building}-${floor}`;
  }

  static toUnitId(domainId: string, roomPrefix = false): string {
    const { org, building, floor, unit } = DomainId.parse(domainId);

    return `${roomPrefix ? 'Room-' : ''}${org}-${building}-${floor}-${unit}`;
  }

  static toRoomId(domainId: string): string {
    const { org, building, floor, unit, room } = DomainId.parse(domainId);

    return `${org}-${building}-${floor}-${unit}-${room}`;
  }

  static toBuildingFloor(domainId: string): string {
    const { building, floor } = DomainId.parse(domainId);

    return `${building}-${floor}`;
  }

  static toBuildingFloorUnit(domainId: string | null): string {
    const { building, floor, unit } = DomainId.parse(domainId);

    return `${building}-${floor}-${unit}`;
  }

  static toLevel(domainId: string, level: Level): string {
    switch (level) {
      case 'org':
        return DomainId.toOrgId(domainId);
      case 'building':
        return DomainId.toBuildingId(domainId);
      case 'floor':
        return DomainId.toFloorId(domainId);
      case 'unit':
        return DomainId.toUnitId(domainId);
      case 'room':
        return DomainId.toRoomId(domainId);
      default:
        throw new Error('Unsupported level');
    }
  }

  static normalizeDomainId(domainId: string): string {
    return domainId.startsWith('Room-') ? domainId.substring(5) : domainId;
  }

  /**
   * Parse domain id into org, building, floor, unit and room
   * Id domain id starts with Room- we skip it.
   * @param domainId - domain id to parse
   */
  static parse(domainId: string | null): DomainIdParts {
    if (!domainId) return {};

    const domainIdNormalized = DomainId.normalizeDomainId(domainId);

    const [org, building, floor, unit, room] = domainIdNormalized.split('-');

    return {
      org,
      building,
      floor,
      unit,
      room,
    };
  }

  static getLevel(domainId: string): Level {
    const { building, floor, unit, room } = DomainId.parse(domainId);

    if (room) {
      return 'room';
    }

    if (unit) {
      return 'unit';
    }

    if (floor) {
      return 'floor';
    }

    if (building) {
      return 'building';
    }

    return 'org';
  }
}
