import { useCallback } from 'react';
import { LoadingButton } from '@mui/lab';
import { useMutation } from '@tanstack/react-query';
import { useConfirm } from 'material-ui-confirm';
import { useFormContext, useWatch } from 'react-hook-form';
import toast from 'react-hot-toast';

import { updateUserActivationState } from '../data-access/updateUserActivationState';
import { useSetDeactivateUserQueryData } from '../hooks/useSetDeactivateUserQueryData';

interface Props {
  orgId?: string;
  onSuccess: () => void;
}

export const ChangeUserActivationState = ({ orgId, onSuccess }: Props) => {
  const deactivated = useWatch({ name: 'deactivated' });
  const userId = useWatch({ name: 'userId' });
  const { setValue } = useFormContext();

  const deactivate = !deactivated;

  const confirm = useConfirm();

  const setDeactivateUserQueryData = useSetDeactivateUserQueryData(orgId);

  const { mutateAsync, isPending } = useMutation({
    mutationFn: async () => updateUserActivationState(userId, deactivate),
    onSuccess: () => {
      setDeactivateUserQueryData(userId, deactivate);
      setValue('deactivated', deactivate);
      onSuccess();

      toast.success(
        `Successfully ${deactivate ? 'deactivated' : 'reactivated'} the user.`,
      );
    },
    onError: () => {
      toast.error(
        `Failed to ${deactivate ? 'deactivate' : 'reactivate'} the user.`,
      );
    },
  });

  const deactivateUser = useCallback(async () => {
    try {
      await confirm({
        title: `Are you sure you want to ${deactivate ? 'deactivate' : 'reactivate'} this user?`,
        description: deactivate
          ? "This user's profile will be disabled and they will lose access. You can reactivate a user's profile from within this window at any time."
          : "This user's profile will be reactivated and they will regain access.",
      });

      await mutateAsync();
    } catch {
      // Do nothing. `confirm` will throw if the user cancels, which
      // is fine, and `mutate` is handled by its `onError` callback.
    }
  }, [confirm, mutateAsync]);

  return (
    <LoadingButton
      variant='contained'
      color={deactivate ? 'delete' : 'success'}
      loading={isPending}
      onClick={deactivateUser}
    >
      {deactivate ? 'Deactivate' : 'Reactivate'}
    </LoadingButton>
  );
};
