import { Autocomplete, Chip, TextField, Tooltip } from '@mui/material';
import { Controller } from 'react-hook-form';

import type { Control } from 'react-hook-form';

interface Props {
  control: Control;
  tooltip?: string;
}

const SelectPrivOpts = ({ control, tooltip }: Props) => (
  <Controller
    name='virtualCurtainDurationOptions'
    control={control}
    render={({ field: { value, onChange }, fieldState: { error } }) => (
      <Autocomplete
        multiple
        id='virtualCurtainDurationOptions'
        value={value}
        onChange={(_e, newValue) => onChange(newValue)}
        options={[]}
        open={false}
        freeSolo
        renderTags={(tagValue: string[], getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              variant='outlined'
              label={option}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <Tooltip
            title={tooltip}
            placement='left'
            disableHoverListener={!tooltip}
            disableInteractive={!tooltip}
            disableFocusListener={!tooltip}
          >
            <TextField
              {...params}
              variant='outlined'
              label='Virtual curtain duration options'
              placeholder='# of minutes'
              margin='dense'
              error={Boolean(error)}
              helperText={
                error && 'Privacy options must be positive whole numbers'
              }
            />
          </Tooltip>
        )}
      />
    )}
  />
);

export default SelectPrivOpts;
