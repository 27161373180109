import type { AdminTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { Api } from '../../../utility/client';

export const getBasestations = async () => {
  const res = await Api.get<AdminTypes.BasestationDto[]>(
    '/admin/v1/basestations',
  );

  return res.data;
};
