import type React from 'react';
import { createContext, useContext, useMemo } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

import type { OrgAuthSettings } from '@inspiren-monorepo/virtual-care/api-contracts';

import { useOrgAuthSettings } from './hooks/useOrgAuthSettings';

import Loading from '../screens/Loading';
import { sendAmpEvent } from '../utility/amplitude';

import type { AppState } from '@auth0/auth0-react';

const AuthSettingsContext = createContext<{
  authSettings?: OrgAuthSettings;
}>({
  authSettings: undefined,
});

export const useCurrentAuthSettings = () => useContext(AuthSettingsContext);

const Auth = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();

  const { authSettings, loading } = useOrgAuthSettings(
    window.location.hostname,
  );

  const onRedirectCallback = async (appState?: AppState) => {
    // Clear dropdown options in Event Review on login
    // so we don't get stale data from a previously logged in user
    sendAmpEvent('Login', {});
    navigate(`${appState?.returnTo || window.location.pathname}`);
  };

  const value = useMemo(() => ({ authSettings }), [authSettings]);

  if (loading) {
    return <Loading />;
  }

  if (!(authSettings?.auth0TenantUrl && authSettings?.auth0ClientId)) {
    return <div>There was an error loading the auth settings</div>;
  }

  return (
    <AuthSettingsContext.Provider value={value}>
      <Auth0Provider
        domain={authSettings.auth0TenantUrl}
        clientId={authSettings.auth0ClientId}
        audience={`https://${authSettings.auth0TenantUrl}/api/v2/`}
        redirectUri={`${window.location.origin}/rooms`}
        onRedirectCallback={onRedirectCallback}
        connection={undefined}
      >
        {children}
      </Auth0Provider>
    </AuthSettingsContext.Provider>
  );
};

export default Auth;
