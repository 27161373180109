import type { SelectChangeEvent } from '@mui/material';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  Tooltip,
} from '@mui/material';
import { isNil } from 'lodash';
import { Controller } from 'react-hook-form';

import type { DataFieldOption } from '../types/DataFields';
import type { Control } from 'react-hook-form';

export type Item = string | DataFieldOption | { groupLabel: string };

function isGroupLabel(item: Item): item is { groupLabel: string } {
  return !isNil((item as { groupLabel: string }).groupLabel);
}

type Props = {
  id: string;
  label: string;
  items: Item[];
  control: Control<any, any>;
  disabled?: boolean;
  required?: boolean;
  onChange?: (event: SelectChangeEvent) => void;
  defaultValue?: string;
  tooltip?: string;
};

const ControlledDropdown = ({
  id,
  label,
  items,
  control,
  disabled = false,
  required = false,
  onChange,
  defaultValue = '',
  tooltip,
}: Props) => {
  const labelId = `${id}-label`;

  return (
    <Controller
      name={id}
      control={control}
      defaultValue={defaultValue}
      render={({
        field: { name, value, onChange: onControllerChange },
        fieldState: { error },
      }) => {
        const handleChange = (event: SelectChangeEvent) => {
          onControllerChange(event);
          if (onChange) onChange(event);
        };

        // mui doesnt like null values
        const selectValue = value ?? '';

        // show selected value in dropdown before items have been pulled from backend
        const options = items && items.length ? items : [selectValue];
        return (
          <Tooltip
            title={tooltip}
            placement='left'
            disableHoverListener={!tooltip}
            disableInteractive={!tooltip}
            disableFocusListener={!tooltip}
          >
            <FormControl margin='dense' fullWidth>
              <InputLabel id={labelId} error={Boolean(error)}>
                {label}
              </InputLabel>
              <Select
                labelId={labelId}
                id={name}
                value={selectValue}
                data-testid={id}
                label={label}
                onChange={handleChange}
                disabled={disabled}
                required={required}
                error={Boolean(error)}
                defaultValue={defaultValue}
              >
                {options.map((item) => {
                  if (isGroupLabel(item)) {
                    return (
                      <ListSubheader key={item.groupLabel}>
                        {item.groupLabel}
                      </ListSubheader>
                    );
                  }

                  if (typeof item === 'string') {
                    return (
                      <MenuItem value={item} key={item}>
                        {item}
                      </MenuItem>
                    );
                  }

                  return (
                    <MenuItem value={item.value} key={item.value}>
                      {item.label}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText error={Boolean(error)}>
                {error?.message}
              </FormHelperText>
            </FormControl>
          </Tooltip>
        );
      }}
    />
  );
};

export default ControlledDropdown;
