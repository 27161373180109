import type React from 'react';
import { cloneElement, useMemo } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Button,
  DialogActions,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { keyBy, keys } from 'lodash';
import {
  bindDialog,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import { useForm, FormProvider, Form } from 'react-hook-form';
import toast from 'react-hot-toast';
import { z } from 'zod';

import { getAxiosErrorMessage } from '@inspiren-monorepo/util-axios';
import type { AdminTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { useHouseholdOptions } from '../../../../../hooks/useHouseholdOptions';
import { useUnitOptions } from '../../../../../hooks/useUnitOptions';
import { ControlledAutocomplete } from '../../../modals/ControlledAutocomplete';
import { ControlledSelectUnitHousehold } from '../../../tables/RoomsTable/components/ControlledSelectUnitHousehold';
import { assignAlisRoom } from '../data-access/assignAlisRoom';

type Props = {
  buildingId?: string;
  room: AdminTypes.AlisStructureRoom;
  children: React.ReactElement;
};

export const HouseholdFormSchema = z.object({
  unitId: z.string().min(1, 'Unit is required'),
  householdId: z.string().min(1, 'Household is required'),
});

export const AssignRoomModal = ({ children, room, buildingId }: Props) => {
  const queryClient = useQueryClient();
  const awsOrg = import.meta.env.VITE_ORG_ID;

  const popupState = usePopupState({
    variant: 'popover',
    popupId: `${room.id}`,
  });

  const form = useForm<z.infer<typeof HouseholdFormSchema>>({
    resolver: zodResolver(HouseholdFormSchema),
    defaultValues: {
      unitId: '',
      householdId: '',
    },
  });

  const unitId = form.watch('unitId');
  const householdId = form.watch('householdId');

  const { isLoading: unitsLoading, data: units } = useUnitOptions({
    buildingId,
  });

  const { isLoading, data: households } = useHouseholdOptions({ unitId });

  const unitsById = useMemo(() => keyBy(units, 'id'), [units]);

  const householdsById = useMemo(
    () => keyBy(households || [], 'id'),
    [households],
  );

  const { mutate, isPending } = useMutation<
    void,
    unknown,
    {
      householdId: string;
    }
  >({
    mutationFn: async ({ householdId }) => {
      await assignAlisRoom(room.id, householdId);
    },
    onSuccess: async () => {
      toast.success('Alis room assigned successfully');
      popupState.close();

      await queryClient.invalidateQueries({
        queryKey: ['alis-community-structure'],
      });
    },
    onError: (error: any) => {
      const message =
        getAxiosErrorMessage(error) ??
        `Error assigning room to apartment${error ? `: ${error}` : ''}`;

      toast.error(message);
    },
  });

  return (
    <>
      {cloneElement(children, { ...bindTrigger(popupState) })}
      <Dialog fullWidth maxWidth='xs' {...bindDialog(popupState)}>
        <DialogTitle>
          Connect to Apartment
          <IconButton
            aria-label='close'
            onClick={popupState.close}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <FormProvider {...form}>
          <Form {...form}>
            <DialogContent sx={{ py: 2 }}>
              <ControlledAutocomplete
                id='unitId'
                label='UnitId'
                items={keys(unitsById)}
                getOptionLabel={(unitId) => unitsById[unitId]?.displayName}
                loading={unitsLoading}
                control={form.control}
              />
              <ControlledSelectUnitHousehold
                isLoading={isLoading}
                householdsMap={householdsById}
                orgId={awsOrg}
                disabled={!unitId}
              />
            </DialogContent>
            <DialogActions>
              <Button
                variant='outlined'
                sx={{ fontSize: 14, width: '140px' }}
                onClick={popupState.close}
              >
                Cancel
              </Button>
              <LoadingButton
                variant='contained'
                sx={{ fontSize: 14, width: '140px' }}
                form='household-form'
                disabled={!householdId}
                onClick={() =>
                  mutate({
                    householdId,
                  })
                }
                loading={isPending}
              >
                Confirm
              </LoadingButton>
            </DialogActions>
          </Form>
        </FormProvider>
      </Dialog>
    </>
  );
};
