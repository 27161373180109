import type {
  AdminTypes,
  TelesittingTypes,
} from '@inspiren-monorepo/virtual-care/api-contracts';

import { Api } from '../../../utility/client';

interface Props {
  userId: string;
  updatedFields: Partial<AdminTypes.User | TelesittingTypes.User>;
}

export async function updateUser({ userId, updatedFields }: Props) {
  return Api.put(`/v1/users/${userId}`, {
    updatedFields,
  });
}
