import { useEffect, useRef, useState, memo } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  colors,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import DownloadIcon from '@mui/icons-material/Download';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import HideImageIcon from '@mui/icons-material/HideImage';
import { useQuery } from '@tanstack/react-query';
import { isNil } from 'lodash';

import type { TelesittingTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import type { State, Status } from './types/State';

type Props = {
  exportId: string | null;
  fetchExportFunc: (
    exportId: string,
  ) => Promise<TelesittingTypes.ExportProgress>;
};

const IconWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: colors.grey[100],
  borderRadius: '50%',
  padding: 24,
});

const ExportState = ({ exportId, fetchExportFunc }: Props) => {
  const anchorRef = useRef<HTMLAnchorElement | null>(null);

  const [state, setState] = useState<State>({
    status: 'loading',
    progress: 0,
  });

  const {
    data: progressData,
    isFetched,
    error: fetchError,
  } = useQuery({
    queryKey: ['eventReview', 'videoExportProgress', exportId],
    queryFn: () => fetchExportFunc(exportId!),
    enabled: !!exportId && state.status === 'loading',
    refetchInterval: 1000,
  });

  useEffect(() => {
    if (progressData) {
      let status: Status = 'loading';
      if (progressData.error) status = 'error';
      else if (progressData.progress === 100 && !isNil(progressData.url))
        status = 'success';
      else if (progressData.progress === 100 && isNil(progressData.url))
        status = 'no-images';
      else if (isFetched && isNil(progressData)) status = 'expired';

      setState((prevState) => ({
        ...prevState,
        downloadUrl: progressData.url,
        progress: progressData.progress || 0,
        error: progressData.error,
        status,
      }));
    } else if (fetchError) {
      setState((prevState) => ({
        ...prevState,
        status: 'error',
      }));
    }
  }, [progressData, fetchError]);

  useEffect(() => {
    if (state.status === 'success') {
      setTimeout(() => {
        anchorRef?.current?.click();
      }, 1000);
    }
  }, [state.status]);

  return (
    <Stack
      direction='column'
      alignItems='center'
      justifyContent='center'
      gap={4}
    >
      {!fetchError && state.status === 'loading' && (
        <>
          <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress
              size={48}
              variant={state.progress === 0 ? 'indeterminate' : 'determinate'}
              value={state.progress}
            />
            {state.progress > 0 && (
              <Box
                sx={{
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  position: 'absolute',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography
                  variant='caption'
                  component='div'
                >{`${state.progress}%`}</Typography>
              </Box>
            )}
          </Box>
          <Typography mt={3}>Encoding video...</Typography>
        </>
      )}

      {!fetchError && state.status === 'success' && (
        <>
          <IconWrapper>
            <DownloadIcon sx={{ fontSize: 52 }} />
          </IconWrapper>
          <Typography align='center'>
            Downloading should start automatically. If not please click the link
            below.
          </Typography>
          <a href={state.downloadUrl || undefined} ref={anchorRef}>
            <Button variant='text'>Click to download.</Button>
          </a>
        </>
      )}
      {!fetchError && state.status === 'error' && (
        <>
          <IconWrapper>
            <ErrorOutlineIcon sx={{ fontSize: 52 }} />
          </IconWrapper>
          <Typography>{state.error}</Typography>
        </>
      )}
      {!fetchError && state.status === 'no-images' && (
        <>
          <IconWrapper>
            <HideImageIcon sx={{ fontSize: 52 }} />
          </IconWrapper>
          <Typography>
            We couldn&apos;t find the images for given period of time.
          </Typography>
        </>
      )}
      {fetchError && (
        <>
          <IconWrapper>
            <AvTimerIcon sx={{ fontSize: 52 }} />
          </IconWrapper>
          <Typography>The link has expired.</Typography>
        </>
      )}
    </Stack>
  );
};

export default memo(ExportState);
