import { useState } from 'react';
import { Dialog, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import toast from 'react-hot-toast';

import { ConfirmationStage } from './stages/ConfirmationStage';
import { TokenResultStage } from './stages/TokenResultStage';

import { useGenerateExternalApiKey } from '../hooks/useGenerateExternalApiKey';

type Stage = 'confirmation-stage' | 'token-result';

type Props = {
  open: boolean;
  onClose: () => void;
};

export const GenerateExternalApiKeyModal = ({ open, onClose }: Props) => {
  const [stage, setStage] = useState<Stage>('confirmation-stage');
  const { mutateAsync, data, isPending } = useGenerateExternalApiKey();

  const onConfirm = async () => {
    try {
      await mutateAsync();
      setStage('token-result');
    } catch {
      toast.error('failed to create token');
    }
  };

  return (
    <Dialog open={open} maxWidth='xl'>
      <DialogTitle>
        Generate API Key
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {stage === 'confirmation-stage' && (
        <ConfirmationStage
          cancel={onClose}
          confirm={onConfirm}
          confirmLoading={isPending}
        />
      )}
      {stage === 'token-result' && !!data && (
        <TokenResultStage tokenDto={data} cancel={onClose} />
      )}
    </Dialog>
  );
};
