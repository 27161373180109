import { useMemo } from 'react';
import { Button, Stack } from '@mui/material';
import { NavigateNext as ArrowRightIcon } from '@mui/icons-material';
import {
  DateTimeRangePicker,
  LocalizationProvider,
  type DateTimeRangePickerSlots,
  type DateTimeRangePickerSlotProps,
  renderDigitalClockTimeView,
} from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { sub, subDays } from 'date-fns';
import dayjs, { type Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { compact, isNil } from 'lodash';

import DatePickerDayCell from './DatePickerDayCell';
import { useTimezone } from './hooks/useTimezone';
import { useUpdateSelection } from './hooks/useUpdateSelection';
import {
  type TzMode,
  useEventReviewStoreShallow,
} from './store/EventReviewStore';

import { useIsAdmin } from '../../hooks/useIsAdmin';
import { Can } from '../Can/Can';
import { DropdownSingleSelect } from '../shared/DropdownSingleSelect';

dayjs.extend(utc);
dayjs.extend(timezone);

const DatePicker = () => {
  const {
    datePickerEndDate,
    datePickerStartDate,
    selectedUnit,
    setDatePickerStartAndEndDate,
    setTzMode,
    tzMode,
  } = useEventReviewStoreShallow([
    'datePickerEndDate',
    'datePickerStartDate',
    'selectedUnit',
    'setDatePickerStartAndEndDate',
    'setTzMode',
    'tzMode',
  ]);

  const { updateStartAndEndDate } = useUpdateSelection();

  const { isAdmin } = useIsAdmin();

  const tzModeOptions = useMemo<{ value: TzMode; label: string }[]>(
    () =>
      compact([
        {
          value: 'facility',
          label: 'Facility Timezone',
        },
        {
          value: 'local',
          label: 'Local Timezone',
        },
        isAdmin && {
          value: 'utc',
          label: 'UTC Timezone',
        },
      ]),
    [isAdmin],
  );

  const now = new Date();

  const timeWindowInDays =
    selectedUnit && selectedUnit.imageExp && selectedUnit.eventExp
      ? Math.max(selectedUnit.imageExp, selectedUnit.eventExp)
      : null;

  const windowStart = !isNil(timeWindowInDays)
    ? subDays(now, timeWindowInDays)
    : null;

  const { formattedTimezone, ianaTimezone } = useTimezone();

  const selectPrevDays = (days: number) => {
    const start = sub(now, { days, minutes: -1 });
    updateStartAndEndDate(start, now);
  };

  return (
    <Stack
      direction='row'
      flexWrap='wrap'
      alignItems='center'
      justifyContent='center'
      gap={2}
    >
      {/* Unfortunately the date-fns adapter does not yet support timezones,
      so we're using dayjs just to interface with the date picker
      https://github.com/mui/mui-x/issues/10573 */}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimeRangePicker
          value={[
            datePickerStartDate ? dayjs(datePickerStartDate) : null,
            datePickerEndDate ? dayjs(datePickerEndDate) : null,
          ]}
          onChange={([start, end]) => {
            setDatePickerStartAndEndDate(
              start?.toDate() ?? null,
              end?.toDate() ?? null,
            );
          }}
          onAccept={([start, end], { validationError }) => {
            updateStartAndEndDate(
              start && isNil(validationError[0]) ? start.toDate() : null,
              end && isNil(validationError[1]) ? end.toDate() : null,
            );
          }}
          minDateTime={windowStart ? dayjs(windowStart) : undefined}
          disableFuture
          localeText={{
            start: `Start time (${formattedTimezone})`,
            end: `End time (${formattedTimezone})`,
          }}
          timezone={ianaTimezone}
          format='MM/DD/YYYY hh:mm A'
          views={['day', 'hours']}
          timeSteps={{ minutes: 15 }}
          viewRenderers={{ hours: renderDigitalClockTimeView }}
          slots={{
            day: DatePickerDayCell as DateTimeRangePickerSlots<Dayjs>['day'],
            fieldSeparator:
              ArrowRightIcon as DateTimeRangePickerSlots<Dayjs>['fieldSeparator'],
          }}
          slotProps={{
            actionBar: {
              actions: ['clear', 'cancel', 'accept'],
            },
            day: {
              timeWindowInDays,
            } as DateTimeRangePickerSlotProps<Dayjs, false>['day'],
            fieldSeparator: {
              color: 'disabled',
            },
            textField: {
              size: 'small',
              sx: { width: 240 },
            },
            field: {
              clearable: true,
            },
            fieldRoot: {
              spacing: 0.5,
            },
          }}
        />
      </LocalizationProvider>

      <DropdownSingleSelect<TzMode>
        id='timezone'
        value={tzMode}
        size='medium'
        buttonWidth={165}
        options={tzModeOptions}
        onChange={(value) => setTzMode(value)}
      />

      {(isNil(timeWindowInDays) || timeWindowInDays >= 1) && (
        <Can
          permission={{
            action: 'view',
            subject: 'virtual-care.event-review.time-shortcuts',
          }}
        >
          <>
            <Button variant='contained' onClick={() => selectPrevDays(1)}>
              Last 24 Hours
            </Button>
            {(isNil(timeWindowInDays) || timeWindowInDays >= 2) && (
              <Button variant='contained' onClick={() => selectPrevDays(2)}>
                Last 48 Hours
              </Button>
            )}
          </>
        </Can>
      )}
    </Stack>
  );
};

export default DatePicker;
