import { useQuery } from '@tanstack/react-query';

import type { AdminTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { Api } from '../../../../../utility/client';

export const useAlisCommunities = () => useQuery({
    queryKey: ['alis-communities'],
    queryFn: async () => {
      const response = await Api.get<AdminTypes.AlisCommunity[]>(
        '/admin/v1/alis/communities',
      );

      return response.data;
    },
    retry: 1,
  });
