import { TextField, Tooltip } from '@mui/material';
import { Controller } from 'react-hook-form';

import type { Control } from 'react-hook-form';

type Props = {
  id: string;
  label: string;
  control: Control<any, any>;
  disabled?: boolean;
  placeholder?: string;
  tooltip?: string;
};

const ControlledTextField = ({
  id,
  label,
  control,
  disabled = false,
  placeholder,
  tooltip,
}: Props) => (
  <Controller
    name={id}
    control={control}
    render={({ field: { name, value, onChange }, fieldState: { error } }) => (
      <Tooltip
        title={tooltip}
        placement='left'
        disableHoverListener={!tooltip}
        disableInteractive={!tooltip}
        disableFocusListener={!tooltip}
      >
        <TextField
          id={name}
          label={label}
          margin='dense'
          type='text'
          variant='outlined'
          fullWidth
          value={value}
          onChange={onChange}
          disabled={disabled}
          error={Boolean(error)}
          helperText={error?.message}
          placeholder={placeholder}
          InputLabelProps={{ shrink: Boolean(placeholder) || undefined }}
        />
      </Tooltip>
    )}
  />
);

export default ControlledTextField;
