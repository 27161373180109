import type React from 'react';
import { cloneElement, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { sortBy } from 'lodash';
import {
  bindDialog,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import toast from 'react-hot-toast';

import { getAxiosErrorMessage } from '@inspiren-monorepo/util-axios';
import { DomainId } from '@inspiren-monorepo/util-rooms';
import type {
  AdminTypes,
  SelectOptionsTypes,
} from '@inspiren-monorepo/virtual-care/api-contracts';

import { useRoomOptions } from '../../../../../hooks/useRoomOptions';
import LoadingAutocomplete from '../../../../shared/LoadingAutocomplete';
import { assignAlisBed } from '../data-access/assignAlisBed';

type Props = {
  room: AdminTypes.AlisStructureRoom;
  bed: AdminTypes.AlisStructureBed;
  children: React.ReactElement;
};

export const AssignBedModal = ({ room, bed, children }: Props) => {
  const queryClient = useQueryClient();

  const [selectedRoom, setSelectedRoom] =
    useState<SelectOptionsTypes.RoomOption | null>(null);

  const popupState = usePopupState({
    variant: 'dialog',
    popupId: 'connect-to-bed-modal',
  });

  const { data: rooms, isLoading } = useRoomOptions({
    householdId: room.connectedHousehold?.id,
  });

  const { mutate, isPending } = useMutation<
    void,
    unknown,
    {
      alisUnitId: number;
      alisBedId: string;
      roomId: string;
    }
  >({
    mutationFn: async ({ alisUnitId, alisBedId, roomId }) => {
      await assignAlisBed(alisUnitId, alisBedId, roomId);
    },
    onSuccess: async () => {
      toast.success('Alis bed assigned successfully');
      popupState.close();

      await queryClient.invalidateQueries({
        queryKey: ['alis-community-structure'],
      });
    },
    onError: (error: any) => {
      const message =
        getAxiosErrorMessage(error) ??
        `Error assigning bed to room${error ? `: ${error}` : ''}`;

      toast.error(message);
    },
  });

  return (
    <>
      {cloneElement(children, { ...bindTrigger(popupState) })}
      {popupState.isOpen && (
        <Dialog fullWidth maxWidth='xs' {...bindDialog(popupState)}>
          <DialogTitle>
            Connect to existing room
            <IconButton
              aria-label='close'
              onClick={popupState.close}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box sx={{ py: 2 }}>
              <LoadingAutocomplete
                label='Room'
                loading={isLoading}
                size='small'
                id='unit'
                data-testid='unit'
                options={sortBy(
                  rooms,
                  (room) =>
                    room.displayName || DomainId.parse(room.domainId).room,
                )}
                value={selectedRoom}
                getOptionLabel={(room) =>
                  room.displayName ||
                  DomainId.parse(room.domainId).room ||
                  'error'
                }
                isOptionEqualToValue={(o, v) => o.id === v.id}
                onChange={(_e, newValue) => setSelectedRoom(newValue)}
                sx={{ width: '100%' }}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              variant='outlined'
              sx={{ fontSize: 14, width: '140px' }}
              onClick={popupState.close}
            >
              Cancel
            </Button>
            <LoadingButton
              variant='contained'
              sx={{ fontSize: 14, width: '140px' }}
              onClick={() =>
                mutate({
                  alisUnitId: room.id,
                  alisBedId: bed.id,
                  roomId: selectedRoom?.id as string,
                })
              }
              disabled={!selectedRoom}
              loading={isPending}
            >
              Confirm
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
