import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from '@mui/material';
import {
  History as HistoryIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import type {
  GridCellClassNamePropType,
  GridColDef,
} from '@mui/x-data-grid-pro';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { format, parseISO } from 'date-fns';
import {
  bindDialog,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';

import { dateTimeFormat } from '../../../../../utility/helpers/time';
import TableError from '../../TableError';
import { useBasestationHistory } from '../hooks/useBasestationHistory';

const unassignedClassName: GridCellClassNamePropType = (params) =>
  params.value === 'unassigned' ? 'basestations unassigned' : '';

const columns: GridColDef[] = [
  {
    field: 'time',
    headerName: 'Date',
    width: 200,
    type: 'dateTime',
    valueGetter: (value) => parseISO(value),
    valueFormatter: (value) => format(value, dateTimeFormat),
  },
  {
    field: 'buildingName',
    headerName: 'Building',
    width: 200,
    cellClassName: unassignedClassName,
  },
  {
    field: 'unitName',
    headerName: 'Unit',
    width: 170,
    cellClassName: unassignedClassName,
  },
  {
    field: 'roomName',
    headerName: 'Room',
    width: 100,
    cellClassName: unassignedClassName,
  },
  {
    field: 'user',
    headerName: 'Changed By',
    flex: 1,
  },
];

interface Props {
  basestationId: string;
}

export const AssignmentHistory = ({ basestationId }: Props) => {
  const popupState = usePopupState({
    variant: 'dialog',
    popupId: 'basestation-room-assignment-history-modal',
  });

  const {
    data = [],
    isError,
    isLoading,
  } = useBasestationHistory({
    basestationId,
    enabled: popupState.isOpen,
  });

  return (
    <>
      <Tooltip title='Basestation Room Assignment History' placement='top'>
        <IconButton {...bindTrigger(popupState)}>
          <HistoryIcon />
        </IconButton>
      </Tooltip>

      {popupState.isOpen && (
        <Dialog fullWidth maxWidth='md' {...bindDialog(popupState)}>
          <DialogTitle>
            Basestation Room Assignment History
            <IconButton
              aria-label='close'
              onClick={popupState.close}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            {isError ? (
              <TableError />
            ) : (
              <DataGridPro
                columns={columns}
                rows={data}
                loading={isLoading}
                getRowId={(row) => `${row.basestation}-${row.time}`}
                pagination
                initialState={{
                  pagination: { paginationModel: { pageSize: 10 } },
                }}
                pageSizeOptions={[10, 25, 50]}
                rowSelection={false}
                sx={{
                  '& .basestations.unassigned': {
                    color: (theme) => theme.palette.grey[400],
                    fontStyle: 'italic',
                  },
                }}
              />
            )}
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};
