import type { Location, Pose } from '@inspiren-monorepo/shared-types';

const HIDDEN = 'hidden';
const BED_EDGE = 'on bed edge';
const BED = 'in bed';
const CHAIR = 'in chair';
const ROOM = 'in room';
const LYING_ON_FLOOR = 'lying on floor';
const MOVEMENT_IN_ROOM = 'movement in room';
const OUT_OF_VIEW = 'out of view';
const SITTING_IN_ROOM = 'sitting in room';
const STAFF_IN_ROOM = 'staff in room';
const STANDING_IN_ROOM = 'standing in room';
const STANDING = 'standing';
const UNKNOWN = 'unknown';

type PatientStatusText =
  | typeof HIDDEN
  | typeof BED_EDGE
  | typeof BED
  | typeof CHAIR
  | typeof ROOM
  | typeof LYING_ON_FLOOR
  | typeof MOVEMENT_IN_ROOM
  | typeof OUT_OF_VIEW
  | typeof SITTING_IN_ROOM
  | typeof STAFF_IN_ROOM
  | typeof STANDING_IN_ROOM
  | typeof STANDING
  | typeof UNKNOWN;

type LocationAndPose = Record<Location, Record<Pose, PatientStatusText>>;

const locationAndPose: LocationAndPose = {
  bed: {
    hidden: HIDDEN,
    lying: BED,
    movement: BED,
    none: OUT_OF_VIEW,
    sitting: BED,
    staff: STAFF_IN_ROOM,
    standing: BED,
    unknown: BED,
  },
  bed_edge: {
    hidden: HIDDEN,
    lying: BED_EDGE,
    movement: BED_EDGE,
    none: OUT_OF_VIEW,
    sitting: BED_EDGE,
    staff: STAFF_IN_ROOM,
    standing: BED_EDGE,
    unknown: BED_EDGE,
  },
  chair: {
    hidden: HIDDEN,
    lying: CHAIR,
    movement: CHAIR,
    none: OUT_OF_VIEW,
    sitting: CHAIR,
    staff: STAFF_IN_ROOM,
    standing: CHAIR,
    unknown: CHAIR,
  },
  exit: {
    hidden: HIDDEN,
    lying: LYING_ON_FLOOR,
    movement: MOVEMENT_IN_ROOM,
    none: OUT_OF_VIEW,
    sitting: SITTING_IN_ROOM,
    staff: STAFF_IN_ROOM,
    standing: STANDING,
    unknown: UNKNOWN,
  },
  none: {
    hidden: OUT_OF_VIEW,
    lying: OUT_OF_VIEW,
    movement: OUT_OF_VIEW,
    none: OUT_OF_VIEW,
    sitting: OUT_OF_VIEW,
    staff: STAFF_IN_ROOM,
    standing: OUT_OF_VIEW,
    unknown: OUT_OF_VIEW,
  },
  outside: {
    hidden: HIDDEN,
    lying: OUT_OF_VIEW,
    movement: OUT_OF_VIEW,
    none: OUT_OF_VIEW,
    sitting: OUT_OF_VIEW,
    staff: STAFF_IN_ROOM,
    standing: OUT_OF_VIEW,
    unknown: OUT_OF_VIEW,
  },
  room: {
    hidden: HIDDEN,
    lying: LYING_ON_FLOOR,
    movement: MOVEMENT_IN_ROOM,
    none: OUT_OF_VIEW,
    sitting: SITTING_IN_ROOM,
    staff: STAFF_IN_ROOM,
    standing: STANDING_IN_ROOM,
    unknown: ROOM,
  },
  sofa: {
    hidden: HIDDEN,
    lying: CHAIR,
    movement: CHAIR,
    none: OUT_OF_VIEW,
    sitting: CHAIR,
    staff: STAFF_IN_ROOM,
    standing: CHAIR,
    unknown: CHAIR,
  },
  staff: {
    hidden: STAFF_IN_ROOM,
    lying: STAFF_IN_ROOM,
    movement: STAFF_IN_ROOM,
    none: STAFF_IN_ROOM,
    sitting: STAFF_IN_ROOM,
    staff: STAFF_IN_ROOM,
    standing: STAFF_IN_ROOM,
    unknown: STAFF_IN_ROOM,
  },
  unknown: {
    hidden: HIDDEN,
    lying: UNKNOWN,
    movement: MOVEMENT_IN_ROOM,
    none: UNKNOWN,
    sitting: SITTING_IN_ROOM,
    staff: STAFF_IN_ROOM,
    standing: STANDING,
    unknown: UNKNOWN,
  },
};

export const formatPatientStatus = (
  location: Location | undefined = UNKNOWN,
  pose: Pose | undefined = UNKNOWN,
): PatientStatusText => locationAndPose[location]?.[pose] ?? UNKNOWN;
