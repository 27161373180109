import { IconButton, Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import toast from 'react-hot-toast';

type Props = {
  valueToCopy: string;
} & Parameters<typeof IconButton>[0];

export const CopyButton = ({ valueToCopy, ...rest }: Props) => {
  const onClick = () => {
    navigator.clipboard.writeText(valueToCopy);
    toast.success('Copied to clipboard', { duration: 500 });
  };

  if (!navigator?.clipboard?.writeText) {
    // some old browsers do not support that action
    return null;
  }

  return (
    <Tooltip title='Copy to clipboard' placement='top'>
      <IconButton {...rest} onClick={onClick}>
        <ContentCopyIcon />
      </IconButton>
    </Tooltip>
  );
};
