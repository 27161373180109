import { Stack, Typography } from '@mui/material';
import { green, grey } from '@mui/material/colors';

import type { AdminTypes } from '@inspiren-monorepo/virtual-care/api-contracts';


type Props = {
  hall: AdminTypes.AlisStructureHall;
};

export const AlisHallRow = ({ hall }: Props) => (
    <Stack direction='row' spacing={2}>
      <Stack
        direction='row'
        spacing={2}
        sx={{ p: 2, backgroundColor: green[50], borderRadius: 2, flex: 1 }}
      >
        <Stack sx={{ flex: 1 }}>
          <Typography
            variant='overline'
            sx={{ lineHeight: 1.4, color: grey[500] }}
          >
            Hall:
          </Typography>
          <Typography variant='h5'>{hall.name || 'Main'}</Typography>
        </Stack>
      </Stack>
      <Stack
        justifyContent='center'
        alignItems='flex-end'
        sx={{ p: 2, flex: 1 }}
      />
    </Stack>
  );
