import { IconButton, Menu, MenuItem } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import type { GridCsvExportApi } from '@mui/x-data-grid-pro';
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';

import { sendAmpEvent } from '../../../utility/amplitude';

interface Props {
  id: string;
  exportCsv?: GridCsvExportApi['exportDataAsCsv'];
  disabled?: boolean;
}

const TableMenu = ({ id, exportCsv, disabled }: Props) => {
  const popupState = usePopupState({
    variant: 'popover',
    popupId: `${id}-menu`,
  });

  return (
    <>
      <IconButton
        {...bindTrigger(popupState)}
        sx={{ mr: 1, height: 32, width: 32 }}
        size='large'
        disabled={disabled}
      >
        <MoreVert fontSize='medium' />
      </IconButton>
      <Menu {...bindMenu(popupState)}>
        <MenuItem
          disabled={!exportCsv}
          onClick={() => {
            exportCsv?.();
            sendAmpEvent('CSV Downloaded');
          }}
        >
          Export as CSV
        </MenuItem>
      </Menu>
    </>
  );
};

export default TableMenu;
