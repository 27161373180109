import { Box } from '@mui/material';
import BatteryGauge from 'react-battery-gauge';

type Props = {
  value: number;
  size?: number;
  fontSize?: number;
};

export const Battery = ({ value, size = 45, fontSize = 24 }: Props) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      height: '100%',
    }}
  >
    <BatteryGauge
      value={value * 100}
      size={size}
      customization={{
        batteryBody: {
          strokeWidth: 2,
          cornerRadius: 6,
          fill: 'none',
          strokeColor: '#111',
        },
        batteryCap: {
          fill: 'none',
          strokeWidth: 2,
          strokeColor: '#111',
          cornerRadius: 2,
          capToBodyRatio: 0.4,
        },
        batteryMeter: {
          fill: 'green',
          lowBatteryValue: 15,
          lowBatteryFill: 'red',
          outerGap: 1,
          noOfCells: 1, // more than 1, will create cell battery
          interCellsGap: 1,
        },
        readingText: {
          lightContrastColor: '#111',
          darkContrastColor: '#fff',
          lowBatteryColor: 'red',
          fontSize,
          showPercentage: true,
        },
      }}
    />
  </Box>
);
