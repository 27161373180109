import { useCallback, useMemo } from 'react';
import { Button, Stack, Typography, Menu, MenuItem, Grid } from '@mui/material';
import CableIcon from '@mui/icons-material/Cable';
import { LoadingButton } from '@mui/lab';
import { grey } from '@mui/material/colors';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { some } from 'lodash';
import { useConfirm } from 'material-ui-confirm';
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import toast from 'react-hot-toast';

import { getAxiosErrorMessage } from '@inspiren-monorepo/util-axios';
import type { AdminTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { AddBuildingModal } from './AddBuildingModal';
import { AssignCommunityModal } from './AssignCommunityModal';

import { unassignAlisCommunity } from '../data-access/unassignAlisCommunity';

type Props = {
  community: AdminTypes.AlisCommunity;
  communityStructure: AdminTypes.AlisCommunityStructure;
};

export const AlisCommunityRow = ({ community, communityStructure }: Props) => {
  const queryClient = useQueryClient();

  const popupState = usePopupState({
    variant: 'popover',
    popupId: `${community.communityId}`,
  });

  const confirm = useConfirm();

  const { mutate, isPending } = useMutation<
    void,
    unknown,
    {
      communityId: number;
      buildingId: string;
    }
  >({
    mutationFn: async ({ communityId, buildingId }) => {
      await unassignAlisCommunity(communityId, buildingId);
    },
    onSuccess: async () => {
      toast.success('Alis community unassigned successfully');
      popupState.close();

      await queryClient.invalidateQueries({
        queryKey: ['alis-community-structure'],
      });
    },
    onError: (error: any) => {
      const message =
        getAxiosErrorMessage(error) ??
        `Error unassigning community from building${error ? `: ${error}` : ''}`;

      toast.error(message);
    },
  });

  const handleUnassign = useCallback(() => {
    confirm({
      title: 'Are you sure?',
      confirmationText: 'Confirm',
      confirmationButtonProps: {
        color: 'primary',
      },
      dialogProps: {
        maxWidth: 'xs',
      },
    }).then(() => {
      mutate({
        communityId: community.communityId,
        buildingId: communityStructure.connectedBuilding?.id as string,
      });
    });
  }, [community, communityStructure, mutate]);

  const canUnassign = useMemo(
    () =>
      !some(
        communityStructure.floors
          .flatMap((floor) => floor.halls)
          .flatMap((hall) => hall.rooms),
        (room) => room.connectedHousehold?.id,
      ),
    [communityStructure],
  );

  return (
    <Stack direction='row' spacing={2} sx={{ mt: 2 }}>
      <Stack
        sx={{
          border: `1px solid ${grey[200]}`,
          flex: 1,
          borderRadius: 2,
          p: 2,
        }}
      >
        <Stack sx={{ flex: 1 }}>
          <Typography
            variant='overline'
            sx={{ lineHeight: 1.4, color: grey[500] }}
          >
            Community:
          </Typography>
          <Stack direction='row' spacing={2}>
            <Typography variant='h4'>{community?.communityName}</Typography>
          </Stack>
          <Grid container spacing={1} sx={{ mt: 1 }}>
            <Grid item xs={6}>
              <Typography component='span' sx={{ color: grey[800] }}>
                <strong>State:</strong> {community?.state}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography component='span' sx={{ color: grey[800] }}>
                <strong>City:</strong> {community?.city}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography component='span' sx={{ color: grey[800] }}>
                <strong>Time Zone:</strong> {community?.timeZone}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography component='span' sx={{ color: grey[800] }}>
                <strong>Zip Code:</strong> {community?.zip}
              </Typography>
            </Grid>
          </Grid>
        </Stack>
      </Stack>
      <Stack
        sx={{
          border: `1px solid ${grey[200]}`,
          flex: 1,
          borderRadius: 2,
          p: 2,
        }}
      >
        <Stack
          direction='row'
          justifyContent='flex-end'
          alignItems={
            communityStructure.connectedBuilding?.id ? 'flex-start' : 'center'
          }
          sx={{ flex: 1 }}
        >
          {!communityStructure.connectedBuilding?.id && (
            <>
              <Button
                startIcon={<CableIcon />}
                variant='contained'
                color='inherit'
                {...bindTrigger(popupState)}
              >
                Connect to Building
              </Button>
              <Menu {...bindMenu(popupState)}>
                <AddBuildingModal community={community}>
                  <MenuItem>Create Building</MenuItem>
                </AddBuildingModal>
                <AssignCommunityModal community={community}>
                  <MenuItem>Connect to Existing Building</MenuItem>
                </AssignCommunityModal>
              </Menu>
            </>
          )}
          {communityStructure.connectedBuilding?.id && (
            <Stack
              direction='row'
              justifyContent='space-between'
              sx={{ flex: 1, width: '100%' }}
            >
              <LoadingButton
                onClick={handleUnassign}
                loading={isPending}
                disabled={!canUnassign}
              >
                Unassign
              </LoadingButton>
              <Stack
                alignItems='flex-end'
                justifyContent='flex-start'
                sx={{ flex: 1 }}
              >
                <Typography
                  variant='overline'
                  sx={{ lineHeight: 1.4, color: grey[500] }}
                >
                  BUILDING:
                </Typography>
                <Stack direction='row' spacing={2}>
                  <Typography variant='h4'>
                    {communityStructure.connectedBuilding.displayName}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};
