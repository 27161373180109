import { useCallback, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';

import { postHousehold } from '../data-access/postHousehold';

export const NewHouseholdNameRegex = /Add "(.+)"/;

type Props = {
  orgId?: string;
  unitId: string;
  onAdd?: (householdId: string) => void;
};

export const useHouseholdChange = ({ orgId, unitId, onAdd }: Props) => {
  const [pending, setPending] = useState(false);
  const queryClient = useQueryClient();

  const handleHouseholdChange = useCallback(
    (household: string | null, onChange: (...event: any[]) => void) => {
      const isAddNew = NewHouseholdNameRegex.test(household || '');

      if (household && isAddNew) {
        setPending(true);
        const householdName = household.match(NewHouseholdNameRegex)?.[1];

        if (householdName && orgId) {
          onChange('');

          postHousehold({
            displayName: householdName,
            unitId,
            orgId,
          })
            .then(async (newHousehold) => {
              queryClient
                .invalidateQueries({
                  queryKey: ['household-options'],
                })
                .then(() => {
                  setPending(false);
                  onAdd?.(newHousehold.id);
                  toast.success('Apartment created');
                });
            })
            .catch((error) => {
              console.error(error);
              toast.error('Error creating apartment');
              setPending(false);
            });
        }
      } else {
        onChange(household);
      }
    },
    [unitId, orgId, onAdd],
  );

  return { handleHouseholdChange, pending };
};
