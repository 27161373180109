import { Button, Link } from '@mui/material';
import { Upload } from '@mui/icons-material';

interface Props {
  itemName: 'basestations' | 'rooms' | 'users';
  urlSuffix?: string;
  disabled?: boolean;
}

const BulkImportLink = ({ itemName, urlSuffix, disabled }: Props) => {
  const href = urlSuffix
    ? `/admin/${itemName}/import/${urlSuffix}`
    : `/admin/${itemName}/import`;

  return (
    <Button
      disabled={disabled}
      component={Link}
      variant='contained'
      size='small'
      href={href}
      startIcon={<Upload />}
    >
      Bulk Import
    </Button>
  );
};

export default BulkImportLink;
