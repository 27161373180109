import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

import type { SelectOptionsTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { useCurrentUser } from '../HOC/CurrentUserContextProvider';
import { Api } from '../utility/client';

type Props = Omit<
  UseQueryOptions<
    SelectOptionsTypes.HouseholdOption[],
    Error,
    SelectOptionsTypes.HouseholdOption[],
    string[]
  >,
  'queryKey' | 'queryFn'
> & {
  unitId?: string;
};

export const useHouseholdOptions = (props: Props = {}) => {
  const { user } = useCurrentUser();
  const { unitId, ...restProps } = props;

  return useQuery({
    ...restProps,
    queryKey: ['household-options', user?.domainId as string, unitId || ''],

    queryFn: async () => {
      const url = `/selectOptions/v1/households${unitId ? `?unitId=${unitId}` : ''}`;
      const res = await Api.get<SelectOptionsTypes.HouseholdOption[]>(url);

      return res?.data;
    },
  });
};
