import { useEffect, useMemo } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';

import { getRooms } from '../data-access/getRooms';

interface Props {
  includeDeleted?: boolean;
}

export const useRooms = ({ includeDeleted }: Props) => {
  const { data, isError, isFetching, fetchNextPage, hasNextPage } =
    useInfiniteQuery({
      queryKey: ['rooms', includeDeleted],
      queryFn: ({ pageParam }) =>
        getRooms({
          includeDeleted,
          lastKey: pageParam,
        }),
      getNextPageParam: (lastPage) => lastPage.lastKey,
      initialPageParam: undefined as string | undefined,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    });

  const rooms = useMemo(
    () => data?.pages.flatMap((page) => page.rooms) ?? [],
    [data],
  );

  useEffect(() => {
    if (hasNextPage) fetchNextPage();
  }, [data]);

  return { rooms, isError, isLoading: hasNextPage || isFetching };
};
