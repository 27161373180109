import { useParams } from 'react-router-dom';

import { FallVideo } from '../components/FallVideo/FallVideo';

const FallVideoScreen = () => {
  const { exportId } = useParams<{ exportId: string }>();

  return <FallVideo exportId={exportId as string} />;
};

export default FallVideoScreen;
