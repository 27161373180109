import type { GridRowProps } from '@mui/x-data-grid-pro';
import { GridRow, useGridApiContext } from '@mui/x-data-grid-pro';

import ErrorTooltip from './ErrorTooltip';

import type { Importable } from '../types/importable';

type Props<TFieldTypes> = GridRowProps & {
  row: Importable<TFieldTypes>;
};

const ImportTableRow = <TFieldTypes,>(props: Props<TFieldTypes>) => {
  const {
    row: {
      $meta: { validationError },
      ...fields
    },
    rowId,
  } = props;

  const apiRef = useGridApiContext();
  const rowMode = apiRef.current?.getRowMode(rowId);

  const isDirty = (() => {
    if (rowMode !== 'edit') {
      return false;
    }

    return Object.entries(fields).some(([field, value]) => {
      const editCellMeta = apiRef.current?.unstable_getEditCellMeta(
        rowId,
        field,
      ) as { value: string } | null;

      return editCellMeta?.value !== value;
    });
  })();

  return (
    <ErrorTooltip
      title={(!isDirty && validationError) || ''}
      placement='left'
      arrow
      sx={{ maxWidth: '250px' }}
    >
      <div className={!isDirty && validationError ? 'import-row-error' : ''}>
        <GridRow {...props} />
      </div>
    </ErrorTooltip>
  );
};

export default ImportTableRow;
