import type { DataField } from '../../../types/DataFields';
import type { RolePermissionFieldTypes } from '../types/RolePermissionFieldTypes';

export const buildPermissionDataField = (
  field: keyof RolePermissionFieldTypes & string,
  label: string,
): DataField<RolePermissionFieldTypes> => ({
  field,
  label,
  width: 150,
  hideOnAdd: true,
  type: 'boolean',
});
