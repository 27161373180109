import { Stack, Typography } from '@mui/material';
import { CopyAll } from '@mui/icons-material';
import { kebabCase } from 'lodash';
import toast from 'react-hot-toast';

interface Props {
  label: string;
  value: string;
  isAdmin: boolean;
}

export const RoomMenuCopyableRoomDetail = ({ label, value, isAdmin }: Props) =>
  isAdmin && (
    <Stack direction='column' sx={{ width: '100%' }}>
      <Typography variant='subtitle2'>{label}</Typography>
      <Stack direction='row'>
        <Typography
          variant='body2'
          data-testid={`room-menu-${kebabCase(label)}`}
          sx={{ flex: 1 }}
        >
          {value}
        </Typography>
        <CopyAll
          fontSize='small'
          sx={{ cursor: 'pointer' }}
          onClick={() => {
            navigator.clipboard.writeText(value);
            toast.success(`Copied ${label} to clipboard`);
          }}
        />
      </Stack>
    </Stack>
  );
