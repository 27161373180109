import { Api } from '../../../../../utility/client';

interface Response {
  mobilePhone: string;
  mobilePhoneVerified: boolean;
  smsWithVideoLink: boolean;
}

type Params = {
  mobilePhone: string | null;
  smsWithVideoLink: boolean;
};

export const updatePhoneNumber = async ({
  mobilePhone,
  smsWithVideoLink,
}: Params) => {
  const res = await Api.put<Response>('/v1/user/mobilePhone', {
    mobilePhone,
    smsWithVideoLink,
  });

  return res.data;
};
