import { type MutableRefObject, useEffect } from 'react';
import { Box, Stack, Typography, lighten, useTheme } from '@mui/material';
import { type GridApiPro, useGridApiRef } from '@mui/x-data-grid-pro';
import * as Sentry from '@sentry/react';
import { isNil } from 'lodash';

import { DataGridBase } from './DataGridBase';
import { MarkSwitch } from './MarkSwitch';
import { useNotificationsColumns } from './Notifications/hooks/useNotificationsColumns';
import TableMenu from './TableMenu';
import { useIsRowActive } from './hooks/useIsRowActive';

import { sendAmpEvent } from '../../../utility/amplitude';
import { useNotifications } from '../hooks/useNotifications';
import { useEventReviewStoreShallow } from '../store/EventReviewStore';

interface Props {
  showDate?: boolean;
  showRowMenu: boolean;
}

const NotificationsTable = ({ showDate = false, showRowMenu }: Props) => {
  const { images, showNotifMarks, setShowNotifMarks, viewMode } =
    useEventReviewStoreShallow([
      'images',
      'showNotifMarks',
      'setShowNotifMarks',
      'viewMode',
    ]);

  const columns = useNotificationsColumns({ showRowMenu, showDate });

  const { data: notifications, isLoading, isError } = useNotifications();

  const notifsExist = notifications && notifications.length > 0;
  const showToggle = notifsExist && !isNil(images) && images.length > 0;

  const apiRef = useGridApiRef();

  useEffect(() => {
    if (viewMode === 'admin') {
      apiRef.current?.setSortModel([{ field: 'createdAt', sort: 'asc' }]);
    } else if (viewMode === 'staff') {
      apiRef.current?.setSortModel([{ field: 'promotedOn', sort: 'asc' }]);
    }
  }, [viewMode]);

  const isRowActive = useIsRowActive();

  const theme = useTheme();

  const activeRowBackgroundColor = lighten(
    theme.palette.notificationEvent.light,
    0.6,
  );

  return (
    <Stack sx={{ height: '100%', minHeight: 0 }}>
      <Box
        sx={{ display: 'flex', alignItems: 'center' }}
        data-testid='eventreview-notificationstable-header'
      >
        <Typography variant='h5' component='h3' p={1.5} mr='auto'>
          Notifications
        </Typography>
        <MarkSwitch
          id='notifications-mark-switch'
          checked={showToggle ? showNotifMarks : false}
          disabled={!showToggle}
          onChange={() => {
            setShowNotifMarks(!showNotifMarks);
            sendAmpEvent('Toggled Notif Events', { toggle: showNotifMarks });
          }}
          customColor={(innerTheme) =>
            innerTheme.palette.notificationEvent.main
          }
        />
        <TableMenu
          id='notifs'
          exportCsv={apiRef?.current?.exportDataAsCsv}
          disabled={!notifsExist}
        />
      </Box>
      <DataGridBase
        apiRef={apiRef as MutableRefObject<GridApiPro>}
        noRowsLabel='No notifications in range'
        rows={notifications}
        columns={columns}
        loading={isLoading}
        error={isError}
        activeRowBackgroundColor={activeRowBackgroundColor}
        getRowClassName={({ row }) =>
          isRowActive(
            viewMode === 'admin' ? row.createdAt : row.promotedOn,
            row.resolvedAt,
          )
            ? 'row-active--true'
            : ''
        }
        defaultSortField='promotedOn'
      />
    </Stack>
  );
};

export default Sentry.withProfiler(NotificationsTable);
