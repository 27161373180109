import { useMemo } from 'react';
import { skipToken, useQuery } from '@tanstack/react-query';
import { intervalToDuration } from 'date-fns';

import { useOrganizationRolesMap } from '../../../hooks/useOrganizationRolesMap';
import { getStaffEvents } from '../data-access/getStaffEvents';
import { useEventReviewStoreShallow } from '../store/EventReviewStore';

import type { RoomActivityRow } from '../DetailsPanel/RoomActivity/types/RoomActivityRow';

export const useStaffEvents = () => {
  const { selectedRoom, startDate, endDate, viewMode } =
    useEventReviewStoreShallow([
      'selectedRoom',
      'startDate',
      'endDate',
      'viewMode',
    ]);

  const roomId = selectedRoom?.domainId;
  const orgId = import.meta.env.VITE_ORG_ID;
  const { data: roleMap = {} } = useOrganizationRolesMap(orgId);

  const { data, isLoading, isError } = useQuery({
    queryKey: [
      'eventReview',
      'staffEvents',
      roomId,
      startDate,
      endDate,
      viewMode,
    ],
    queryFn:
      roomId && startDate && endDate
        ? async () => {
          const events = await getStaffEvents(
            roomId,
            startDate.toISOString(),
            endDate.toISOString(),
            viewMode,
          );

          return events?.map((item) => {
            if (!item.staffFirstName) {
              return {
                ...item,
                staffFirstName: 'Staff',
                staffLastName: 'Member',
              };
            }

            return item;
          });
        }
        : skipToken,
  });

  const staffData: RoomActivityRow[] = useMemo(() => {
    if (!data) return [];

    return data?.map((event) => ({
      event: 'StaffEvent',
      time: event.timeIn ? new Date(event.timeIn) : null,
      timeOut: event.timeOut ? new Date(event.timeOut) : null,
      name:
        event.staff === 'Unassigned'
          ? 'Unassigned'
          : `${event.staffFirstName} ${event.staffLastName} - ${roleMap[event.staffRoleId || ''].displayName}`,
      duration:
        event.timeIn && event.timeOut
          ? intervalToDuration({
            start: new Date(event.timeIn),
            end: new Date(event.timeOut),
          })
          : null,
      id: `${event.room}-${event.timeIn}-${event.beacon || ''}`,
    }));
  }, [data, roleMap]);

  return { data: staffData, isLoading, isError };
};
