import type { AdminTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { Api } from '../../../utility/client';

export const putFloor = async (floor: AdminTypes.FloorUpdateDto) => {
  try {
    const res = await Api.put<AdminTypes.FloorDto>(
      `/admin/v1/floors/${floor.id}`,
      floor,
    );

    return res && res.data;
  } catch (error) {
    console.error('error adding floor');
    throw error;
  }
};
