import { cloneElement, useState } from 'react';
import { Button } from '@mui/material';
import { Add } from '@mui/icons-material';
import { isNil } from 'lodash';

import FormModalBase from './FormModalBase';

import type { OnSubmitFormModal, RenderFormModal } from './FormModalBase';
import type {
  DataFieldValue,
  DataFields,
  FieldTypes,
} from '../types/DataFields';

type Props<F extends FieldTypes> = {
  itemName: string;
  fields: DataFields<F>;
  initialValues?: Partial<F>;
  onSubmit?: OnSubmitFormModal<F>;
  loading?: boolean;
  error?: string | null;
  onOpen?: () => void;
  disabled?: boolean;
  renderModal?: RenderFormModal<F>;
  children?: React.ReactElement;
};

const AddModal = <F extends FieldTypes>({
  itemName,
  fields,
  initialValues,
  onSubmit,
  loading = false,
  error = null,
  onOpen,
  disabled = false,
  renderModal,
  children,
}: Props<F>) => {
  const [open, setOpen] = useState(false);

  // Set initial values to empty strings if unset in FieldTypes
  const formInitialValues = fields.reduce((obj, { field, initialValue }) => {
    if (!isNil(initialValues?.[field as keyof F])) {
      return { ...obj, [field]: initialValues[field as keyof F] };
    }

    let value: DataFieldValue = '';

    // Checking for undefined here because initialValue may be
    // manually set to false or 0
    if (initialValue !== undefined) value = initialValue;

    return { ...obj, [field]: value };
  }, {}) as F;

  const handleOpen = () => {
    if (onOpen) onOpen();
    setOpen(true);
  };

  return (
    <>
      {children ? (
        cloneElement(children, { onClick: handleOpen })
      ) : (
        <Button
          variant='contained'
          size='small'
          onClick={handleOpen}
          startIcon={<Add />}
          disabled={disabled}
        >
          Add {itemName}
        </Button>
      )}
      <FormModalBase<F>
        type='add'
        initialValues={formInitialValues}
        itemName={itemName}
        fields={fields}
        open={open}
        handleClose={() => setOpen(false)}
        loading={loading}
        error={error}
        onSubmit={async (data) => {
          if (onSubmit) return onSubmit(data);
        }}
        renderModal={renderModal}
      />
    </>
  );
};

export default AddModal;
