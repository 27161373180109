import { useCallback, useMemo } from 'react';
import {
  Autocomplete,
  CircularProgress,
  createFilterOptions,
  TextField,
} from '@mui/material';
import {
  type GridRenderEditCellParams,
  type GridValidRowModel,
  useGridApiContext,
} from '@mui/x-data-grid-pro';

import { useHouseholdOptions } from '../../../../../hooks/useHouseholdOptions';
import { NewHouseholdNameRegex } from '../hooks/useHouseholdChange';

type Props = GridRenderEditCellParams<GridValidRowModel, string> & {
  useDomainId?: boolean;
};

const filter = createFilterOptions<string>();

export const ImportHouseholdSelect = (props: Props) => {
  const apiRef = useGridApiContext();

  const { field, value, row, ...params } = props;
  const { unitId } = row;
  const { id, error: validationError, disabled } = params;

  const { isLoading, data: households } = useHouseholdOptions({ unitId });

  const householdsNames = useMemo(
    () => households?.map((household) => household.displayName) || [],
    [households],
  );

  const onChange = useCallback(
    (householdDisplayName: string) => {
      apiRef.current?.setEditCellValue({
        id,
        field,
        value:
          householdDisplayName.match(NewHouseholdNameRegex)?.[1] ??
          householdDisplayName,
      });
    },
    [apiRef],
  );

  return (
    <Autocomplete
      disabled={!unitId || disabled}
      data-testid={field}
      options={householdsNames}
      loading={isLoading}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;

        const isExisting = householdsNames.includes(inputValue);

        if (inputValue !== '' && !isExisting) {
          filtered.push(`Add "${inputValue}"`);
        }

        return filtered;
      }}
      freeSolo
      value={value || null}
      isOptionEqualToValue={(o, v) => o === v}
      onChange={(_e, b) => onChange(b || '')}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? (
                  <CircularProgress color='inherit' size={18} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          label=''
          placeholder='Apartment'
          sx={{ margin: 0 }}
          error={Boolean(validationError)}
        />
      )}
      fullWidth
    />
  );
};
