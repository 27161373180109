import { useMemo, type MutableRefObject } from 'react';
import { Slider, lighten } from '@mui/material';

import SliderMark from './SliderMark';

import { useTimestamp } from '../hooks/useTimestamp';
import { useTimezone } from '../hooks/useTimezone';
import { useEventReviewStoreShallow } from '../store/EventReviewStore';

import type { MarkData } from '../../../../types';

type Props = {
  disabled: boolean;
  marks: MarkData[];
  maxPosition: number | null;
  railRef: MutableRefObject<HTMLSpanElement | null>;
  spacePerMark: number | null;
};

const ProgressBar = ({
  disabled,
  marks,
  maxPosition,
  railRef,
  spacePerMark,
}: Props) => {
  const { images, position, setPosition } = useEventReviewStoreShallow([
    'images',
    'position',
    'setPosition',
  ]);

  const timestamp = useTimestamp();
  const { formatInTimezone } = useTimezone({ showDate: true, showYear: true });

  const timeString = timestamp ? formatInTimezone(timestamp) : '';

  const bufferPercent = useMemo(() => {
    const bufferPosition = images?.findIndex((img) => !img.url) ?? 0;

    return maxPosition && bufferPosition >= 0
      ? `${(bufferPosition / maxPosition) * 100}%`
      : '100%';
  }, [images, maxPosition]);

  return (
    <Slider
      data-testid='eventreview-imageplayer-progressbar'
      min={0}
      max={maxPosition || 1}
      value={position}
      marks={marks}
      onChange={(_e, newValue) => setPosition(+newValue)}
      valueLabelDisplay={timestamp ? 'on' : 'off'}
      valueLabelFormat={() => timeString}
      getAriaValueText={() => timeString}
      disabled={disabled}
      track={bufferPercent === '0%' || disabled ? false : 'normal'}
      slots={{
        mark: SliderMark,
        markLabel: () => null,
      }}
      slotProps={{
        track: {
          style: {
            width: bufferPercent,
            transition: 'width 2s cubic-bezier(0.18, 0.84, 0, 0.96)',
          },
        },
        rail: { ref: railRef },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        mark: { spacePerMark } as any,
      }}
      sx={{
        '& .MuiSlider-rail': {
          color: 'grey.400',
        },
        '& .MuiSlider-track': {
          color: (theme) => lighten(theme.palette.primary.light, 0.5),
        },
        '& .MuiSlider-valueLabel': {
          color: '#000',
          backgroundColor: '#fff',
          boxShadow: (theme) => theme.shadows[4],
        },
      }}
    />
  );
};

export default ProgressBar;
