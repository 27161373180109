import { useEffect, useMemo } from 'react';
import { Divider, Stack, Toolbar } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { DomainId } from '@inspiren-monorepo/util-rooms';

import DatePicker from './DatePicker';
import { AdminViewToggle } from './EventToolbar/AdminViewToggle';
import { getOrg } from './data-access/getOrg';
import { getRoomsForUnit } from './data-access/getRoomsForUnit';
import { getUnits } from './data-access/getUnits';
import { useUpdateSelection } from './hooks/useUpdateSelection';
import { useUpdateUrlParams } from './hooks/useUpdateURLParams';
import { useEventReviewStoreShallow } from './store/EventReviewStore';

import { useIsAdmin } from '../../hooks/useIsAdmin';
import { sendAmpEvent } from '../../utility/amplitude';
import { sortUnitDropdown } from '../../utility/helpers/sortUnitDropdown';
import LoadingAutocomplete from '../shared/LoadingAutocomplete';

const EventToolbar = () => {
  const { selectedUnit, selectedRoom, startDate, endDate } =
    useEventReviewStoreShallow([
      'selectedUnit',
      'selectedRoom',
      'startDate',
      'endDate',
    ]);

  const { updateSelectedUnit, updateSelectedRoom } = useUpdateSelection();

  const { isAdmin } = useIsAdmin();
  const awsOrg = import.meta.env.VITE_ORG_ID;

  const { isLoading: orgLoading, data: org } = useQuery({
    queryKey: ['eventReview', 'org', awsOrg],
    queryFn: () => getOrg(awsOrg),
  });

  const { isLoading: unitsLoading, data: units } = useQuery({
    queryKey: ['eventReview', 'units'],
    queryFn: getUnits,
  });

  const unitId = selectedUnit && selectedUnit.domainId;

  const { isFetching: roomsLoading, data: rooms } = useQuery({
    queryKey: ['eventReview', 'rooms', unitId],
    queryFn: () => (unitId ? getRoomsForUnit(unitId) : Promise.resolve([])),
    enabled: unitId !== undefined,
  });

  useEffect(() => {
    if (selectedRoom && startDate && endDate) {
      sendAmpEvent('Date Selection', {
        room: selectedRoom.domainId,
        start: startDate,
        end: endDate,
      });
    }
  }, [selectedRoom, startDate, endDate]);

  const filteredUnits = useMemo(
    () =>
      org
        ? sortUnitDropdown(
            units?.filter(
              (unit) => unit.domainId.includes(`${org.id}-`) && !unit.hide,
            ),
          )
        : [],
    [org, units],
  );

  const filteredRooms = useMemo(
    () =>
      selectedUnit && rooms
        ? rooms?.sort((a, b) => a.displayName.localeCompare(b.displayName))
        : [],
    [selectedUnit, rooms],
  );

  useUpdateUrlParams({
    units,
    filteredRooms,
    unitsLoading,
    roomsLoading,
  });

  return (
    <Toolbar>
      <Stack
        direction='row'
        gap={2}
        flexWrap='wrap'
        justifyContent='center'
        alignItems='center'
        width='100%'
        p={2}
      >
        <LoadingAutocomplete
          label='Unit'
          loading={unitsLoading || orgLoading}
          size='small'
          id='unit'
          data-testid='unit'
          options={filteredUnits}
          getOptionLabel={(option) =>
            `${option.buildingDisplayName || option.address} ${
              option.displayName || option.name
            }`
          }
          renderOption={(props, option) => (
            <li {...props}>{option.displayName || option.name}</li>
          )}
          groupBy={(option) => option.buildingDisplayName || option.address}
          value={selectedUnit}
          isOptionEqualToValue={(o, v) => o.id === v.id}
          onChange={(_e, newValue) => updateSelectedUnit(newValue)}
          sx={{ minWidth: 285 }}
        />

        <LoadingAutocomplete
          label='Room'
          loading={roomsLoading && Boolean(selectedUnit)}
          size='small'
          id='room'
          data-testid='room'
          options={filteredRooms}
          getOptionLabel={(option) =>
            option.displayName || DomainId.parse(option.domainId).room || ''
          }
          value={selectedRoom}
          isOptionEqualToValue={(o, v) => o.domainId === v.domainId}
          onChange={(_e, newValue) => updateSelectedRoom(newValue)}
          disabled={!selectedUnit}
          sx={{ minWidth: 180 }}
        />

        <Divider
          sx={{
            width: '100%',
            border: 'none',
            display: { xs: 'block', sm: 'none' },
          }}
        />

        <DatePicker />

        {isAdmin && <AdminViewToggle />}
      </Stack>
    </Toolbar>
  );
};

export default EventToolbar;
