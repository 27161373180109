import { useMemo } from 'react';
import { skipToken, useQuery } from '@tanstack/react-query';
import { intervalToDuration } from 'date-fns';

import { UserEventType } from '@inspiren-monorepo/shared-types';

import { useOrganizationRolesMap } from '../../../hooks/useOrganizationRolesMap';
import { getUserEvents } from '../data-access/getUserEvents';
import { useEventReviewStoreShallow } from '../store/EventReviewStore';

import type { RoomActivityRow } from '../DetailsPanel/RoomActivity/types/RoomActivityRow';

export const useUserEvents = () => {
  const { selectedRoom, startDate, endDate } = useEventReviewStoreShallow([
    'selectedRoom',
    'startDate',
    'endDate',
  ]);

  const roomId = selectedRoom?.domainId;
  const orgId = import.meta.env.VITE_ORG_ID;
  const { data: roleMap = {} } = useOrganizationRolesMap(orgId);

  const { data, isLoading, isError } = useQuery({
    queryKey: ['eventReview', 'anyEvent', roomId, startDate, endDate],
    queryFn:
      roomId && startDate && endDate
        ? async () => {
            const res = await getUserEvents(
              roomId,
              startDate.toISOString(),
              endDate.toISOString(),
            );

            return res;
          }
        : skipToken,
  });

  const userEventsData: RoomActivityRow[] = useMemo(() => {
    if (!data) return [];

    return data?.map((event) => ({
      event: event.type,
      time: event.time ? new Date(event.time) : null,
      timeOut: event.end ? new Date(event.end) : null,
      name:
        (event?.firstName && event?.lastName
          ? `${event.firstName} ${event.lastName}`
          : event?.user || '') +
        (roleMap[event.roleId || '']?.displayName
          ? ` - ${roleMap[event.roleId || ''].displayName}`
          : ''),
      duration:
        event.type === UserEventType.PrivacyModeOn && event.end
          ? intervalToDuration({
              start: new Date(event.time),
              end: new Date(event.end),
            })
          : null,
      id: `${event.time}-${event.user}`,
    }));
  }, [data, roleMap]);

  return { data: userEventsData, isLoading, isError };
};
