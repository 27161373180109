import { Card, Checkbox, Typography } from '@mui/material';
import { Circle, CircleOutlined } from '@mui/icons-material';

import { DomainId } from '@inspiren-monorepo/util-rooms';
import type { TelesittingTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

type Props = {
  data: TelesittingTypes.Room;
  selected: boolean;
  onClick?: () => void;
};

const RoomSelectCard = ({ data, selected, onClick }: Props) => {
  const { displayName } = data;

  const { floor } = DomainId.parse(data.domainId);

  return (
    <Card
      elevation={0}
      sx={{
        backgroundColor: 'grey.100',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        p: 1.5,
        mr: 1.5,
        mb: 1.5,
        width: 300,
      }}
      onClick={() => onClick?.()}
      data-test-component='RoomSelectCard'
      data-test-roomid={data.domainId}
    >
      <Checkbox
        checked={selected}
        sx={{ mr: 1 }}
        icon={<CircleOutlined />}
        checkedIcon={<Circle />}
      />
      <Typography sx={{ fontWeight: 600, mr: 2, userSelect: 'none' }}>
        Room {displayName}
      </Typography>
      {floor && (
        <Typography sx={{ mr: 1, userSelect: 'none' }}>
          Floor {floor}
        </Typography>
      )}
    </Card>
  );
};

export default RoomSelectCard;
