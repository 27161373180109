import { Alert, AlertTitle, darken, styled } from '@mui/material';
import { DataGridPro, type DataGridProProps } from '@mui/x-data-grid-pro';

interface DataGridBaseProps
  extends Pick<
    DataGridProProps,
    'apiRef' | 'rows' | 'columns' | 'loading' | 'getRowClassName'
  > {
  noRowsLabel: string;
  error?: boolean;
  activeRowBackgroundColor: string;
  defaultSortField: string;
}

const StyledDataGrid = styled(DataGridPro, {
  shouldForwardProp: (prop) => prop !== 'backgroundColor',
})<{ activeRowBackgroundColor: string }>(({ activeRowBackgroundColor }) => ({
  '& .row-active--true': {
    backgroundColor: activeRowBackgroundColor,
    '&:hover': {
      backgroundColor: darken(activeRowBackgroundColor, 0.05),
    },
  },
  border: 'none',
}));

export const DataGridBase = ({
  apiRef,
  rows,
  columns,
  loading,
  error = false,
  noRowsLabel,
  getRowClassName,
  activeRowBackgroundColor,
  defaultSortField,
}: DataGridBaseProps) =>
  error ? (
    <Alert severity='error' sx={{ m: 1 }}>
      <AlertTitle>Error</AlertTitle>
      An error occured while loading the table.
    </Alert>
  ) : (
    <StyledDataGrid
      apiRef={apiRef}
      rows={rows}
      columns={columns}
      loading={loading}
      localeText={{ noRowsLabel }}
      getRowClassName={getRowClassName}
      hideFooter
      rowSelection={false}
      initialState={{
        density: 'compact',
        sorting: {
          sortModel: [{ field: defaultSortField, sort: 'asc' }],
        },
      }}
      slotProps={{
        loadingOverlay: {
          variant: 'linear-progress',
          noRowsVariant: 'skeleton',
        },
      }}
      activeRowBackgroundColor={activeRowBackgroundColor}
    />
  );
