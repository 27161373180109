import { useMemo } from 'react';
import type { GridColDef } from '@mui/x-data-grid-pro';
import { isEmpty } from 'lodash';

import { formatDurationConcise } from '../../../helpers/formatDurationConcise';
import {
  formatRoomActivityType,
  roomActivityTypeMap,
} from '../../../helpers/formatRoomActivityType';
import { gridDurationComparator } from '../../../helpers/gridDurationComparator';
import { useTimezone } from '../../../hooks/useTimezone';
import { useEventReviewStoreShallow } from '../../../store/EventReviewStore';
import TableRowMenu from '../../TableRowMenu';
import { TimeLink } from '../../components/TimeLink';

import type { RoomActivityRow } from '../types/RoomActivityRow';

interface Params {
  showRowMenu: boolean;
  showDate: boolean;
}

export const useRoomActivityColumns = ({ showRowMenu, showDate }: Params) => {
  const { startDate, images, viewMode } = useEventReviewStoreShallow([
    'startDate',
    'images',
    'viewMode',
  ]);

  const { formatInTimezone } = useTimezone({ showDate });

  const columns = useMemo(() => {
    const columnDefinitions: (GridColDef<RoomActivityRow> | false)[] = [
      {
        field: 'event',
        headerName: 'Event',
        type: 'singleSelect',
        valueOptions: Object.entries(roomActivityTypeMap).map(
          ([value, label]) => ({
            value,
            label,
          }),
        ),
        width: 150,
        valueFormatter: (value) => formatRoomActivityType(value),
      },
      {
        field: 'time',
        headerName: 'Time',
        type: 'dateTime',
        width: 170,
        valueFormatter: (value) => formatInTimezone(value),
        renderCell: ({ value, formattedValue }) => (
          <TimeLink
            disabled={
              startDate && value && (value < startDate || isEmpty(images))
            }
            value={value}
            formattedValue={formattedValue}
          />
        ),
      },
      viewMode === 'admin' && {
        field: 'state',
        headerName: 'State',
        width: 240,
      },
      {
        field: 'name',
        headerName: 'Name',
        width: 150,
      },
      {
        field: 'duration',
        headerName: 'Duration',
        width: 180,
        filterable: false,
        valueFormatter: formatDurationConcise,
        sortComparator: gridDurationComparator,
      },
      showRowMenu && {
        field: 'Menu',
        type: 'actions',
        getActions: ({ row }) => [
          <TableRowMenu
            id={row.id}
            eventStart={row.time}
            eventEnd={row.timeOut ?? null}
          />,
        ],
        width: 40,
        filterable: false,
        sortable: false,
        hideable: false,
        pinnable: false,
        disableColumnMenu: true,
      },
    ];

    return columnDefinitions.filter(Boolean) as GridColDef<RoomActivityRow>[];
  }, [startDate, images, showDate, showRowMenu, viewMode, formatInTimezone]);

  return columns;
};
