import { useState } from 'react';
import { Box, Toolbar } from '@mui/material';
import { Navigate, Route, Routes } from 'react-router-dom';

import type { TelesittingTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { useCurrentUser } from '../HOC/CurrentUserContextProvider';
import ProtectedComponent from '../HOC/ProtectedComponent';
import AdminDrawer from '../components/Admin/AdminDrawer';
import DrawerToggleButton from '../components/Admin/DrawerToggleButton';
import { AlisPage } from '../components/Admin/alis/AlisPage/AlisPage';
import { BeaconPage } from '../components/Admin/beacons/BeaconPage/BeaconPage';
import { BeaconsTable } from '../components/Admin/beacons/BeaconsTable/BeaconsTable';
import BasestationsTable from '../components/Admin/tables/BasestationsTable';
import BasestationsImportTable from '../components/Admin/tables/BasestationsTable/BasestationsImportTable';
import BuildingsTable from '../components/Admin/tables/BuildingsTable';
import CareLevelsTable from '../components/Admin/tables/CareLevelsTable';
import ExternalApiTable from '../components/Admin/tables/ExternalApiTable/ExternalApiTable';
import FloorsTable from '../components/Admin/tables/FloorsTable';
import { OrgProfile } from '../components/Admin/tables/OrgsTable/components/OrgProfile';
import RoomsTable from '../components/Admin/tables/RoomsTable';
import RoomsImportTable from '../components/Admin/tables/RoomsTable/RoomsImportTable';
import UnitsTable from '../components/Admin/tables/UnitsTable';
import UsersTable from '../components/Admin/tables/UsersTable';
import UsersImportTable from '../components/Admin/tables/UsersTable/UsersImportTable';
import Footer from '../components/shared/Footer';
import { PageContainer } from '../components/shared/PageContainer';
import { useAccessibleAdminTabs } from '../utility/permissions/useAccessibleAdminTabs';

const AdminScreen = () => {
  const zuiApiUrl = import.meta.env.VITE_ZUI_API_URL;
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);

  const { user } = useCurrentUser();

  const accessibleTabs = useAccessibleAdminTabs(user as TelesittingTypes.User);

  return (
    <>
      <Toolbar />
      <PageContainer>
        <AdminDrawer
          mobileOpen={mobileDrawerOpen}
          onMobileClose={() => setMobileDrawerOpen(false)}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            minWidth: 0,
            p: 2,
            gap: 2,
            backgroundColor: 'white',
          }}
        >
          <DrawerToggleButton onClick={() => setMobileDrawerOpen(true)} />
          <Routes>
            <Route path='basestations'>
              <Route
                index
                element={
                  <ProtectedComponent
                    component={<BasestationsTable />}
                    otherCheck={accessibleTabs.includes('basestations')}
                  />
                }
              />
              <Route
                path='import'
                element={
                  <ProtectedComponent
                    component={<BasestationsImportTable />}
                    otherCheck={accessibleTabs.includes('basestations')}
                  />
                }
              />
            </Route>

            <Route path='general-settings'>
              <Route
                index
                element={
                  <ProtectedComponent
                    component={<OrgProfile />}
                    otherCheck={accessibleTabs.includes('organizations')}
                  />
                }
              />
            </Route>

            <Route
              path='buildings'
              element={
                <ProtectedComponent
                  component={<BuildingsTable />}
                  otherCheck={accessibleTabs.includes('buildings')}
                />
              }
            />

            <Route
              path='floors'
              element={
                <ProtectedComponent
                  component={<FloorsTable />}
                  otherCheck={accessibleTabs.includes('floors')}
                />
              }
            />

            <Route
              path='units'
              element={
                <ProtectedComponent
                  component={<UnitsTable />}
                  otherCheck={accessibleTabs.includes('units')}
                />
              }
            />

            <Route path='rooms'>
              <Route
                index
                element={
                  <ProtectedComponent
                    component={<RoomsTable />}
                    otherCheck={accessibleTabs.includes('rooms')}
                  />
                }
              />

              <Route
                path='import'
                element={
                  <ProtectedComponent
                    component={<RoomsImportTable />}
                    otherCheck={accessibleTabs.includes('rooms')}
                  />
                }
              />
            </Route>

            <Route path='users'>
              <Route
                index
                element={
                  <ProtectedComponent
                    component={<UsersTable />}
                    otherCheck={accessibleTabs.includes('users')}
                  />
                }
              />
              <Route
                path='import'
                element={
                  <ProtectedComponent
                    component={<UsersImportTable />}
                    otherCheck={accessibleTabs.includes('users')}
                  />
                }
              />
            </Route>

            <Route path='beacons'>
              <Route
                index
                element={
                  <ProtectedComponent
                    component={<BeaconsTable />}
                    otherCheck={accessibleTabs.includes('beacons')}
                  />
                }
              />
              <Route
                path=':id'
                element={
                  <ProtectedComponent
                    component={<BeaconPage />}
                    otherCheck={accessibleTabs.includes('beacons')}
                  />
                }
              />
            </Route>

            <Route
              path='care-levels'
              element={
                <ProtectedComponent
                  component={<CareLevelsTable />}
                  otherCheck={accessibleTabs.includes('care-levels')}
                />
              }
            />

            <Route
              path='api-keys'
              element={
                <ProtectedComponent
                  component={<ExternalApiTable />}
                  otherCheck={
                    // the external api is implemented in ZUI
                    !!zuiApiUrl && accessibleTabs.includes('api-keys')
                  }
                />
              }
            />

            <Route
              path='alis'
              element={
                <ProtectedComponent
                  component={<AlisPage />}
                  otherCheck={accessibleTabs.includes('organizations')}
                />
              }
            />

            <Route index element={<Navigate to={accessibleTabs[0] === 'organizations' ? 'general-settings' : accessibleTabs[0]} />} />
          </Routes>
          <Footer />
        </Box>
      </PageContainer>
    </>
  );
};

export default AdminScreen;
