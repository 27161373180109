import { DomainId } from '@inspiren-monorepo/util-rooms';
import type { EventReviewTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

export function updateUrlParams(
  selectedUnit: EventReviewTypes.Unit | null,
  selectedRoom: EventReviewTypes.Room | null,
  startDate: Date | null,
  endDate: Date | null,
) {
  if (!selectedUnit || !selectedRoom || !startDate || !endDate) return;
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  urlParams.set('unit', selectedUnit.domainId);

  urlParams.set('room', DomainId.parse(selectedRoom.domainId).room || '');

  urlParams.set('start', startDate.toISOString());
  urlParams.set('end', endDate.toISOString());
  window.history.pushState({}, '', `${window.location.pathname}?${urlParams}`);
}
