import { useMemo } from 'react';
import { FormControl, FormHelperText } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import type { SelectOptionsTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import SelectUnit from './SelectUnit';

import type { UserFields } from '../helpers/getUsersTableFields';

type Props = {
  options?: SelectOptionsTypes.UnitOption[];
  handleUnitEdit?: () => void;
};

const ControlledSelectedUnit = ({ options, handleUnitEdit }: Props) => {
  const { control, watch } = useFormContext<UserFields>();

  const { levelAccessBuildingIds, levelAccess } = watch();

  const unitOptions = useMemo(
    () =>
      (options || []).filter((unit) => {
        if (levelAccess === 'building') {
          return levelAccessBuildingIds.includes(unit.buildingId);
        }

        return true;
      }),
    [options, levelAccess, levelAccessBuildingIds],
  );

  return (
    <Controller
      name='unitId'
      control={control}
      defaultValue=''
      render={({
        field: { name, value, onChange, disabled },
        fieldState: { error },
      }) => (
        <FormControl fullWidth>
          <SelectUnit
            error={error}
            onChange={(...event) => {
              onChange(...event);
              handleUnitEdit?.();
            }}
            value={value}
            id={name}
            label='Unit'
            options={unitOptions}
            disabled={disabled}
          />
          <FormHelperText error={Boolean(error)}>
            {error?.message}
          </FormHelperText>
        </FormControl>
      )}
    />
  );
};

export default ControlledSelectedUnit;
