import { useRef, useState } from 'react';
import { Tooltip, Typography } from '@mui/material';

type Props = {
  text: string;
};

export const OverflowTextCell = ({ text }: Props) => {
  const ref = useRef<HTMLParagraphElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Tooltip
      title={text}
      arrow
      open={isOpen}
      onOpen={() =>
        ref.current &&
        ref.current.scrollWidth > ref.current.clientWidth &&
        setIsOpen(true)
      }
      onClose={() => setIsOpen(false)}
    >
      <Typography
        ref={ref}
        sx={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          display: 'flex',
          alignItems: 'center',
          height: '100%',
        }}
        variant='body2'
      >
        {text}
      </Typography>
    </Tooltip>
  );
};
