import type { EventReviewTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { Api } from '../../../utility/client';

export const getRoomState = async (
  roomId: string,
  start: string,
  end: string,
) => {
  const res = await Api.get<EventReviewTypes.RoomState[]>(
    `eventReview/v1/roomState/${roomId}/${start}/${end}`,
  );

  return res.data;
};
