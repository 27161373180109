import type { GridComparatorFn } from '@mui/x-data-grid-pro';
import { isNil } from 'lodash';

import type { Duration, DurationUnit } from 'date-fns';

const durationUnits = [
  'years',
  'months',
  'weeks',
  'days',
  'hours',
  'minutes',
  'seconds',
] as DurationUnit[];

export const gridDurationComparator: GridComparatorFn<Duration | null> = (
  v1,
  v2,
) => {
  if (v1 && v2) {
    for (const unit of durationUnits) {
      if (!isNil(v1[unit]) && !isNil(v2[unit]) && v1[unit] !== v2[unit]) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return v1[unit]! - v2[unit]!;
      }
    }
  }

  return v1 ? -1 : 1;
};
