import { useFormContext } from 'react-hook-form';

import ControlledTextField from '../../../modals/ControlledTextField';

import type { RoomFieldTypes } from '../types/RoomFieldTypes';

type Props = {
  field: string;
  label: string;
};

const DisplayName = ({ field, label }: Props) => {
  const { control } = useFormContext<RoomFieldTypes>();

  return <ControlledTextField id={field} label={label} control={control} />;
};

export default DisplayName;
