import type React from 'react';
import { useEffect, useRef, useState } from 'react';
import {
  Button,
  ButtonGroup,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
} from '@mui/material';
import {
  ArrowBackIosNew,
  ArrowForwardIos,
  Check,
  KeyboardArrowDown,
  Pause,
  PlayArrow,
  Speed,
} from '@mui/icons-material';
import TouchRipple from '@mui/material/ButtonBase/TouchRipple';

import ProgressBar from './ProgressBar';

import { useEventReviewStoreShallow } from '../store/EventReviewStore';

import type { MarkData } from '../../../../types';

export type SpeedOption = {
  label: string;
  value: number;
};

type Props = {
  disabled?: boolean;
  maxPosition: number | null;
  playing: boolean;
  onPlayClick: () => void;
  speedOptions: SpeedOption[];
  speed: SpeedOption;
  onSpeedChange: (option: SpeedOption) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rippleRef: React.MutableRefObject<any>;
  onRailWidthChange: (width: number | null) => void;
  marks: MarkData[];
  spacePerMark: number | null;
};

const Playbar = ({
  disabled = false,
  maxPosition,
  playing,
  onPlayClick,
  speedOptions,
  speed,
  onSpeedChange,
  rippleRef,
  onRailWidthChange,
  marks,
  spacePerMark,
}: Props) => {
  const { position, setPosition } = useEventReviewStoreShallow([
    'position',
    'setPosition',
  ]);

  const [speedAnchorEl, setSpeedAnchorEl] = useState<null | HTMLElement>(null);
  const speedOpen = Boolean(speedAnchorEl);

  const railRef = useRef<HTMLSpanElement | null>(null);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      onRailWidthChange(entries[0].contentRect.width);
    });

    if (railRef?.current) observer.observe(railRef.current);

    return () => {
      if (railRef?.current) observer.unobserve(railRef.current);
    };
  }, []);

  return (
    <Stack direction='row' spacing={2} alignItems='center' width='100%' pt={2}>
      <ButtonGroup variant='contained' disabled={disabled}>
        <Button onClick={() => onPlayClick()}>
          {playing ? <Pause /> : <PlayArrow />}
          <TouchRipple ref={rippleRef} center />
        </Button>
        <Button
          onClick={(e) => setSpeedAnchorEl(e.currentTarget)}
          endIcon={<KeyboardArrowDown />}
          id='speed-button'
          aria-controls={speedOpen ? 'speed-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={speedOpen ? 'true' : undefined}
        >
          <Speed />
        </Button>
      </ButtonGroup>
      <Menu
        id='speed-menu'
        anchorEl={speedAnchorEl}
        open={speedOpen}
        onClose={() => setSpeedAnchorEl(null)}
        onClick={() => setSpeedAnchorEl(null)}
        closeAfterTransition
        MenuListProps={{
          'aria-labelledby': 'speed-button',
        }}
      >
        {speedOptions.map((option) => (
          <MenuItem
            key={option.value}
            selected={option.value === speed.value}
            onClick={() => onSpeedChange(option)}
          >
            {option.value === speed.value && (
              <ListItemIcon>
                <Check />
              </ListItemIcon>
            )}
            <ListItemText inset={option.value !== speed.value}>
              {option.label}
            </ListItemText>
          </MenuItem>
        ))}
      </Menu>
      <IconButton onClick={() => setPosition(position - 1)} disabled={disabled}>
        <ArrowBackIosNew fontSize='small' />
      </IconButton>
      <ProgressBar
        disabled={disabled}
        marks={marks}
        spacePerMark={spacePerMark}
        maxPosition={maxPosition}
        railRef={railRef}
      />
      <IconButton onClick={() => setPosition(position + 1)} disabled={disabled}>
        <ArrowForwardIos fontSize='small' />
      </IconButton>
    </Stack>
  );
};

export default Playbar;
