import { useQuery } from '@tanstack/react-query';

import type { AdminTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { Api } from '../../../../../utility/client';

export const useAlisCommunityStructure = (communityId: number | undefined) => useQuery({
    queryKey: ['alis-community-structure', communityId],
    queryFn: async () => {
      const response = await Api.get<AdminTypes.AlisCommunityStructure>(
        `/admin/v1/alis/communities/${communityId}/structure`,
      );

      return response.data;
    },
    enabled: !!communityId,
    retry: 1,
  });
