import { useEffect } from 'react';
import { useTracking } from 'react-tracking';

import type { UserActivityEvent } from '@inspiren-monorepo/activity-tracker';
import { logger } from '@inspiren-monorepo/util-logger';

import { ingestBrowserUserActivity } from './ingestBrowserUserActivity';
import { useBrowserActivityTracker } from './useBrowserActivityTracker';

interface Props {
  app: UserActivityEvent['app'];
  userId?: string;
  activityBaseUrl: string;
  isLocal?: boolean;
}

export const useInitBrowserActivityTracker = ({
  userId,
  activityBaseUrl,
  app,
  isLocal,
}: Props) => {
  const tracker = useBrowserActivityTracker();

  const tracking = useTracking<UserActivityEvent>(
    () => ({
      app,
      userId,
    }),
    {
      dispatch: (event) => {
        if (isLocal) {
          logger.info('Offline tracking event', event);
          return;
        }

        ingestBrowserUserActivity(activityBaseUrl)([event]);
      },
      process: (event) => (event.userId ? event : null), // do not dispatch event if user unavailable
    },
  );

  useEffect(() => {
    // tracking changes on user change -> its first argument is used as useEffect dependencies
    // activity tracker needs to updates its dispatch function accordingly

    tracker.updateOptions({
      dispatch: (event) => tracking.trackEvent(event),
    });
  }, [tracking]);

  useEffect(() => {
    tracker.start();

    return () => {
      tracker.stop();
    };
  }, []);
};
