import { Fragment, useEffect, useState } from 'react';
import { Autocomplete, Stack, TextField, Typography } from '@mui/material';
import { sortBy } from 'lodash';

import type { AdminTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { AlisCommunityRow } from './components/AlisCommunityRow';
import { AlisFloorRow } from './components/AlisFloorRow';
import { AlisHallRow } from './components/AlisHallRow';
import { AlisRoomRow } from './components/AlisRoomRow';
import { useAlisCommunities } from './hooks/useAlisCommunities';
import { useAlisCommunityStructure } from './hooks/useAlisCommunityStructure';

import ErrorScreen from '../../../../screens/ErrorScreen';
import Loading from '../../../../screens/Loading';
import { ShowPageHeader } from '../../components/ShowPageHeader';

export const AlisPage = () => {
  const {
    data: communities,
    isLoading: loadingCommunities,
    isError: communitiesError,
  } = useAlisCommunities();

  const [selectedCommunity, setSelectedCommunity] =
    useState<AdminTypes.AlisCommunity | null>(null);

  useEffect(() => {
    if (!loadingCommunities) {
      setSelectedCommunity(communities?.[0] || null);
    }
  }, [loadingCommunities, communities]);

  const { data: communityStructure, isLoading: isStructureLoading } =
    useAlisCommunityStructure(selectedCommunity?.communityId);

  if (loadingCommunities) {
    return <Loading />;
  }

  if (communitiesError) {
    return (
      <ErrorScreen defaultMessage='Something went wrong with getting Alis data.' />
    );
  }

  return (
    <Stack>
      <ShowPageHeader
        backPath='/admin/general-settings'
        prefix='Alis Structure Mapping'
        data-testid='alis-structureHeader'
        actions={
          <Autocomplete
            disablePortal
            options={communities || []}
            value={selectedCommunity}
            getOptionLabel={(community) => community.communityName}
            sx={{ width: 300 }}
            onChange={(_, newValue) => setSelectedCommunity(newValue)}
            renderInput={(params) => <TextField {...params} label='' />}
          />
        }
      />
      {isStructureLoading ? (
        <Loading />
      ) : selectedCommunity && communityStructure ? (
        <>
          <Stack direction='row' justifyContent='space-between'>
            <Typography variant='h2'>Alis</Typography>
            <Typography variant='h2'>Inspiren</Typography>
          </Stack>
          <AlisCommunityRow
            community={selectedCommunity}
            communityStructure={communityStructure}
          />
          <Stack sx={{ px: 4, mt: 1 }} direction='column' gap={1}>
            {sortBy(communityStructure?.floors || [], 'name').map((floor) => (
              <Fragment key={floor.id}>
                <AlisFloorRow floor={floor} />
                <Stack sx={{ px: 4, mb: 2 }} direction='column' gap={1}>
                  {sortBy(floor.halls, 'name').map((hall) => (
                    <Fragment key={hall.id}>
                      <AlisHallRow hall={hall} />
                      <Stack direction='column' gap={1} sx={{ px: 4 }}>
                        {sortBy(hall.rooms, 'name').map((room) => (
                          <AlisRoomRow
                            key={room.id}
                            buildingId={
                              communityStructure.connectedBuilding?.id
                            }
                            room={room}
                          />
                        ))}
                      </Stack>
                    </Fragment>
                  ))}
                </Stack>
              </Fragment>
            ))}
          </Stack>
        </>
      ) : null}
    </Stack>
  );
};
