import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box } from '@mui/material';
import * as Sentry from '@sentry/react';
import { isNil } from 'lodash';

import type { WasabiImage } from '@inspiren-monorepo/shared-react/universal';
import type { MarkData } from '@inspiren-monorepo/shared-types';

import Image from './Image';
import Playbar from './Playbar';

import { doesRangeExceedLimitForImages } from '../../helpers/doesRangeExceedLimitForImages';
import { isPastImageExpiration } from '../../helpers/isPastImageExpiration';

import type { SpeedOption } from './Playbar';
import type {
  EventReviewRoom,
  EventReviewUnit,
} from '../../types/EventReviewProps';

const speedOptions: SpeedOption[] = [
  { label: 'Slow', value: 1000 },
  { label: 'Normal', value: 500 },
];

interface ImagePlayerProps {
  selectedRoom: EventReviewRoom | null;
  startDate: Date | null;
  endDate: Date | null;
  images: WasabiImage[] | null;
  imagesLoading: boolean;
  lastImagesLoading: boolean;
  imagePosition: number;
  onImagePositionChange: (newValue: number | 'inc' | 'dec') => void;
  timestamp: Date | null;
  selectedUnit: EventReviewUnit | null;
  marks: MarkData[] | null;
  message: string | null;
}

const ImagePlayer = ({
  selectedRoom,
  startDate,
  endDate,
  images,
  imagesLoading,
  lastImagesLoading,
  imagePosition,
  onImagePositionChange,
  timestamp,
  selectedUnit,
  marks,
  message,
}: ImagePlayerProps) => {
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
  const [speed, setSpeed] = useState<SpeedOption>(speedOptions[1]);
  const [railWidth, setRailWidth] = useState<number | null>(null);

  const handleRailWidthChange = useCallback((width: number | null) => {
    setRailWidth(width);
  }, []);

  const imgUrl =
    images && images[imagePosition] ? images[imagePosition].url : null;

  const maxPosition = images && images.length > 0 ? images.length - 1 : null;

  const spacePerMark = useMemo(() => {
    if (!railWidth || !maxPosition) return null;
    return railWidth / maxPosition;
  }, [railWidth, maxPosition]);

  const disabled = !images || images.length < 1;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const rippleRef = useRef<any>();

  const handleRipple = () => {
    rippleRef.current.start({});
    setTimeout(() => rippleRef.current.stop({}), 240);
  };

  /**
   * It cleans up the old interval. We use it to stop interval when we change speed or once change card to Rooms etc.
   */
  useEffect(
    () => () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    },
    [intervalId],
  );

  /**
   * It resets intervalId when we change startDate, endDate or room. We use it to stop playback.
   */
  useEffect(
    () => () => {
      setIntervalId(null);
    },
    [startDate, endDate, selectedRoom],
  );

  const handleStepForward = useCallback(() => {
    if (isNil(images) || imagePosition >= images.length - 1) return;
    onImagePositionChange('inc');
  }, [images, imagePosition, onImagePositionChange]);

  const handlePlayClick = useCallback(() => {
    if (intervalId) {
      setIntervalId(null);
      return;
    }

    const newIntervalId = setInterval(() => {
      handleStepForward();
    }, speed.value);

    setIntervalId(newIntervalId);
  }, [intervalId, handleStepForward]);

  const handleSpeedChange = useCallback(
    (option: SpeedOption) => {
      setSpeed(option);

      if (intervalId) {
        const newIntervalId = setInterval(() => {
          handleStepForward();
        }, option.value);

        setIntervalId(newIntervalId);
      }
    },
    [intervalId, handleStepForward],
  );

  useEffect(() => {
    // Stop playback at end or if images change
    if (
      intervalId &&
      ((maxPosition && imagePosition >= maxPosition) || images?.length === 0)
    ) {
      setIntervalId(null);
    }
  }, [imagePosition, maxPosition, images]);

  return (
    <Box
      sx={{
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        height: '100%',
      }}
      data-testid='eventreview-imageplayer'
    >
      <Box
        sx={{
          position: 'relative',
          aspectRatio: '953 / 735',
          width: '100%',
        }}
      >
        {/* {!isPastImageExpiration(endDate, selectedUnit) &&
          !disabled &&
          !imagesLoading && <ImageMenu disableVideoExport={!is1hrOrLess} />} */}
        <Image
          src={imgUrl || null}
          onClick={() => {
            handleRipple();
            handlePlayClick();
          }}
          selectedRoom={selectedRoom}
          imagesLoading={imagesLoading}
          lastImagesLoading={lastImagesLoading}
          message={message}
          startDate={startDate}
          endDate={endDate}
        />
      </Box>
      <Playbar
        disabled={
          disabled ||
          imagesLoading ||
          isPastImageExpiration(endDate, selectedUnit) ||
          doesRangeExceedLimitForImages(startDate, endDate)
        }
        maxPosition={maxPosition}
        playing={Boolean(intervalId)}
        onPlayClick={handlePlayClick}
        speedOptions={speedOptions}
        speed={speed}
        onSpeedChange={handleSpeedChange}
        rippleRef={rippleRef}
        onRailWidthChange={handleRailWidthChange}
        spacePerMark={spacePerMark}
        marks={marks || []}
        images={images}
        timestamp={timestamp}
        imagePosition={imagePosition}
        onImagePositionChange={onImagePositionChange}
      />
    </Box>
  );
};

export default Sentry.withProfiler(ImagePlayer);
