import {
  FormHelperText,
  FormControl,
  TextField,
  Autocomplete,
  CircularProgress,
  createFilterOptions,
} from '@mui/material';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import type { SelectOptionsTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import {
  NewHouseholdNameRegex,
  useHouseholdChange,
} from '../hooks/useHouseholdChange';

import type { RoomFieldTypes } from '../types/RoomFieldTypes';

const filter = createFilterOptions<string>();

type Props = {
  orgId?: string;
  isLoading?: boolean;
  disabled?: boolean;
  householdsMap: Record<string, SelectOptionsTypes.HouseholdOption>;
};

export const ControlledSelectUnitHousehold = ({
  orgId,
  isLoading,
  disabled,
  householdsMap,
}: Props) => {
  const { control, setValue } = useFormContext<RoomFieldTypes>();
  const unitId = useWatch({ control, name: 'unitId' });

  const { handleHouseholdChange, pending: addingHousehold } =
    useHouseholdChange({
      orgId,
      unitId,
      onAdd: (householdId: string) => {
        setValue('householdId', householdId);
      },
    });

  return (
    <Controller
      name='householdId'
      control={control}
      render={({ field: { name, value, onChange }, fieldState: { error } }) => (
        <FormControl fullWidth>
          <Autocomplete
            id={name}
            disabled={disabled || addingHousehold || isLoading}
            data-testid={name}
            options={Object.keys(householdsMap || {})}
            loading={isLoading || addingHousehold}
            getOptionLabel={(option) => {
              if (NewHouseholdNameRegex.test(option)) {
                return option;
              }

              return householdsMap[option]?.displayName || '';
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              const { inputValue } = params;

              const isExisting = Object.values(householdsMap).some(
                (option) => inputValue === option.displayName,
              );

              if (inputValue !== '' && !isExisting) {
                filtered.push(`Add "${inputValue}"`);
              }

              return filtered;
            }}
            value={value || null}
            isOptionEqualToValue={(o, v) => o === v}
            onChange={(_e, b) => handleHouseholdChange(b, onChange)}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {isLoading || addingHousehold ? (
                        <CircularProgress color='inherit' size={18} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
                label={addingHousehold ? 'Adding aparment...' : 'Apartment'}
                margin='dense'
                error={Boolean(error)}
              />
            )}
            fullWidth
          />
          <FormHelperText error={Boolean(error)}>
            {error?.message}
          </FormHelperText>
        </FormControl>
      )}
    />
  );
};
