import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { some } from 'lodash';

import type {
  AdminTypes,
  SelectOptionsTypes,
} from '@inspiren-monorepo/virtual-care/api-contracts';

import { useCurrentUser } from '../../../../../HOC/CurrentUserContextProvider';
import { postUser } from '../../../data-access/postUser';
import { putUser } from '../../../data-access/putUser';

import type { RoleMap } from '../../../../../../types';
import type { OnSubmitFormModal } from '../../../modals/FormModalBase';
import type { UserFields } from '../helpers/getUsersTableFields';
import type { InfiniteData } from '@tanstack/react-query';

type Props = {
  users?: AdminTypes.User[];
  roleMap: RoleMap;
  unitsMap: Record<string, SelectOptionsTypes.UnitOption>;
};

type InfiniteDataUsers = InfiniteData<{
  lastKey: unknown;
  users: AdminTypes.User[];
}>;

const useUsersUpsert = ({ users, roleMap, unitsMap }: Props) => {
  const orgId = import.meta.env.VITE_ORG_ID;
  const queryClient = useQueryClient();

  const { user } = useCurrentUser();

  const handleEditSubmit: OnSubmitFormModal<UserFields> = useCallback(
    async (params) => {
      const editedUser = users?.find((u) => u.domainId === params.id);

      const data: Partial<AdminTypes.NewUser> & { userId: string } = {
        userId: params.id,
        firstName: params.firstName,
        lastName: params.lastName,
        roleId: params.role,
        mobilePhone: params.mobilePhone,
        email: params.email || editedUser?.email,
      };

      if (params.username) data.username = params.username;
      if (params.password) data.password = params.password;
      if (params.unitId) data.unitId = params.unitId;
      if (params.levelAccess) data.levelAccess = params.levelAccess;
      if (params.levelAccessBuildingIds)
        data.levelAccessBuildingIds = params.levelAccessBuildingIds;

      await putUser(data);

      if (data.userId === user?.domainId) {
        // when logged user edits own settings, then we have to refresh current user
        await queryClient.invalidateQueries({ queryKey: ['auth-user'] });
      }

      queryClient.setQueryData<InfiniteDataUsers>(['users'], (oldData) => {
        if (!oldData) {
          // Handle the case where oldData is undefined
          // For example, you might want to return an empty array
          return oldData;
        }

        const newPages = oldData.pages.map((page) => {
          if (some(page.users, (u) => u.domainId === data.userId)) {
            const usersData = page.users.map((u) => {
              if (u.domainId === data.userId) {
                const updatedUser: AdminTypes.User = {
                  ...u,
                  firstName: params.firstName,
                  lastName: params.lastName,
                  roleId: params.role,
                  levelAccess: params.levelAccess || null, // we cant default
                  levelAccessBuildingIds: params.levelAccessBuildingIds || [],
                  email: params.email,
                  unitId: params.unitId,
                  unitDisplayName: params.unitId
                    ? unitsMap[params.unitId]?.displayName
                    : '',
                  mobilePhone: params.mobilePhone,
                };

                return updatedUser;
              }

              return u;
            });

            return {
              ...page,
              users: usersData,
            };
          }

          return page;
        });

        return {
          ...oldData,
          pages: newPages,
        };
      });
    },
    [users, queryClient, user, roleMap, unitsMap],
  );

  const handleAddSubmit = useCallback(
    async (params: UserFields, invalidateQueries = true) => {
      const data: Partial<AdminTypes.NewUser> = {
        firstName: params.firstName,
        lastName: params.lastName,
        roleId: params.role,
        password: params.password,
      };

      if (params.unitId) data.unitId = params.unitId;
      if (params.levelAccess) data.levelAccess = params.levelAccess;
      if (params.email) data.email = params.email;
      if (params.levelAccessBuildingIds)
        data.levelAccessBuildingIds = params.levelAccessBuildingIds;
      if (params.username) data.username = params.username;
      if (params.mobilePhone) data.mobilePhone = params.mobilePhone;

      const newUser = await postUser(data as AdminTypes.NewUser);

      if (invalidateQueries) {
        queryClient.setQueryData<InfiniteDataUsers>(['users'], (oldData) => {
          const updatedUser: AdminTypes.User = {
            id: newUser.id,
            domainId: newUser.domainId,
            firstName: params.firstName,
            lastName: params.lastName,
            roleId: params.role,
            levelAccess: params.levelAccess,
            levelAccessBuildingIds: params.levelAccessBuildingIds,
            email: newUser.email,
            username: newUser.username,
            mobilePhone: newUser.mobilePhone,
            unitId: params.unitId,
            lastActivityAt: newUser.lastActivityAt,
            roleDisplayName: roleMap[params.role]?.displayName,
            unitDisplayName: params.unitId
              ? unitsMap[params.unitId]?.displayName
              : '',
            orgId,
          };

          const lastKey = newUser.id;

          if (!oldData) {
            // Handle the case where oldData is undefined
            // For example, you might want to return an array with the new room

            return {
              pages: [
                {
                  users: [updatedUser],
                  lastKey,
                },
              ],
              pageParams: [lastKey],
            };
          }

          return {
            pages: [
              {
                users: [updatedUser],
                lastKey,
              },
              ...oldData.pages,
            ],
            pageParams: [lastKey, ...oldData.pageParams],
          };
        });
      }
    },
    [orgId, queryClient, roleMap, unitsMap],
  );

  return {
    handleEditSubmit,
    handleAddSubmit,
  };
};

export default useUsersUpsert;
