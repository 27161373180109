import { useMemo } from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import type {
  GridSlotProps,
  GridSlots,
  GridValidRowModel,
} from '@mui/x-data-grid-pro';
import { DataGridPro } from '@mui/x-data-grid-pro';

import ImportTableFooter, {
  type ImportTableFooterProps,
} from './components/ImportTableFooter';
import ImportTableRow from './components/ImportTableRow';
import ImportTableToolbar, {
  type ImportTableToolbarProps,
} from './components/ImportTableToolbar';
import { useImportTable } from './hooks/useImportTable';

import type { ImportTableHookProps } from './hooks/useImportTable';
import type { Importable } from './types/importable';

type ImportTableProps<TFieldTypes extends GridValidRowModel> =
  ImportTableHookProps<TFieldTypes> & {
    loading?: boolean;
    itemName: string;
    heading?: string;
  };

const StyledBox = styled('div')(({ theme }) => ({
  height: '100%',
  width: '100%',
  '& .MuiDataGrid-cell--editable': {
    '& .MuiInputBase-root': {
      height: '100%',
    },
    '& .Mui-error': {
      backgroundColor: `rgb(126,10,15, ${theme.palette.mode === 'dark' ? 0 : 0.1})`,
      color: theme.palette.validationError[theme.palette.mode],
    },
  },
  '& .import-row-error .MuiDataGrid-row--editable .MuiDataGrid-cell': {
    backgroundColor: `rgb(126,10,15, ${theme.palette.mode === 'dark' ? 0 : 0.09})`,
  },
}));

const ImportTable = <TFieldTypes extends GridValidRowModel>({
  fields,
  createDefaultRow,
  mapCsvToFields,
  upload,
  loading: externalLoading,
  itemName,
  heading,
  onRowUpdate,
  bulkUpdateSupport,
}: ImportTableProps<TFieldTypes>) => {
  const {
    columns,
    rows,
    rowModesModel,
    setRowModesModel,
    saveAll,
    canSaveAll,
    handleRowEditStart,
    handleRowEditStop,
    processRowUpdate,
    importBlocked,
    onAddRow,
    onCsvImportAccepted,
    onImport,
    loading,
  } = useImportTable({
    fields,
    createDefaultRow,
    mapCsvToFields,
    upload,
    onRowUpdate,
    bulkUpdateSupport,
  });

  const footerProps = useMemo<ImportTableFooterProps>(
    () => ({
      canImport: !importBlocked,
      canSaveAll,
      onImport,
      saveAll,
    }),
    [importBlocked, canSaveAll, onImport, saveAll],
  );

  const toolbarProps = useMemo<ImportTableToolbarProps<TFieldTypes>>(
    () => ({
      onAddRow,
      onCsvImportAccepted,
      loading: externalLoading || loading,
      itemName,
      fields,
    }),
    [onAddRow, onCsvImportAccepted, externalLoading, loading, itemName, fields],
  );

  return (
    <StyledBox>
      <Typography variant='h2' mb={3}>
        {heading ?? `Import ${itemName}`}
      </Typography>
      <DataGridPro<Importable<TFieldTypes>>
        rowSelection={false}
        editMode='row'
        rowHeight={58}
        loading={externalLoading || loading}
        rowModesModel={rowModesModel}
        onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        rows={rows}
        columns={columns}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={() => {}}
        getRowId={(row) => row.$meta.uniqueId}
        slots={{
          footer: ImportTableFooter as GridSlots['footer'],
          toolbar: ImportTableToolbar as GridSlots['toolbar'],
          row: ImportTableRow as GridSlots['row'],
        }}
        slotProps={{
          footer: footerProps as GridSlotProps['footer'],
          toolbar: toolbarProps as GridSlotProps['toolbar'],
        }}
      />
    </StyledBox>
  );
};

export default ImportTable;
